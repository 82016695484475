import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'
import React, { useState, useEffect } from 'react'
import { CircleFlag } from 'react-circle-flags'
import { Link } from 'react-router-dom'
import { animated, useSpring } from 'react-spring'
import styled from 'styled-components/macro'

import affiliate from '../../../../src/assets/images/icons/campaigns/affiliate.svg'
import bespoke from '../../../../src/assets/images/icons/campaigns/bespoke.svg'
import giveaway from '../../../../src/assets/images/icons/campaigns/giveaway.svg'
import shoutout from '../../../../src/assets/images/icons/campaigns/shoutout.svg'
import ugc from '../../../../src/assets/images/icons/campaigns/ugc.svg'
import instagram from '../../../../src/assets/images/icons/social/instagram-color-square.svg'
import tiktok from '../../../../src/assets/images/icons/social/tiktok-color-square.svg'
import youtube from '../../../../src/assets/images/icons/social/youtube-color-square.svg'
import placeholder from '../../../assets/images/icons/campaigns/campaign.svg'
import globe from '../../../assets/images/icons/globe.svg'
import { findProfile } from '../../../utils'
import { ImageLoader } from '../../general/ImageLoader'
const socialIcons = { instagram, youtube, tiktok }

export const CampaignCard = ({ campaign, userData, affiliateCommission }) => {
  const [reachRange, setReachRange] = useState(null)
  const [engagementRange, setEngagementRange] = useState(null)
  const categories =
    campaign.categories.length > 0
      ? campaign.categories.map(item => item.category.title).join(' • ')
      : 'Any niche'
  const commission = campaign?.metaData?.affiliateCommission || affiliateCommission

  // calculate user age by given date of birth
  const calculateAge = dob => {
    if (!dob) {
      return null
    } else {
      const today = new Date()
      const birthDate = new Date(dob)
      let age = today.getFullYear() - birthDate.getFullYear()
      const m = today.getMonth() - birthDate.getMonth()
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age = age - 1
      }
      return age
    }
  }

  // user stats
  const instagramProfile = findProfile(userData?.socialProfiles, 'instagram')
  const tiktokProfile = findProfile(userData?.socialProfiles, 'tiktok')
  const youtubeProfile = findProfile(userData?.socialProfiles, 'youtube')

  const instagramFollowers = instagramProfile?.followerCount
  const tiktokFollowers = tiktokProfile?.followerCount
  const youtubeFollowers = youtubeProfile?.followerCount

  const instagramEngagement = instagramProfile?.engagement
  const tiktokEngagement = tiktokProfile?.engagement
  const youtubeEngagement = youtubeProfile?.engagement

  const userAge = calculateAge(userData?.creatorProfile?.birthDate)
  const userResidence = userData?.creatorProfile?.shippingCountry

  // eligibility checks
  const followersCheck =
    (!campaign.instagramReach && !campaign.tiktokReach && !campaign.youtubeReach) ||
    (campaign.instagramReach > 0 && instagramFollowers >= campaign.instagramReach) ||
    (campaign.tiktokReach > 0 && tiktokFollowers >= campaign.tiktokReach) ||
    (campaign.youtubeReach > 0 && youtubeFollowers >= campaign.youtubeReach)

  const engagementCheck =
    (!campaign.instagramEngagement && !campaign.tiktokEngagement && !campaign.youtubeEngagement) ||
    (campaign.instagramEngagement > 0 && instagramEngagement >= campaign.instagramEngagement) ||
    (campaign.tiktokEngagement > 0 && tiktokEngagement >= campaign.tiktokEngagement) ||
    (campaign.youtubeEngagement > 0 && youtubeEngagement >= campaign.youtubeEngagement)

  const ageCheck =
    userAge &&
    (userAge >= campaign.minAge || (!campaign.minAge && userAge >= 13)) &&
    (userAge <= campaign.maxAge || !campaign.maxAge)

  const residenceCheck =
    !campaign.countries.length ||
    campaign.countries.find(el => el.country.countryCode === userResidence)

  const nicheCheck =
    !campaign.categories.length ||
    !!userData?.creatorProfile?.niches?.find(niche =>
      campaign.categories.find(cat => cat.category.id === niche.category.id)
    )

  useEffect(() => {
    // setting reach and engagement ranges based on selected social channels & requirements

    if (campaign?.socialChannels?.length) {
      // using a copy to avoid mutating state
      const socialChannels = [...campaign.socialChannels]

      const sortedReach = socialChannels.sort((a, b) => {
        const aReach = campaign[`${a}Reach`]
        const bReach = campaign[`${b}Reach`]
        return aReach > bReach ? 1 : bReach > aReach ? -1 : 0
      })

      const lowestReach = campaign[`${sortedReach[0]}Reach`]
      const highestReach =
        socialChannels.length > 1 ? campaign[`${sortedReach[sortedReach.length - 1]}Reach`] : null

      setReachRange(
        lowestReach || highestReach
          ? `${lowestReach?.toLocaleString() || 0}${
              highestReach && highestReach !== lowestReach
                ? `-${highestReach.toLocaleString()}+`
                : '+'
            }`
          : '-'
      )

      // do the same for engagement
      const sortedEngagement = socialChannels.sort((a, b) => {
        const aEngagement = campaign[`${a}Engagement`]
        const bEngagement = campaign[`${b}Engagement`]
        return aEngagement > bEngagement ? 1 : bEngagement > aEngagement ? -1 : 0
      })

      const lowestEngagement = campaign[`${sortedEngagement[0]}Engagement`]
      const highestEngagement =
        socialChannels.length > 1
          ? campaign[`${sortedEngagement[sortedEngagement.length - 1]}Engagement`]
          : null

      setEngagementRange(
        lowestEngagement || highestEngagement
          ? `${lowestEngagement?.toLocaleString() || 0}${
              highestEngagement && highestEngagement !== lowestEngagement
                ? `-${highestEngagement.toLocaleString()}%+`
                : '%+'
            }`
          : '-'
      )
    }
  }, [campaign])

  const fadeIn = useSpring({
    from: { opacity: 0.5 },
    to: { opacity: 1 },
  })

  return (
    <Wrapper key={campaign.id} data-cy='campaign-card'>
      <animated.div className='fade-in' style={fadeIn}>
        <Link to={`/campaign/${campaign.slug}`} className='campaign-card-inner'>
          <div className='basic-info'>
            <p className='brand'>{campaign.brand ? campaign.brand.name : ''}</p>
            <p className='title'>{campaign.title}</p>
            {campaign.maxPaidAmount && (
              <p className='paid-campaign'>
                Payment:{' '}
                <span className='value'>
                  $
                  {campaign.minPaidAmount
                    ? `${campaign.minPaidAmount.toLocaleString()}-${campaign.maxPaidAmount.toLocaleString()}`
                    : campaign.maxPaidAmount.toLocaleString()}{' '}
                  USD
                </span>
              </p>
            )}
            {!!commission && (
              <p className='affiliate-commission'>
                Affiliate Commission: <span className='value'>{commission}%</span>
              </p>
            )}
          </div>
          <div className='details'>
            <div className='product'>
              <div className='thumbnail'>
                <ImageLoader src={campaign.thumbnailSrc || placeholder} alt='' />
              </div>
            </div>
            <div className='requirements'>
              <div className='qualifications'>
                <div className='requirement'>
                  <span className='label'>
                    {userData && (
                      <span className='eligibility-indicator'>
                        {followersCheck ? (
                          <CheckCircleOutlined style={{ color: '#027df0' }} />
                        ) : (
                          <CloseCircleOutlined style={{ color: '#ccc' }} />
                        )}
                      </span>
                    )}
                    {campaign.socialChannels?.[0] === 'youtube' ? 'Subscribers' : 'Followers'}
                  </span>
                  <span className='value'>
                    {campaign.socialChannels?.length ? reachRange : '-'}
                  </span>
                </div>
                <div className='requirement'>
                  <span className='label'>
                    {userData && (
                      <span className='eligibility-indicator'>
                        {engagementCheck ? (
                          <CheckCircleOutlined style={{ color: '#027df0' }} />
                        ) : (
                          <CloseCircleOutlined style={{ color: '#ccc' }} />
                        )}
                      </span>
                    )}
                    Engagement
                  </span>
                  <span className='value'>
                    {campaign.socialChannels?.length ? engagementRange : '-'}
                  </span>
                </div>
                {/* age range default 13+ */}
                <div className='requirement'>
                  <span className='label'>
                    {userData && (
                      <span className='eligibility-indicator'>
                        {ageCheck ? (
                          <CheckCircleOutlined style={{ color: '#027df0' }} />
                        ) : (
                          <CloseCircleOutlined style={{ color: '#ccc' }} />
                        )}
                      </span>
                    )}
                    Age
                  </span>
                  <span className='value'>
                    {campaign.minAge && campaign.maxAge
                      ? `${campaign.minAge} to ${campaign.maxAge}`
                      : campaign.minAge && !campaign.maxAge
                        ? `${campaign.minAge}+`
                        : !campaign.minAge && campaign.maxAge
                          ? `13 to ${campaign.maxAge}`
                          : '13+'}
                  </span>
                </div>
                <div className='requirement'>
                  <span className='label'>
                    {userData && (
                      <span className='eligibility-indicator'>
                        {residenceCheck ? (
                          <CheckCircleOutlined style={{ color: '#027df0' }} />
                        ) : (
                          <CloseCircleOutlined style={{ color: '#ccc' }} />
                        )}
                      </span>
                    )}
                    Residence
                  </span>
                  <span className='value'>
                    {campaign.countries?.length ? (
                      campaign.countries.length > 2 ? (
                        <>
                          {campaign.countries.slice(0, 2).map(item => (
                            <CircleFlag
                              key={item.country.id}
                              className='flag'
                              countryCode={`${item.country.countryCode.toLowerCase()}`}
                            />
                          ))}
                          <img className='flag plus' src={globe} alt='More countries' />
                        </>
                      ) : (
                        campaign.countries.map(item => (
                          <CircleFlag
                            key={item.country.id}
                            className='flag'
                            countryCode={`${item.country.countryCode.toLowerCase()}`}
                          />
                        ))
                      )
                    ) : (
                      <img className='flag' src={globe} alt='Global' />
                    )}
                  </span>
                </div>
                <div className='requirement'>
                  <span
                    className={`niche ${userData && (nicheCheck ? 'eligible' : 'not-eligible')}`}>
                    {categories}
                  </span>
                </div>
              </div>
              <div className='objectives'>
                <div className='strategy'>
                  <img
                    className='icon'
                    src={
                      campaign.strategy === 'ugc'
                        ? ugc
                        : campaign.strategy === 'shoutout'
                          ? shoutout
                          : campaign.strategy === 'giveaway'
                            ? giveaway
                            : campaign.strategy === 'affiliate'
                              ? affiliate
                              : bespoke
                    }
                    alt=''
                  />
                  {campaign.socialChannels?.map((channel, i) => (
                    <img src={socialIcons[channel]} alt={channel} key={i} />
                  ))}
                  {!!campaign.contentFormats?.length &&
                    !!campaign.contentFormats[0].length &&
                    campaign.contentFormats.map(
                      format =>
                        !!format.length && (
                          <span key={format} className='format'>
                            {format === 'Video Post' ? 'Video' : format}
                            {campaign.metaData?.contentFormatCounts?.[format?.toLowerCase()] &&
                              ` (${
                                campaign.metaData?.contentFormatCounts?.[format?.toLowerCase()]
                              })`}
                          </span>
                        )
                    )}
                </div>
              </div>
            </div>
          </div>
        </Link>
      </animated.div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: #fff;
  border: 1px solid #e0e0f7;
  min-height: 100%;
  width: 100%;
  max-width: 400px;
  border-radius: 20px;
  transition: 0.2s ease-in-out;
  overflow: hidden;
  cursor: pointer;
  // prevent hover effect on mobile
  @media (hover: hover) {
    &:hover {
      box-shadow: -3px 3px 10px rgba(0, 0, 0, 0.1);
    }
  }
  .ant-popover-arrow {
    right: 8px;
  }
  .ant-popover-buttons button {
    padding: 3px 8px 0 8px;
  }
  p {
    margin: 0;
  }
  .fade-in {
    height: 100%;
    position: relative;
  }
  .campaign-card-inner {
    color: ${props => props.theme.crcoMidnight};
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    &:focus {
      color: ${props => props.theme.crcoTechBlue};
    }
  }
  .basic-info {
    width: 100%;
    text-align: center;
    margin: auto;
    padding-bottom: 10px;
    .brand {
      color: #080830;
      font-size: 0.8rem;
      opacity: 0.5;
    }
    .title {
      font-size: 0.9rem;
      padding: 5px 0;
    }
    .paid-campaign,
    .retail-value,
    .affiliate-commission {
      color: rgba(0, 0, 0, 0.3);
      font-size: 0.8rem;
    }
    .paid-campaign,
    .affiliate-commission {
      .value {
        color: #00c457;
      }
    }
    .retail-value {
      .value {
        color: ${props => props.theme.crcoTechBlue};
      }
    }
  }
  .details {
    display: flex;
    border-top: 1px solid #e6e6e6;
    .product {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      padding: 10px;
      border-right: 1px solid #e6e6e6;
      .thumbnail {
        height: 125px;
        width: 125px;
        display: grid;
        place-content: center;
        overflow: hidden;
        border-radius: 5px;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 5px;
        }
      }
    }
    .requirements {
      width: 50%;
      padding: 10px;
      .header-text {
        display: none;
      }
      .qualifications {
        padding-bottom: 5px;
        border-bottom: 1px solid #e6e6e6;
        margin-bottom: 10px;
        .requirement {
          display: flex;
          justify-content: space-between;
          font-size: 0.7rem;
          color: #808080;
          .eligibility-indicator {
            margin-right: 5px;
          }
          .label {
            white-space: nowrap;
          }
          .value {
            color: ${props => props.theme.crcoTechBlue};
            display: flex;
          }
          .flag {
            height: 15px;
            width: 15px;
            margin-left: 5px;
            &.plus {
              border: 1px dotted ${props => props.theme.crcoTechBlue};
              border-radius: 50%;
              opacity: 0.5;
            }
          }
        }
        .niche {
          border: 1px solid;
          border-radius: 20px;
          text-align: center;
          font-size: 0.7rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding: 0 5px;
          width: 100%;
          &.eligible {
            border-color: ${props => props.theme.crcoTechBlue};
            color: ${props => props.theme.crcoTechBlue};
          }
          &.not-eligible {
            border-color: #ccc;
            color: #999;
          }
        }
      }
      .objectives {
        .strategy {
          display: flex;
          flex-wrap: wrap;
          gap: 5px;
          img {
            height: 20px;
          }
          .format {
            background: #ecf6ff;
            color: #027df0;
            display: grid;
            place-content: center;
            padding: 0 5px;
            border-radius: 3px;
            font-size: 0.7rem;
          }
        }
      }
    }
  }
  @media only screen and (min-width: 900px) {
    width: 310px;
    .campaign-card-inner {
      padding: 20px;
    }
    .basic-info {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .brand {
        font-size: 0.9rem;
      }
      .title {
        font-size: 1rem;
        min-height: 60px;
        display: grid;
        place-content: center;
      }
    }
    .details {
      flex-direction: column;
      border: none;
      .product {
        width: 100%;
        padding: 0 0 10px 0;
        border-right: none;
        border-bottom: 1px solid #e6e6e6;
        .thumbnail {
          height: 200px;
          width: 200px;
        }
      }
      .requirements {
        width: 100%;
        padding: 10px 0 0 0;
        .header-text {
          display: block;
          text-align: center;
          margin-bottom: 5px;
        }
        .qualifications {
          .requirement {
            font-size: 0.8rem;
          }
          .niche {
            font-size: 0.8rem;
          }
        }
        .objectives {
          display: flex;
          flex-direction: column;
          align-items: center;
          .objectives-content .format {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
`
