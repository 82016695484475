import {
  SendOutlined,
  CheckCircleFilled,
  CheckCircleOutlined,
  PaperClipOutlined,
  LoadingOutlined,
  ExclamationCircleFilled,
  PhoneOutlined,
  DesktopOutlined,
} from '@ant-design/icons'
import { Alert, Input, Button, Upload, Tooltip } from 'antd'
import axios from 'axios'
import { Formik, Form } from 'formik'
import moment from 'moment'
import React, { useState, useEffect, useContext, useRef } from 'react'
import { useQuery, useInfiniteQuery, useQueryClient } from 'react-query'
import styled from 'styled-components/macro'

import { API_URL } from '../../../constants'
import { UserContext } from '../../../contexts/UserContext'
import { socket } from '../../../service/socket'
import { extractFileNameFromUrl } from '../../../utils'
import { Avatar } from '../../general/Avatar'

export const Chat = ({ brand, campaign, optIn }) => {
  // #region Ref
  const scrollRef = useRef()
  const endMessage = useRef()
  // #endregion

  // #region State
  const queryClient = useQueryClient()
  const [messages, setMessages] = useState([])
  const [socketId, setSocketId] = useState(false)
  const [fileList, setFileList] = useState([])
  // #endregion

  // #region Context
  const { fetchCurrentUser } = useContext(UserContext)
  // #endregion

  // #region Queries
  const { data: userData } = useQuery('user', fetchCurrentUser)

  const {
    data: chatData,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingMore,
  } = useInfiniteQuery(
    ['chat', optIn.id],
    async ({ pageParam = 1 }) => {
      const { data } = await axios.get(`${API_URL}/chat/${optIn.id}/messages/creator/${pageParam}`)
      return data
    },
    {
      getNextPageParam: lastPage => lastPage.nextCursor,
    }
  )
  // #endregion Queries

  // #region Functions
  useEffect(() => {
    if (chatData) {
      // order messages by date
      const pageArrays = chatData.pages.map(page => page.messages)
      const messagesArray = Array.prototype.concat.apply([], pageArrays)
      setMessages(messagesArray.reverse())
    }
  }, [chatData])

  const newMessage = msg => {
    const chatObj = {
      content: msg.message,
      sender: 'creator',
      sending: true,
      user: userData,
      read: false,
    }
    setMessages(messages => (messages ? [...messages, chatObj] : [chatObj]))

    scrollRef.current.scrollTop = 0
  }

  const handleSubmit = async message => {
    if (!message) return

    newMessage(message)
    socket.emit(
      'send-message',
      { message, optInId: optIn.id, userId: userData.id, sender: 'creator' },
      socketId
    )
  }

  const handleUpload = async fileData => {
    const uid = fileData.file.uid
    const maxFileSize = fileData.file.type.includes('image') ? 1000000000 : 10000000000

    if (fileData.file.size <= maxFileSize) {
      setFileList(prev => [
        ...prev,
        {
          uid,
          name: fileData.file.name,
          percent: 0,
          status: 'uploading',
        },
      ])

      const formData = new FormData()
      formData.append('upload', fileData.file)

      // handle upload
      axios
        .post(`${API_URL}/chat-file-upload/${optIn.id}?sender=creator`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: data => {
            const percent = Math.round((100 * data.loaded) / data.total)
            // update progress of given upload
            setFileList(prev =>
              prev.map(file =>
                file.uid === uid
                  ? {
                      ...file,
                      percent,
                    }
                  : file
              )
            )
          },
        })
        .then(() => {
          setFileList(prev =>
            prev.map(file =>
              file.uid === uid
                ? {
                    ...file,
                    status: 'done',
                  }
                : file
            )
          )
          queryClient.invalidateQueries('chat')

          scrollRef.current.scrollTop = 0

          setTimeout(() => {
            setFileList(prev => prev.filter(file => file.uid !== uid))
          }, 3000)
        })
        .catch(() => {
          setFileList(prev =>
            prev.map(file =>
              file.uid === uid
                ? {
                    ...file,
                    status: 'error',
                  }
                : file
            )
          )
        })
    } else {
      setFileList(prev => [
        ...prev,
        {
          uid,
          name: `File too large (max ${maxFileSize / 8000000}MB)`,
          status: 'error',
        },
      ])
      setTimeout(() => {
        setFileList(prev => prev.filter(file => file.uid !== uid))
      }, 5000)
    }
    endMessage.current.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    setSocketId(socket.id)
    socket.on('new-message', optInId => {
      if (optInId === optIn.id) queryClient.invalidateQueries('chat')
    })
    socket.on('SERVER_CONNECT')
    return () => {
      socket.off('message')
      socket.off('SERVER_CONNECT')
    }
  }, [optIn.id, setSocketId, queryClient])

  const handleScroll = () => {
    // fetch more messages when scrolled to top
    const scrollDistance = scrollRef.current.scrollTop
    const outerHeight = scrollRef.current.offsetHeight
    const innerHeight = scrollRef.current.scrollHeight
    const actualDistance = innerHeight + (scrollDistance - outerHeight)
    if (
      actualDistance < 200 &&
      hasNextPage &&
      !isFetching &&
      !isFetchingMore &&
      scrollRef.current
    ) {
      fetchNextPage()
    }
  }

  // #endregion Functions

  // #region Components

  const MessageContent = ({ message }) => {
    let content
    if (message.type === 'image') {
      const fileName = extractFileNameFromUrl(message.content)
      content = (
        <a className='file-download' download={true} href={message.content}>
          <img src={message.content} alt={fileName} />
        </a>
      )
    } else if (message.type === 'file') {
      const fileName = extractFileNameFromUrl(message.content)
      content = (
        <a className='file-download' download={true} href={message.content}>
          {fileName}
        </a>
      )
    } else if (message.type === 'notification') {
      content = (
        <span>
          <ExclamationCircleFilled /> {message.content}
        </span>
      )
    } else {
      content = message.content
    }
    return (
      <Text
        className={message.type}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignSelf: message.sender === 'creator' ? 'flex-end' : 'flex-start',
        }}>
        {(message.type === 'image' || message.type === 'file') && (
          <span className='info-text'>
            <PaperClipOutlined /> Attachment (click to download)
          </span>
        )}
        {content}
      </Text>
    )
  }
  // #endregion Components

  const messagesDisabled =
    (optIn.status === 'pending' && !messages?.length) || optIn.status === 'cancelled'

  return (
    <Wrapper className={messagesDisabled ? 'disabled' : ''}>
      <div className='inner'>
        <div
          id='messages-container'
          className='messages-container'
          ref={scrollRef}
          onScroll={handleScroll}>
          <div className='messages'>
            <p className='info-text'>
              This is the beginning of your message history with {brand.name} for the{' '}
              <b>{campaign.title}</b> campaign.
            </p>
            {messages?.length > 0 &&
              messages.map((message, i) =>
                message.sender === 'creator' ? (
                  <Message key={message.id || i} sender='current-user'>
                    <div className='message-info current-user'>
                      {message.sending ? (
                        <Status sender='current-user'>
                          <span className='sender-name'>
                            {message.user?.firstName} {message.user?.lastName}
                          </span>
                          <span>
                            Sending <LoadingOutlined spin />
                          </span>
                        </Status>
                      ) : (
                        <Status sender='current-user'>
                          <span className='sender-name'>
                            {message.user?.firstName} {message.user?.lastName}
                          </span>
                          <span>
                            {message.messageSid !== null ? (
                              <Tooltip title='Sent via SMS'>
                                <span className='device'>
                                  <PhoneOutlined />
                                </span>
                              </Tooltip>
                            ) : (
                              <Tooltip title='Sent via browser'>
                                <span className='device'>
                                  <DesktopOutlined />
                                </span>
                              </Tooltip>
                            )}
                            {message.created
                              ? `${moment(new Date(message.created)).fromNow()} - `
                              : 'Date Unknown'}
                            {message.read ? (
                              <span className='read'>
                                <b>Read</b> <CheckCircleFilled />
                              </span>
                            ) : (
                              <span className='delivered'>
                                Delivered <CheckCircleOutlined />
                              </span>
                            )}
                          </span>
                        </Status>
                      )}
                      <Avatar
                        size={30}
                        initials={`${userData?.firstName
                          .charAt(0)
                          .toUpperCase()} ${userData?.lastName.charAt(0).toUpperCase()}`}
                      />
                    </div>
                    <div className='message-content'>
                      <MessageContent message={message} />
                    </div>
                  </Message>
                ) : (
                  //left aligned, sender is brand/admin
                  <Message key={message.id}>
                    <div className='message-info'>
                      {message.userId && (
                        <Avatar
                          size={30}
                          color='#23262f'
                          initials={`${message.user?.firstName?.charAt(0).toUpperCase() || ''} ${
                            message.user?.lastName?.charAt(0).toUpperCase() || ''
                          }`}
                        />
                      )}
                      <Status>
                        <span className='sender-name'>
                          {message.userId
                            ? message.user?.firstName
                            : chatData.pages?.[0]?.brand?.name}{' '}
                          {message.userId ? message.user?.lastName : ''}
                        </span>
                        <span className='date'>
                          {message.created
                            ? `${moment(new Date(message.created)).fromNow()}`
                            : 'Date Unknown'}
                        </span>
                      </Status>
                    </div>
                    <div className='message-content'>
                      <MessageContent message={message} />
                    </div>
                  </Message>
                )
              )}
            <div ref={endMessage} />
          </div>
        </div>
        <Upload
          className='upload'
          name='upload'
          disabled={messagesDisabled}
          customRequest={handleUpload}
          fileList={fileList}
          accept='image/*,video/*,audio/*,.doc,.docx,.pdf,.csv,.xlsx,.txt'
          showUploadList={{
            showRemoveIcon: false,
          }}>
          <Button type='link' icon={<PaperClipOutlined />}>
            Upload Files
          </Button>
        </Upload>
        <Formik
          initialValues={{ message: '' }}
          onSubmit={(data, { resetForm }) => {
            handleSubmit(data.message)
            resetForm()
          }}>
          {({ values, setValues, status, isSubmitting, submitForm }) => {
            return (
              <Form>
                {status && <Alert message={status} type='error' showIcon />}
                <div className='input-container'>
                  <Input.TextArea
                    disabled={messagesDisabled}
                    name='message'
                    maxLength={15000}
                    autoComplete='off'
                    value={values.message || ''}
                    placeholder={
                      messagesDisabled
                        ? `Messaging is disabled unless activated by ${brand.name}`
                        : `Message ${brand.name}`
                    }
                    allowClear
                    onKeyDown={e => {
                      // prevent submitting message if pressing enter & shift key together
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault()
                        submitForm()
                      }
                    }}
                    onChange={e => {
                      setValues({ ...values, message: e.target.value })
                    }}
                  />
                  <Button type='link' htmlType='submit' disabled={!values.message || isSubmitting}>
                    <SendOutlined />
                  </Button>
                </div>
                <InfoText validInput={values.message}>
                  Press <b>Enter</b> to submit, or <b>Shift + Enter</b> to add a new line.
                </InfoText>
              </Form>
            )
          }}
        </Formik>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  font-family: 'Campton-Medium', Verdana, sans-serif;
  background: #dbeeff;
  position: relative;
  height: 100%;
  &.disabled {
    pointer-events: none;
    opacity: 0.7;
  }
  .inner {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 10px;
    bottom: 10px;
    left: 10px;
    right: 10px;
  }
  .messages-container {
    flex: 1;
    background: #ecf6ff;
    max-height: 100%;
    border-radius: 5px;
    padding: 8px;
    display: flex;
    flex-direction: column-reverse;
    overflow: auto;
    ${props => props.theme.scrollbar};
  }
  .messages {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .info-text {
      color: rgba(0, 0, 0, 0.3);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
  .device {
    margin-right: 5px;
  }
  .upload {
    text-align: right;
    .ant-btn-link {
      font-size: 0.8rem;
      margin: 0;
      padding: 0;
      border: 0;
    }
    .ant-upload-list-item {
      background: rgba(255, 255, 255, 0.5);
      border-radius: 3px;
      margin: 5px 0;
    }
    .ant-upload-list-item:hover .ant-upload-list-item-info {
      background-color: transparent;
    }
  }
  form {
    .ant-input-affix-wrapper {
      background: none;
    }
    .input-container {
      position: relative;
    }
    textarea {
      height: 100px;
      border: 1px solid #a5d2f3;
      border-radius: 5px;
      padding: 10px 40px 10px 10px;
      resize: none;
    }
    button {
      background: none;
      color: ${props => props.theme.crcoTechBlue};
      opacity: 0.5;
      position: absolute;
      bottom: 5px;
      right: 10px;
      padding: 0;
      border: 0;
      font-size: 1.2rem;
      z-index: 10;
      transition: 0.2s ease-in-out;
      &:disabled {
        pointer-events: none;
        opacity: 0;
      }
      &:hover {
        color: ${props => props.theme.crcoTechBlue};
        opacity: 1;
      }
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
    border-radius: 5px;
    border: 1px solid #fff;
  }
`

const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.sender === 'current-user' ? 'flex-end' : '')};
  margin-top: 20px;
  .message-info {
    display: flex;
    &.current-user {
      justify-content: flex-end;
      text-align: right;
    }
    .sender-name {
      font-weight: bold;
    }
  }
  .message-content {
    display: flex;
    flex-direction: column;
    max-width: 90%;
    text-align: ${props => (props.sender === 'current-user' ? 'right' : '')};
    margin-top: 5px;
    .file-download {
      display: grid;
      margin: 5px 0;
    }
  }
`

const Text = styled.p`
  text-align: left;
  background: #fff;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 0;
  white-space: pre-line;
  max-width: 100%;
  overflow-wrap: anywhere;

  &.notification {
    background: ${props => props.theme.crcoTechBlue};
    font-style: italic;
    color: #fff;
    opacity: 0.7;
  }

  img {
    max-height: 250px;
    max-width: 250px;
  }
`

const Status = styled.span`
  color: #999;
  font-size: 0.7rem;
  display: flex;
  flex-direction: column;
  margin: auto 5px;
  .read {
    .anticon {
      color: ${props => props.theme.crcoLettuce};
    }
  }
`

const InfoText = styled.p`
  opacity: ${props => (props.validInput ? '1' : '0')};
  color: #666;
  font-size: 0.7rem;
  margin: 12px 15px 4px 15px;
  text-align: right;
  transition: 0.2s ease-in-out;
  display: none;
  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    display: block;
  }
`
