import {
  ClockCircleOutlined,
  RightOutlined,
  SettingOutlined,
  DislikeOutlined,
  LikeOutlined,
} from '@ant-design/icons'
import { Drawer, Alert, Button, message, Tag } from 'antd'
import axios from 'axios'
import { useState } from 'react'
import { useQueryClient } from 'react-query'

import { API_URL } from '../../../../constants'
import PayoutSettings from '../PayoutSettings'

export const OptInPaymentDetails = ({ id, optIn, userData }) => {
  const queryClient = new useQueryClient()
  const [paymentsOpen, setPaymentsOpen] = useState(false)
  const [negotiationLoading, setNegotiationLoading] = useState(false)

  const handleNegotiation = async type => {
    try {
      setNegotiationLoading(true)
      await axios.post(`${API_URL}/opt-in/${id}/respond-update-payment-amount`, {
        accept: type === 'accept',
        reason: null,
      })

      if (type === 'accept') {
        message.success('Offer accepted!')
      } else {
        message.success('Offer declined.')
      }

      setNegotiationLoading(false)
      queryClient.invalidateQueries('opt-in')
    } catch (err) {
      message.error('Sorry, something went wrong.')
      setNegotiationLoading(false)
    }
  }

  const trolleyPayment = optIn?.trolleyPayments?.filter(payment => payment.type === 'optIn') || []

  const paymentStatusColor = status => {
    // Possible types of payments from trolley API
    switch (status) {
      case 'pending':
        return 'warning'
      case 'processing':
        return 'processing'
      case 'processed':
        return 'success'
      case 'failed':
        return 'error'
      case 'returned':
        return 'error'
      default:
        return 'warning'
    }
  }

  return (
    <div className='info-section payment'>
      <h2>Payment Details</h2>

      {optIn.paymentStatus !== 'completed' ? (
        optIn.extraData?.requestedPaymentStatus === 'pending' ? (
          // NEGOTIATION (OFFER PENDING)
          <div className='negotiation'>
            <div>
              {optIn.paymentAmount
                ? `${optIn.campaign.brand.name} wants to adjust your payment.`
                : `${optIn.campaign.brand.name} has sent an offer!`}
            </div>
            <div className='offer'>
              {optIn.paymentAmount && (
                <>
                  <span className='prev-amount'>${optIn.paymentAmount}</span>{' '}
                  <RightOutlined className='arrow' />
                </>
              )}
              <span className='new-amount'>${optIn.extraData?.requestedPaymentAmount}</span>
            </div>
            <div className='actions'>
              <Button
                loading={negotiationLoading}
                icon={<LikeOutlined />}
                onClick={() => handleNegotiation('accept')}
                type='primary'>
                Accept
              </Button>
              <Button
                loading={negotiationLoading}
                icon={<DislikeOutlined />}
                onClick={() => handleNegotiation('decline')}
                type='ghost'
                danger>
                Decline
              </Button>
            </div>
          </div>
        ) : optIn.paymentAmount ? (
          // PAYMENT AMOUNT SET
          <>
            <p className='info-text'>
              Your payment amount has been set. You will be paid upon successful completion of this
              campaign.
            </p>
            <div className='receipt'>
              <div className='line offer'>
                Payment amount
                <span className='num'>${optIn.paymentAmount.toFixed(2)}</span>
              </div>
              <div className='line fee'>
                5% processing fee
                <span>- ${(optIn.paymentAmount * 0.05).toFixed(2)}</span>
              </div>
              <div className='line total'>
                <div>
                  <Tag className='status-tag' color={paymentStatusColor(trolleyPayment[0]?.status)}>
                    {trolleyPayment[0]?.status?.toUpperCase() || 'PENDING'}
                  </Tag>
                </div>
                <span className='num'>
                  ${(optIn.paymentAmount - optIn.paymentAmount * 0.05).toFixed(2)}
                </span>
              </div>
            </div>
          </>
        ) : (
          // AWAITING OFFER
          <>
            <p className='info-text'>
              Please wait for the brand to review your opt-in and send their offer for payment.
            </p>
            <p>
              <ClockCircleOutlined /> Awaiting offer.
            </p>
          </>
        )
      ) : (
        // PAYMENT COMPLETED
        <>
          <p className='info-text'>
            You have received your payment! Thank you for participating in this campaign.
          </p>
          <div className='receipt'>
            <div className='line offer'>
              Payment amount
              <span className='num'>${optIn.paymentAmount?.toFixed(2)}</span>
            </div>
            <div className='line fee'>
              5% processing fee
              <span>
                {optIn.paymentAmount
                  ? `- $${(optIn.paymentAmount * 0.05).toFixed(2)}`
                  : `- $${(optIn.campaign.maxPaidAmount * 0.05).toFixed(2)}`}
              </span>
            </div>
            <div className='line total'>
              <div>
                <span className='status-tag paid'>PROCESSED</span> Total
              </div>
              <span className='num'>
                {optIn.paymentAmount
                  ? `$${(optIn.paymentAmount - optIn.paymentAmount * 0.05).toFixed(2)}`
                  : `$${(
                      optIn.campaign.maxPaidAmount -
                      optIn.campaign.maxPaidAmount * 0.05
                    ).toFixed(2)}`}
              </span>
            </div>
          </div>
        </>
      )}

      {/* PAYOUT SETTINGS */}
      <div className='payout-settings'>
        {userData?.trolleyStatus === 'active' ? (
          <Alert type='success' message='Payout method connected' showIcon />
        ) : (
          <Alert
            type='error'
            message='No payout method connected'
            description='Please add your payout settings in order to receive payment for this campaign.'
            showIcon
          />
        )}
        <Button type='link' icon={<SettingOutlined />} onClick={() => setPaymentsOpen(true)}>
          Payout Settings
        </Button>
      </div>

      <Drawer
        onClose={() => {
          setPaymentsOpen(false)
          queryClient.invalidateQueries('user')
        }}
        open={paymentsOpen}
        footer={null}
        width={window.innerWidth > 600 ? 600 : '100vw'}>
        <PayoutSettings />
      </Drawer>
    </div>
  )
}
