import { CaretRightFilled, CaretLeftFilled } from '@ant-design/icons'
import React, { useRef } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components/macro'

import VideoItem from './VideoItem'
import { isVideo } from '../../utils'

export const ImageCarouselSimple = ({ images }) => {
  const sliderRef = useRef()

  const sliderSettings = {
    slidesToShow: 1,
    fade: true,
    arrows: false,
  }

  return (
    <Wrapper>
      <button className='arrow prev' onClick={() => sliderRef.current.slickPrev()}>
        <CaretLeftFilled />
      </button>
      <button className='arrow next' onClick={() => sliderRef.current.slickNext()}>
        <CaretRightFilled />
      </button>

      <Slider className='slider' ref={sliderRef} {...sliderSettings}>
        {images.map(image => (
          <div className='img-frame' key={image.url}>
            {isVideo(image.url) ? (
              <VideoItem url={image.url} autoPlay />
            ) : (
              <img src={image.url} alt='' />
            )}
          </div>
        ))}
      </Slider>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: #fff;
  width: 100%;
  max-width: 100%;
  margin: auto;
  border-radius: 5px;
  position: relative;
  .arrow {
    background: transparent;
    color: #ccc;
    border: none;
    display: grid;
    position: absolute;
    top: 45%;
    font-size: 1.5rem;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    z-index: 1;
    &:hover {
      color: #999;
    }
    &.prev {
      left: -20px;
    }
    &.next {
      right: -20px;
    }
  }
  .slick-track {
    display: flex;
    align-items: center;
    div {
      display: grid;
    }
  }
  .slider {
    .img-frame {
      video,
      img {
        object-fit: cover;
        height: 250px;
        width: 250px;
        margin: auto;
        border-radius: 5px;
      }
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    max-width: 400px;
    .arrow {
      &.prev {
        left: 0;
      }
      &.next {
        right: 0;
      }
    }
    .slider {
      .img-frame {
        video,
        img {
          object-fit: cover;
          height: 300px;
          width: 300px;
          margin: auto;
        }
      }
    }
  }
`
