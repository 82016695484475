import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  StarOutlined,
  CloseCircleOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from '@ant-design/icons'
import { Select, Checkbox } from 'formik-antd'
import styled from 'styled-components/macro'
const { Option } = Select

export const OptInFilters = ({ submitForm }) => {
  return (
    <Wrapper>
      <Select
        getPopupContainer={trigger => trigger.parentNode}
        virtual={false}
        name='status'
        onChange={submitForm}
        allowClear
        placeholder='Status'
        style={{ width: '100%', minWidth: '150px' }}>
        <Option value='pending'>
          <ClockCircleOutlined /> Pending
        </Option>
        <Option value='activated'>
          <CheckCircleOutlined /> Activated
        </Option>
        <Option value='completed'>
          <StarOutlined /> Completed
        </Option>
        <Option value='cancelled'>
          <CloseCircleOutlined /> Cancelled
        </Option>
      </Select>
      <Select
        getPopupContainer={trigger => trigger.parentNode}
        virtual={false}
        name='sort'
        onChange={submitForm}
        placeholder='Sort'
        style={{ width: '100%', minWidth: '150px' }}>
        <Option value='created-desc'>
          <ArrowDownOutlined /> Newest
        </Option>
        <Option value='created-asc'>
          <ArrowUpOutlined /> Oldest
        </Option>
      </Select>
      <Checkbox name='hasUnread' onChange={submitForm}>
        New messages
      </Checkbox>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  white-space: nowrap;
  @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }
`
