import { CaretRightFilled, CaretLeftFilled } from '@ant-design/icons'
import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components/macro'

import { ImageLoader } from './ImageLoader'
import VideoItem from './VideoItem'
import { isVideo } from '../../utils'

export const ImageCarouselGallery = ({ images }) => {
  const sliderRef = useRef()
  const galleryRef = useRef()

  const [sliderIndex, setSliderIndex] = useState(0)
  const [sliderNav, setSliderNav] = useState()
  const [galleryNav, setGalleryNav] = useState()

  useEffect(() => {
    setSliderNav(sliderRef.current)
    setGalleryNav(galleryRef.current)
  }, [sliderRef, galleryRef])

  const sliderSettings = {
    slidesToShow: 1,
    asNavFor: galleryNav,
    fade: true,
    arrows: false,
    afterChange: newIndex => {
      setSliderIndex(newIndex)
    },
  }
  const gallerySettings = {
    slidesToShow: 3,
    arrows: false,
    centerMode: true,
    focusOnSelect: true,
    asNavFor: sliderNav,
  }

  const singleUrls = images.length
    ? images
        .filter(image => image.type !== 'inspiration')
        .map(image => {
          const single = image.sizes.find(size => size.size === 'single' || size.size === 'video')

          if (single && single.url) {
            return single.url
          } else return null
        })
    : []

  const galleryUrls = images.length
    ? images
        .filter(image => image.type !== 'inspiration')
        .map(image => {
          const gallery = image.sizes.find(
            size => size.size === 'gallery_thumbnail' || size.size === 'video'
          )
          if (gallery && gallery.url) {
            return gallery.url
          } else return null
        })
    : []
  return (
    <Wrapper>
      <button className='arrow prev' onClick={() => sliderRef.current.slickPrev()}>
        <CaretLeftFilled />
      </button>
      <button className='arrow next' onClick={() => sliderRef.current.slickNext()}>
        <CaretRightFilled />
      </button>

      <Slider className='primary-slider' ref={sliderRef} {...sliderSettings}>
        {singleUrls.map((url, i) => (
          <div className='img-frame' key={i}>
            {isVideo(url) ? (
              i === sliderIndex ? (
                <Video
                  key={url + i}
                  preload='auto'
                  controls={true}
                  muted={false}
                  autoPlay={true}
                  loop={true}>
                  <source src={url} type='video/mp4' />
                </Video>
              ) : (
                <></>
              )
            ) : (
              <ImageLoader key={i} src={url} />
            )}
          </div>
        ))}
      </Slider>

      <Slider className='gallery-slider' ref={galleryRef} {...gallerySettings}>
        {galleryUrls.map((url, i) => (
          <div className='img-frame' key={i}>
            {isVideo(url) ? <VideoItem url={url} /> : <ImageLoader src={url} />}
          </div>
        ))}
      </Slider>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: #fff;
  max-width: 100%;
  margin: auto;
  border-radius: 5px;
  position: relative;
  .arrow {
    background: transparent;
    color: #ccc;
    border: none;
    display: grid;
    position: absolute;
    top: 35%;
    font-size: 1.5rem;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    z-index: 1;
    &:hover {
      color: #999;
    }
    &.prev {
      left: -20px;
    }
    &.next {
      right: -20px;
    }
  }
  .slick-track {
    display: flex;
    align-items: center;
    .img-frame {
      display: grid !important;
      place-content: center;
    }
  }
  .primary-slider {
    .img-frame {
      video,
      img {
        object-fit: cover;
        height: 250px;
        width: 250px;
        margin: auto;
        border-radius: 5px;
      }
    }
  }
  .gallery-slider {
    margin-top: 20px;
    border-top: 1px solid #e6e6e6;
    padding: 10px;
    .img-frame {
      video,
      img {
        object-fit: cover;
        height: 60px;
        width: 60px;
        margin: auto;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    max-width: 400px;
    .arrow {
      &.prev {
        left: 0;
      }
      &.next {
        right: 0;
      }
    }
    .primary-slider {
      .img-frame {
        video,
        img {
          height: 300px;
          width: 300px;
        }
      }
    }
    .gallery-slider {
      margin-top: 30px;
      .img-frame {
        video,
        img {
          height: 80px;
          width: 80px;
        }
      }
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
    max-width: 500px;
    .primary-slider {
      .img-frame {
        video,
        img {
          height: 350px;
          width: 350px;
        }
      }
    }
    .gallery-slider .img-frame {
      video,
      img {
        height: 100px;
        width: 100px;
      }
    }
  }
`

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
