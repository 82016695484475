import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components/macro'

import { CampaignCard } from './CampaignCard'
import { GhostCampaignCard } from './GhostCampaignCard'
import { UserContext } from '../../../contexts/UserContext'

const CampaignsInner = ({ pages, fetchingMore }) => {
  const { fetchCurrentUser } = useContext(UserContext)
  const { data: userData } = useQuery('user', fetchCurrentUser)

  const campaigns = pages.flatMap(page => page.campaigns)

  return (
    <Wrapper>
      {campaigns.map(item => (
        <CampaignCard
          key={item.id}
          campaign={item}
          userData={userData}
          affiliateCommission={item.affiliateCommission}
        />
      ))}
      {fetchingMore && Array.from(Array(4).keys()).map(item => <GhostCampaignCard key={item} />)}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px;
  gap: 10px;
  margin: auto;
  @media only screen and (min-width: 700px) {
    padding: 30px;
    gap: 30px;
  }
`

export default CampaignsInner
