import { Button, Empty } from 'antd'
import axios from 'axios'
import React, { useEffect, useState, useContext } from 'react'
import { useQuery, useInfiniteQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { animated, useTrail } from 'react-spring'
import styled from 'styled-components/macro'

import { FeaturedCampaigns } from './FeaturedCampaigns'
import { API_URL } from '../../../constants'
import { UserContext } from '../../../contexts/UserContext'
import { useMetaData } from '../../../custom-hooks/useMetaData'
import { CampaignCard } from '../campaignHub/CampaignCard'
import { GhostCampaignCard } from '../campaignHub/GhostCampaignCard'

export const Wishlist = () => {
  useMetaData(
    'My Wishlist | Creator Dashboard',
    'View and manage campaigns you are interested in.',
    'https://creatorco.nyc3.cdn.digitaloceanspaces.com/assets/preview-images/meta-preview.png'
  )

  const { fetchCurrentUser } = useContext(UserContext)
  const { data: userData, status: userStatus } = useQuery('user', fetchCurrentUser)

  const items = [
    <WishlistCampaigns key='wishlist-campaigns' userData={userData} />,
    <FeaturedCampaigns key='featured-campaigns' userData={userData} />,
  ]

  const trail = useTrail(items.length, {
    config: {
      mass: 0.5,
      tension: 1200,
      friction: 100,
    },
    from: { left: '-16px', opacity: 0 },
    opacity: 1,
    left: '0px',
  })

  return (
    <Wrapper>
      {userStatus === 'success' && userData
        ? trail.map((props, key) => (
            <animated.div key={key} style={{ ...props, position: 'relative' }}>
              {items[key]}
            </animated.div>
          ))
        : Array.from(Array(3).keys()).map(item => (
            <section className='skelement' key={item}>
              <div className='title loading-gradient' />
              <div className='text loading-gradient' />
              <div className='body loading-gradient' />
            </section>
          ))}
    </Wrapper>
  )
}

const WishlistCampaigns = ({ userData }) => {
  const [pinnedCampaigns, setPinnedCampaigns] = useState([])

  const { data, status } = useInfiniteQuery(
    ['pinned-campaigns'],
    async ({ pageParam = 1, queryKey }) => {
      const { data } = await axios.get(`${API_URL}/creator/pinned-campaigns/${pageParam}`, {
        params: queryKey[1],
      })
      return data
    },
    {
      getNextPageParam: lastPage => lastPage.nextPage,
    }
  )

  useEffect(() => {
    if (data) {
      let { pages } = data
      const campaigns = pages.map(({ campaigns }) => campaigns).flatMap(c => c)
      setPinnedCampaigns(
        campaigns.map(campaign => (
          <CampaignCard key={campaign.id} campaign={campaign} userData={userData} />
        ))
      )
    }
  }, [data, userData])

  return (
    <section className='wishlist'>
      <h1>My Wishlist</h1>
      <div className='campaigns'>
        {status === 'success' ? (
          pinnedCampaigns.length ? (
            pinnedCampaigns
          ) : (
            <div className='no-results'>
              <Empty description='Your wishlist is empty.' />
              <Link to='/collaboration-hub' className='cta'>
                <Button type='primary'>Explore Campaigns</Button>
              </Link>
            </div>
          )
        ) : (
          Array.from(Array(6).keys()).map(item => <GhostCampaignCard key={item} />)
        )}
      </div>
    </section>
  )
}

const Wrapper = styled.div`
  min-height: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 20px;
  section {
    background: #fff;
    padding: 40px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;
  }
  .campaigns {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .no-results {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    padding: 20px;
    section {
      border-radius: 10px;
    }
    .campaigns {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: normal;
      padding: 20px;
      gap: 20px;
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
    max-width: 1200px;
    margin: auto;
    padding: 40px;
    gap: 40px;
    section {
      padding: 30px;
    }
  }
`
