import React from 'react'
import styled, { keyframes } from 'styled-components'

export const Pulser = () => {
  return (
    <Wrapper>
      <div className='ring'></div>
      <div className='circle'></div>
    </Wrapper>
  )
}

const pulseAnimation = keyframes`
   0% {
      transform: scale(0.1, 0.1);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1.1, 1.1);
      opacity: 0;
    }
`

const Wrapper = styled.div`
  position: relative;
  height: 20px;
  width: 20px;
  .circle {
    width: 10px;
    height: 10px;
    background-color: ${props => props.theme.crcoCoral};
    border-radius: 50%;
    position: absolute;
    top: 5px;
  }
  .ring {
    border: 2px solid ${props => props.theme.crcoCoral};
    border-radius: 50%;
    height: 20px;
    width: 20px;
    position: absolute;
    left: -5px;
    opacity: 0;
    animation: ${pulseAnimation} 1s ease-in-out infinite;
  }
`
