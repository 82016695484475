import moment from 'moment'
import React from 'react'

const CJAffiliateEvent = ({ event }) => {
  return (
    <>
      <div className='event-brand'>
        <div className='name'>{event.brand.name}</div>
      </div>
      <div className='event-metrics'>
        <div className='metric amount'>
          <span className='metric-title'>Sale Amount - </span>
          <span className='metric-value'>${event.amount}</span>
        </div>
        <div className='metric commission'>
          <span className='metric-title'>Commission - </span>
          <span className='metric-value'>${event.commission}</span>
        </div>
        <div className='metric date'>
          <span className='metric-title'>Date - </span>
          <span className='metric-value'>{moment(event.created).format('MM-DD-YYYY')}</span>
        </div>
      </div>
    </>
  )
}

export default CJAffiliateEvent
