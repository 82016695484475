import { InfoCircleOutlined, PlusCircleFilled } from '@ant-design/icons'
import { Button, Popover, message } from 'antd'
import axios from 'axios'
import { Form, Formik } from 'formik'
import { Input } from 'formik-antd'
import { truncate } from 'lodash'
import moment from 'moment'
import React from 'react'
import { useQueryClient } from 'react-query'
import styled from 'styled-components'
import * as yup from 'yup'

import { API_URL } from '../../../../constants'
import { FormItem } from '../../../general/forms/FormItem'

const ContentSubmission = ({ optInId, socialPosts, shoutoutStepsCompleted }) => {
  const queryClient = new useQueryClient()
  const validationSchema = yup.object().shape({
    url: yup.string().url().required(),
  })

  return (
    <Wrapper>
      <h2>Content Submission</h2>
      <p>
        View all content submitted through your campaign steps here.{' '}
        {shoutoutStepsCompleted && 'You can also submit additional content as needed. '}
        Please note, metrics update every 24 hours.
      </p>
      {shoutoutStepsCompleted && (
        <Formik
          initialValues={{ url: '' }}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            await axios
              .post(`${API_URL}/me/opt-in/${optInId}/social-posts`, values)
              .then(() => {
                message.success('Content submitted successfully.')
                queryClient.invalidateQueries('opt-in')
                resetForm()
              })
              .catch(err => {
                message.error(`Failed to submit content. ${err.response.data.err}`)
                console.error(err)
              })
          }}>
          {({ isSubmitting }) => (
            <Form>
              <FormItem name='url' size='small'>
                <div className='content-submission'>
                  <Input name='url' placeholder='ShoutOut URL' />
                  <div>
                    <Button
                      loading={isSubmitting}
                      htmlType='primary'
                      type='link'
                      icon={<PlusCircleFilled />}
                    />
                  </div>
                </div>
              </FormItem>
            </Form>
          )}
        </Formik>
      )}
      <div className='content-list'>
        {socialPosts.map(post => {
          return (
            <div key={post.id} className='content-item'>
              <div className='item-image'>
                <div style={{ backgroundImage: `url("${post.thumbnailUrl}")` }} />
              </div>
              <div className='item-info'>
                <a href={post.url} target='_blank' rel='noopener noreferrer'>
                  {truncate(post.url, { length: 50 })}
                </a>
                <p>{moment(new Date(post.posted)).format('lll')}</p>
                <p>
                  {post.type}
                  {post.format !== post.type && ` | ${post.format}`}
                </p>
                <div className='item-metrics'>
                  <Popover
                    content={
                      <PopoverContent>
                        <h3>Metrics</h3>
                        <div className='metrics'>
                          {!!post.views && (
                            <div className='metric'>
                              <span>Views</span>
                              <span>{post.views.toLocaleString()}</span>
                            </div>
                          )}

                          {!!post.impressions && (
                            <div className='metric'>
                              <span>Impressions</span>
                              <span>{post.impressions.toLocaleString()}</span>
                            </div>
                          )}

                          {!!post.reach && (
                            <div className='metric'>
                              <span>Reach</span>
                              <span>{post.reach.toLocaleString()}</span>
                            </div>
                          )}

                          {!!post.likes && (
                            <div className='metric'>
                              <span>Likes</span>
                              <span>{post.likes.toLocaleString()}</span>
                            </div>
                          )}

                          {!!post.comments && (
                            <div className='metric'>
                              <span>Comments</span>
                              <span>{post.comments.toLocaleString()}</span>
                            </div>
                          )}

                          {!!post.shares && (
                            <div className='metric'>
                              <span>Shares</span>
                              <span>{post.shares.toLocaleString()}</span>
                            </div>
                          )}
                        </div>
                      </PopoverContent>
                    }>
                    <InfoCircleOutlined /> Metrics
                  </Popover>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .content-submission {
    display: flex;
    gap: 12px;
    margin-bottom: 12px;
    align-items: center;

    .ant-btn {
      padding: 0;
      font-size: 20px;

      span {
        font-size: 24px;
      }
    }
  }
  .content-list {
    max-height: 400px;
    overflow-y: auto;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }
  .content-item {
    margin-bottom: 12px;
    display: flex;
    .item-image {
      & > div {
        width: 125px;
        height: 125px;
        background-color: #d9d9d9;
        border-radius: 5px;
        background-size: cover;
      }
    }
    .item-info {
      padding: 0 10px;
      p {
        margin: 0;
      }
    }
    .item-metrics {
      margin-top: 12px;
      & > span {
        color: #1890ff;
        cursor: default;
      }
    }
  }
`

const PopoverContent = styled.div`
  .metrics {
    width: 200px;
  }
  .metric {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
`

export default ContentSubmission
