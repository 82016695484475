import React from 'react'
import { animated, useSpring } from 'react-spring'
import styled from 'styled-components/macro'

export const GhostCampaignCard = () => {
  const fadeIn = useSpring({
    from: { opacity: 0.5 },
    to: { opacity: 1 },
  })

  return (
    <Wrapper data-cy='ghost-campaign-card'>
      <animated.div className='fade-in' style={fadeIn}>
        <div className='inner'>
          <div className='header'>
            <div className='brand loading-gradient' />
            <div className='title loading-gradient' />
          </div>
          <div className='body'>
            <div className='product'>
              <div className='thumbnail loading-gradient' />
            </div>
            <div className='details'>
              <div className='qualifications'>
                <div className='row'>
                  <span className='label'>Followers</span>
                  <span className='value loading-gradient'></span>
                </div>
                <div className='row'>
                  <span className='label'>Engagement</span>
                  <span className='value loading-gradient'></span>
                </div>
                <div className='row'>
                  <span className='label'>Age</span>
                  <span className='value loading-gradient'></span>
                </div>
                <div className='row'>
                  <span className='label'>Residence</span>
                  <span className='value loading-gradient'></span>
                </div>
                <div className='row niche'></div>
              </div>
              <div className='objectives'>
                <div className='row'>
                  <span className='icon loading-gradient'></span>
                  <span className='icon loading-gradient'></span>
                  <span className='content-formats loading-gradient'></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </animated.div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: #fff;
  border: 1px solid #e0e0f7;
  color: ${props => props.theme.crcoMidnight};
  width: 100%;
  max-width: 400px;
  border-radius: 20px;
  transition: 0.2s ease-in-out;
  .inner {
    padding: 10px;
  }
  .header {
    margin: 10px 0;
    .brand {
      height: 25px;
      margin-bottom: 10px;
      border-radius: 5px;
    }
    .title {
      height: 40px;
      margin-bottom: 10px;
      border-radius: 5px;
    }
  }
  .body {
    display: flex;
    border-top: 1px solid #f6f6f6;
  }
  .product {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border-right: 1px solid #f6f6f6;
  }
  .thumbnail {
    height: 125px;
    width: 125px;
    border-radius: 5px;
  }
  .retail-value {
    color: #999;
    display: flex;
    justify-content: center;
    font-size: 0.7rem;
    .value {
      height: 15px;
      width: 50px;
      margin-left: 5px;
      border-radius: 5px;
    }
  }
  .details {
    width: 50%;
    padding: 10px;
    .header-text {
      margin-bottom: 5px;
    }
    .qualifications {
      padding-bottom: 5px;
      border-bottom: 1px solid #f6f6f6;
      margin-bottom: 10px;
      .row {
        display: flex;
        justify-content: space-between;
        .label {
          color: #999;
          font-size: 0.7rem;
        }
        .value {
          height: 15px;
          width: 50px;
          border-radius: 5px;
        }
        &.niche {
          height: 20px;
          border-radius: 10px;
        }
      }
    }
    .objectives {
      .row {
        display: flex;
        justify-content: center;
        .icon {
          height: 20px;
          width: 20px;
          margin-right: 10px;
          border-radius: 50%;
        }
        .content-formats {
          border-radius: 5px;
          width: 100px;
        }
      }
    }
  }
  @media only screen and (min-width: 900px) {
    max-width: 310px;
    .inner {
      padding: 20px;
    }
    .header {
      .title {
        height: 60px;
      }
    }
    .body {
      flex-direction: column;
      border: 0;
    }
    .product {
      width: 100%;
      border: 0;
    }
    .thumbnail {
      height: 200px;
      width: 200px;
      margin-bottom: 10px;
    }
    .retail-value {
      font-size: 0.8rem;
      .value {
        margin-left: 10px;
      }
    }
    .details {
      width: 100%;
      text-align: center;
      margin-top: 10px;
      border-top: 1px solid #e6e6e6;
      padding: 10px 0 0 0;
      .qualifications {
        .row {
          .label {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
`
