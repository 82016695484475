import {
  ShopOutlined,
  SettingOutlined,
  HeartFilled,
  StarOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  RightOutlined,
} from '@ant-design/icons'
import { Alert, Rate, Empty, Button } from 'antd'
import axios from 'axios'
import React, { useContext, useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { Link, useHistory } from 'react-router-dom'
import { animated, useTrail } from 'react-spring'
import styled from 'styled-components/macro'

import { FeaturedCampaigns } from './FeaturedCampaigns'
import { API_URL } from '../../../constants.js'
import { OptInContext } from '../../../contexts/OptInContext'
import { UserContext } from '../../../contexts/UserContext'
import { useMetaData } from '../../../custom-hooks/useMetaData'

const DashboardHome = () => {
  useMetaData(
    'Home | Creator Dashboard',
    'Collaboration and account management dashboard for creators.',
    'https://creatorco.nyc3.cdn.digitaloceanspaces.com/assets/preview-images/meta-preview.png'
  )
  const { fetchCurrentUser } = useContext(UserContext)
  const { data: userData, status: userStatus } = useQuery('user', fetchCurrentUser)

  const items = [
    <Overview key={1} userData={userData} />,
    <FeaturedCampaigns key={2} userData={userData} />,
  ]

  const trail = useTrail(items.length, {
    config: {
      mass: 0.5,
      tension: 1200,
      friction: 100,
    },
    from: { left: '-16px', opacity: 0 },
    opacity: 1,
    left: '0px',
  })

  return (
    <Wrapper className='content'>
      {userStatus === 'success' && userData
        ? trail.map((props, key) => (
            <animated.div key={key} style={{ ...props, position: 'relative' }}>
              {items[key]}
            </animated.div>
          ))
        : Array.from(Array(3).keys()).map(item => (
            <section className='skelement' key={item}>
              <div className='title loading-gradient' />
              <div className='text loading-gradient' />
              <div className='body loading-gradient' />
            </section>
          ))}
    </Wrapper>
  )
}

const Notices = ({ userData }) => {
  let today = new Date()
  let registeredDate = new Date(userData?.registered)
  let daysRegistered = Math.floor((today.getTime() - registeredDate.getTime()) / (1000 * 3600 * 24))

  const [notices, setNotices] = useState([])

  useEffect(() => {
    // setting notices once userData is loaded
    if (userData) {
      // new user (signed up within past week)
      if (daysRegistered <= 7) {
        setNotices(prev => ({ ...prev, newUser: true }))
      }
      // no social accounts connected
      if (!userData.socialProfiles?.length) {
        setNotices(prev => ({ ...prev, noSocialAccounts: true }))
      }
      // expired social account connections
      userData.socialProfiles?.forEach(profile => {
        if (!profile?.phylloData?.account?.id) return
        axios.get(`${API_URL}/phyllo-account/${profile.phylloData.account.id}`).then(res => {
          if (res.data.status === 'SESSION_EXPIRED') {
            setNotices(prev => ({ ...prev, expiredPhylloConnections: true }))
          }
        })
      })
      // missing certain information
      if (!userData.creatorProfile?.birthDate) {
        setNotices(prev => ({ ...prev, missingBirthDate: true }))
      }
      if (!userData.creatorProfile?.shippingCountry) {
        setNotices(prev => ({ ...prev, missingCountry: true }))
      }
      if (!userData.creatorProfile?.niches?.length) {
        setNotices(prev => ({ ...prev, missingNiches: true }))
      }
    }
  }, [userData, daysRegistered])

  return (
    <div className='notices'>
      {notices.newUser && (
        <Alert
          message='Welcome!'
          description={
            <div>
              <p>
                We&apos;re so glad you signed up. Check out the Collaboration Hub and explore
                hundreds of awesome opportunities, from ShoutOuts to Giveaways and Paid Campaigns!
              </p>
              <Link to='collaboration-hub'>
                <ShopOutlined /> Collaboration Hub
              </Link>
            </div>
          }
          type='info'
        />
      )}
      {notices.noSocialAccounts && (
        <Alert
          message='Connect your socials'
          description={
            <div>
              <p>
                In order to opt in to platform-specific campaigns, you must connect your social
                accounts.
              </p>
              <Link to='/my-settings'>
                <SettingOutlined /> Settings
              </Link>
            </div>
          }
          type='warning'
        />
      )}
      {notices.expiredPhylloConnections && (
        <Alert
          message='Reconnect social account(s)'
          description={
            <div>
              <p>Your social account connection(s) have expired. Please reconnect.</p>
              <Link to='/my-settings'>
                <SettingOutlined /> Settings
              </Link>
            </div>
          }
          type='warning'
        />
      )}
      {(notices.missingBirthDate || notices.missingCountry || notices.missingNiches) && (
        <Alert
          message='Complete your profile'
          description={
            <div>
              <p>We need the following info to check your eligibility for campaigns:</p>
              <ul>
                {notices.missingBirthDate && (
                  <li>Birthdate (you must be at least 13 to participate in most campaigns)</li>
                )}
                {notices.missingCountry && (
                  <li>Residence (certain brands can only ship products to specific locations)</li>
                )}
                {notices.missingNiches && (
                  <li>Niche (your social media content must match the brand&apos;s niche)</li>
                )}
              </ul>
              <Link to='/my-settings'>
                <SettingOutlined /> Settings
              </Link>
            </div>
          }
          type='warning'
        />
      )}
    </div>
  )
}

const Overview = ({ userData }) => {
  const history = useHistory()
  const { optIns } = userData
  const { setFormData } = useContext(OptInContext)
  const [averageRating, setAverageRating] = useState(0)
  const [numRatings, setNumRatings] = useState(0)

  const [campaignCounts, setCampaignCounts] = useState({
    pending: 0,
    activated: 0,
    completed: 0,
    cancelled: 0,
  })

  useEffect(() => {
    if (optIns?.length) {
      const ratings = optIns
        .map(optIn => {
          const rating = optIn.rating
          if (!rating) return null
          return (
            // Add up ratings in each category and divide to get average for each opt-in
            (Number(rating?.content || 0) +
              Number(rating?.creativity || 0) +
              Number(rating?.communication || 0) +
              Number(rating?.overall) || 0) / 4
          )
        })
        .filter(rating => rating !== null)

      // Get the average of all ratings
      const avg = ratings.length ? ratings.reduce((a, b) => a + b, 0) / ratings.length : null

      setAverageRating(avg)
      setNumRatings(ratings.length)

      setCampaignCounts({
        pending: optIns.filter(optIn => optIn.status === 'pending')?.length || 0,
        activated: optIns.filter(optIn => optIn.status === 'activated')?.length || 0,
        completed: optIns.filter(optIn => optIn.status === 'completed')?.length || 0,
        cancelled: optIns.filter(optIn => optIn.status === 'cancelled')?.length || 0,
      })
    }
  }, [optIns])

  const handleCampaignStatusSelection = status => {
    setFormData({ status })
    history.push('/my-campaigns')
  }

  return (
    <>
      <Notices userData={userData} />

      <div className='greeting'>
        <h1>Hi, {userData.firstName}</h1>
        <p>Welcome to your Creator Dashboard. Let&apos;s collaborate!</p>
      </div>

      <div className='overview'>
        <section className='campaigns-summary'>
          <h2>Your Campaigns</h2>

          <div className='tags'>
            {['pending', 'activated', 'completed', 'cancelled'].map(status => (
              <div
                key={status}
                className='tag'
                onClick={() => handleCampaignStatusSelection(status)}
                onKeyDown={() => handleCampaignStatusSelection(status)}
                role='button'
                tabIndex='0'>
                <p className={`label ${status}`}>
                  {status === 'pending' && <ClockCircleOutlined />}
                  {status === 'activated' && <CheckCircleOutlined />}
                  {status === 'completed' && <StarOutlined />}
                  {status === 'cancelled' && <CloseCircleOutlined />}
                  <span className='label-text'>{status}</span>
                </p>
                <span className={`num ${campaignCounts[status] === 0 && 'none'}`}>
                  {campaignCounts[status]}
                </span>
              </div>
            ))}
          </div>
          <Link to='/my-campaigns' onClick={() => setFormData({})}>
            <Button type='link'>
              See All <RightOutlined />
            </Button>
          </Link>
        </section>

        <section>
          <h2>Your Average Rating</h2>
          {numRatings > 0 ? (
            <div className='rating'>
              <Rate
                value={averageRating}
                character={<HeartFilled />}
                className='rate'
                disabled
                allowHalf
              />
              <p className='info-text'>Total Ratings: {numRatings}</p>
            </div>
          ) : (
            <div className='no-results'>
              <Empty description='No ratings yet.' />
              <Link to='/collaboration-hub'>
                <Button type='primary'>Get Started</Button>
              </Link>
            </div>
          )}
        </section>
      </div>
    </>
  )
}

const Wrapper = styled.div`
  min-height: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .greeting {
    padding: 10px 20px;
    h1 {
      margin: 0;
    }
    p {
      color: ${props => props.theme.crcoGrey};
    }
  }
  .notices {
    padding: 10px;
  }
  ul {
    list-style: disc inside;
    padding: 0;
  }
  section {
    background: #fff;
    padding: 40px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;
  }
  .section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    h2 {
      margin: 0;
    }
  }
  .skelement {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .loading-gradient {
      border-radius: 3px;
      background-image: linear-gradient(90deg, #f6f6f6 0px, #fafafa 100px, #f6f6f6 300px);
      background-size: 100vw 100%;
      animation: shine 1.5s infinite ease-in-out;
    }
    .title {
      height: 40px;
      width: 100%;
      max-width: 300px;
    }
    .text {
      height: 20px;
      width: 100%;
      max-width: 600px;
      margin-bottom: 10px;
    }
    .body {
      height: 50px;
    }
  }
  .overview {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .card-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      h2 {
        margin: 0;
      }
    }
    .tags {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      margin: auto;
      padding: 20px 0;
    }
    .tag {
      padding: 15px;
      margin: 0;
      border-radius: 20px;
      border: 1px solid #e6e6e6;
      display: flex;
      flex-direction: column;
      gap: 10px;
      cursor: pointer;
      transition: 0.2s ease-in-out;
      .label {
        color: ${props => props.theme.crcoGrey};
        text-transform: uppercase;
        letter-spacing: 1px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        margin: 0;
        &.pending {
          background: #eee;
        }
        &.activated {
          background: #e6f7ff;
          color: ${props => props.theme.crcoTechBlue};
        }
        &.completed {
          background: rgb(22 217 110 / 10%);
          color: ${props => props.theme.crcoLettuce};
        }
        &.cancelled {
          background: #fff1f0;
          color: ${props => props.theme.crcoCoral};
        }
        .label-text {
          margin-top: 2px;
        }
      }
      .num {
        font-size: 22px;
        padding: 0 10px;
        color: ${props => props.theme.crcoMidnight};
        &.none {
          opacity: 0.3;
        }
      }
      &:hover {
        border-color: ${props => props.theme.crcoTechBlue};
        color: ${props => props.theme.crcoTechBlue};
      }
    }
    .rating {
      text-align: center;
      margin: auto;
    }
    .rate {
      font-size: 26px;
      color: ${props => props.theme.crcoCoral};
    }
  }
  .campaigns {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .no-results {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    margin: auto;
  }
  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    padding: 20px;
    .greeting {
      p {
        font-size: 16px;
      }
    }
    section {
      border-radius: 10px;
    }
    .notices {
      padding: 0;
    }
    .campaigns {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: normal;
      padding: 20px;
      gap: 20px;
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
    max-width: 1200px;
    margin: auto;
    padding: 40px;
    gap: 40px;
    section {
      flex: 1;
      padding: 30px;
    }
    .overview {
      flex-direction: row;
      gap: 40px;
    }
  }

  @keyframes shine {
    0% {
      background-position-x: -20vw;
    }
    95%,
    100% {
      background-position-x: 85vw;
    }
  }
`

export default DashboardHome
