import { DollarCircleOutlined } from '@ant-design/icons'
import { Button, Drawer, Empty, Tag } from 'antd'
import axios from 'axios'
import { Form, Formik } from 'formik'
import { DatePicker, Select } from 'formik-antd'
import numeral from 'numeral'
import React, { useContext, useState } from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components/macro'

import { API_URL } from '../../../../constants'
import { UserContext } from '../../../../contexts/UserContext'
import LoadingLogo from '../../../general/LoadingHeart'

const getDateString = date => {
  const dateObj = new Date(date)
  dateObj.setMonth(dateObj.getMonth() + 1)
  return `${dateObj.toLocaleDateString('US-en', {
    month: 'long',
  })} ${dateObj.getDate()}, ${dateObj.getFullYear()}`
}

const SideDrawer = ({ openAffiliateDrawer, setOpenAffiliateDrawer }) => {
  const { fetchCurrentUser } = useContext(UserContext)
  const { data: userData } = useQuery('user', fetchCurrentUser)

  const closeDrawer = () => {
    setOpenAffiliateDrawer(false)
  }

  const fetchAffiliateCreatorBatches = async () => {
    const currentBatchId = userData?.extraData?.currentBatchId
    const openBatchIds = userData?.extraData?.openBatchIds
    const params = [currentBatchId, ...openBatchIds]

    const { data } = await axios.get(`${API_URL}/creator/affiliates/affiliate-batches`, {
      params,
    })
    return data
  }

  const { data: affiliateBatches, status: affiliateBatchesStatus } = useQuery(
    ['affiliateBatches'],
    fetchAffiliateCreatorBatches
  )

  const totalPayments = affiliateBatches
    ?.map(batch => +batch.amount)
    .reduce((acc, cur) => acc + cur)

  return (
    <Drawer
      title='Pending Payouts'
      open={openAffiliateDrawer}
      onClose={closeDrawer}
      width={window.innerWidth > 300 ? 436 : '100%'}
      placement='right'>
      {affiliateBatchesStatus === 'success' && (
        <DrawerWrapper>
          {affiliateBatches.length ? (
            <>
              <div className='total'>
                Total Payout: <span className='payout'>${totalPayments}</span>
              </div>
              {affiliateBatches?.map((batch, index) => (
                <div className='batch' key={index}>
                  <div className='batch-details'>
                    <div className='detail'>
                      <span className='detail-light'>Payments:</span> {batch?.totalPayments}
                    </div>
                    <div className='detail'>
                      <span className='detail-light'>Payout:</span>{' '}
                      <span className='payout'>
                        ${batch?.amount} <span className='currency'>{batch?.currency}</span>
                      </span>
                    </div>
                  </div>
                  <div className='tags'>
                    <Tag color='default' className='tag'>
                      {batch?.status}
                    </Tag>
                    <div color='blue' className='date'>
                      {getDateString(batch?.payoutDate)}
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className='empty'>
              <Empty description='No open batches found.' />
            </div>
          )}
        </DrawerWrapper>
      )}
      {affiliateBatchesStatus === 'loading' && (
        <div style={{ height: '100%', display: 'grid', placeItems: 'center' }}>
          <LoadingLogo />
        </div>
      )}
    </Drawer>
  )
}

const AffiliateCreatorCo = () => {
  const [filters, setFilters] = useState({})
  const [openAffiliateDrawer, setOpenAffiliateDrawer] = useState(false)

  const fetchAffiliateAnalytics = async key => {
    const { queryKey } = key
    const params = queryKey[1]
    const { data } = await axios.get(`${API_URL}/creator/affiliates/affiliate-analytics`, {
      params,
    })
    return data
  }

  const { data: affiliateAnalytics, status: affiliateAnalyticsStatus } = useQuery(
    ['affiliateAnalytics', filters],
    fetchAffiliateAnalytics
  )
  const { totalClicks, totalConversions, totalCommissions } = affiliateAnalytics || {}

  const fetchFilterAffiliates = async () => {
    const { data } = await axios.get(`${API_URL}/creator/affiliates/filter-analytics`)

    return data
  }

  const { data: affiliateFilters, status: affiliateFilterStatus } = useQuery(
    ['affiliateFilters'],
    fetchFilterAffiliates
  )

  return (
    <Wrapper>
      <div className='inner'>
        <div className='inner-content'>
          <Formik
            initialValues={{ brand: '' }}
            onSubmit={values => {
              setFilters(prev => ({ ...prev, ...values }))
            }}>
            {({ values, submitForm }) => (
              <Form className='form'>
                <div className='filters-box'>
                  <div className='filters'>
                    <section>
                      <Select
                        value={values.brand}
                        onChange={submitForm}
                        className='creator-select'
                        loading={affiliateFilterStatus === 'loading'}
                        name='brand'>
                        <Select.Option value={''}>All Brands</Select.Option>
                        {affiliateFilters?.map(brand => (
                          <Select.Option value={brand.id} key={brand.id}>
                            {`${brand.name}`}
                          </Select.Option>
                        ))}
                      </Select>
                    </section>
                    <section>
                      <div className='date-filter'>
                        <DatePicker onChange={submitForm} placeholder='From Date' name='fromDate' />
                        {' - '}
                        <DatePicker onChange={submitForm} placeholder='To Date' name='toDate' />
                      </div>
                    </section>
                  </div>
                  <Button
                    onClick={() => setOpenAffiliateDrawer(true)}
                    icon={<DollarCircleOutlined />}
                    type='primary'>
                    Pending Payouts
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
          {affiliateAnalyticsStatus === 'success' && (
            <div className='data'>
              <div className='overview'>
                <div className='overview-item'>
                  <span className='title'>Clicks</span>
                  <div className={!totalClicks ? 'text-grey value' : 'text-primaryBlue value'}>
                    {totalClicks || 0}
                  </div>
                </div>
                <div className='overview-item'>
                  <span className='title'>Conversions</span>
                  <div className={!totalConversions ? 'text-grey value' : 'text-primaryBlue value'}>
                    {totalConversions ? totalConversions : 0}
                  </div>
                </div>
                <div className='overview-item'>
                  <span className='title'>Commissions</span>
                  <div className={!totalCommissions ? 'text-grey value' : 'text-primaryBlue value'}>
                    ${totalCommissions ? totalCommissions : 0}
                  </div>
                </div>
              </div>
              <div className='affiliates'>
                {affiliateAnalytics?.affiliateLinks?.length ? (
                  <>
                    <div className='affiliate-header'>
                      <div className='affiliate-name'>Affiliate Links</div>
                      <div className='clicks'>Clicks</div>
                      <div className='conversions'>Conversions</div>
                      <div className='commissions'>Commissions</div>
                    </div>
                    <div className='affiliate-list'>
                      {affiliateAnalytics?.affiliateLinks?.map((link, id) => (
                        <div className='affiliate' key={id}>
                          <div className='affiliate-name'>
                            <div className='url'>{link.urlPath}</div>
                            <div className='description'>{link.description}</div>
                          </div>
                          <div className='affiliate-analytics'>
                            <div className='clicks'>
                              <span className={!link.clicks ? 'text-grey ' : 'text-primaryBlue '}>
                                {numeral(link.clicks).format('0a')}
                              </span>
                              <span className='affiliate-analytics-text'>clicks</span>
                            </div>
                            <span className='analytics-separator'>•</span>
                            <div className='conversions'>
                              <span
                                className={!link.conversions ? 'text-grey ' : 'text-primaryBlue '}>
                                {numeral(link.conversions).format('0a')}
                              </span>
                              <span className='affiliate-analytics-text'>conversions</span>
                            </div>
                            <span className='analytics-separator'>•</span>
                            <div className='commissions'>
                              <span
                                className={!link.commissions ? 'text-grey ' : 'text-primaryBlue '}>
                                ${numeral(link.commissions).format('0a')}
                              </span>
                              <span className='affiliate-analytics-text'>commissions</span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <div className='empty'>
                    <Empty description='No affiliate activity found.' />
                  </div>
                )}
              </div>
            </div>
          )}
          {affiliateAnalyticsStatus === 'loading' && (
            <div className='loading'>
              <LoadingLogo />
            </div>
          )}
        </div>
      </div>

      <SideDrawer
        openAffiliateDrawer={openAffiliateDrawer}
        setOpenAffiliateDrawer={setOpenAffiliateDrawer}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  color: black;
  width: 90vw;
  max-width: 480px;
  margin: 0 auto;

  @media only screen and (min-width: ${props => props.theme.breakpointMobile}) {
    width: 100%;
    max-width: 1200px;
  }

  .text-grey {
    opacity: 0.5;
  }

  .text-primaryBlue {
    color: #027df0;
  }

  .inner {
    height: 100%;
  }

  .inner-content {
    background-color: #fff;
    padding: 20px 0px;
    border-radius: 10px;
    margin: 0 auto;

    .form {
      .filters-box {
        background-color: #fff;
        padding: 0 20px 15px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
        margin-bottom: 24px;
        box-shadow: 0px 5px 10px -10px #000000ab;
        z-index: 1;

        .filters {
          display: flex;
          flex-wrap: wrap;
          gap: 30px;
        }

        .date-filter {
          label {
            margin-right: 8px;
          }
        }

        .creator-select {
          min-width: 250px;
        }
      }
    }
  }

  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    .inner-content {
      padding: 20px;
    }
  }

  .data {
    .overview {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      margin-bottom: 16px;
      padding: 0px 20px;
    }

    @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
      .overview {
        padding: 0px;
      }
    }

    .overview-item {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #fff;
      padding: 12px;
      border: 1px solid #e6e6e6;
      border-radius: 5px;

      .title {
        font-size: 18px;
        text-transform: uppercase;
        opacity: 0.5;
      }

      .value {
        font-size: 26px;
      }
    }

    .affiliates {
      border: 1px solid #e6e6e6;
      border-radius: 10px;
      padding: 10px;
      background-color: #f2f2f2;
      margin: 0 15px;

      .affiliate-header {
        display: none;
        font-size: 1.1rem;
        margin-bottom: 12px;
        margin-right: 12px;
        padding-left: 12px;
        padding-right: 12px;

        .affiliate-name {
          flex: 2;
        }

        .clicks,
        .conversions,
        .commissions {
          font-size: 1.1rem;
          text-align: center;
          flex: 1;
        }
      }

      @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
        .affiliate-header {
          display: flex;
        }
      }

      .affiliate-list {
        max-height: 48vh;
        overflow-y: scroll;
        ${props => props.theme.scrollbar}

        .affiliate {
          display: flex;
          padding: 15px 12px;
          background-color: #fff;
          display: flex;
          flex-direction: column;
          justify-items: flex-start;
          align-items: flex-start;

          .affiliate-name {
            flex: 2;

            .url {
              font-size: 1rem;
              // max 1 line with ellipsis text overflow
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .description {
              font-size: 0.9rem;
              color: #666;
              // max 3 line with ellipsis text overflow
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .affiliate-analytics {
            border-top: 1px solid lightgray;
            padding-top: 5px;
            flex: 3;
            display: flex;
            align-items: baseline;
            flex-wrap: wrap;
            gap: 10px;
            width: 100%;
            margin-top: 5px;

            .clicks,
            .conversions,
            .commissions {
              font-size: 12px;
              text-align: left;
              display: flex;
              gap: 3px;
            }
          }

          &:not(:last-child) {
            border-bottom: 1px solid lightgray;
          }
        }
      }
    }

    @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
      .affiliates {
        padding: 10px 10px;
        margin: 0 auto;
      }
    }

    @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
      .affiliates {
        .affiliate-list {
          padding-left: 12px;
          padding-right: 12px;

          .affiliate {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .affiliate-analytics {
              border-top: 0px;
              gap: 0;

              .analytics-separator {
                display: none;
              }

              .clicks,
              .conversions,
              .commissions {
                text-align: center;
                font-size: 1.1rem;
                flex: 1;
                display: block;

                .affiliate-analytics-text {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .loading {
    height: 100%;
    display: grid;
    place-items: center;
  }
`

const DrawerWrapper = styled.div`
  margin: 5px 10px;
  display: flex;
  flex-direction: column;
  gap: 25px;

  .batch {
    border-radius: 5px;
    background-color: #f7f7f7;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 14px;

    .batch-details {
      margin-left: 5px;

      .detail {
        .detail-light {
          opacity: 0.5;
        }
      }

      .payout {
        color: ${props => props.theme.crcoTechBlue};

        .currency {
          font-size: 0.8rem;
        }
      }
    }

    .tags {
      display: flex;
      align-items: center;

      .tag {
        text-transform: uppercase;
        color: grey;
      }

      .date {
        font-size: 0.8rem;
      }
    }
  }

  .total {
    font-size: 1.2rem;

    .payout {
      color: ${props => props.theme.crcoTechBlue};
    }
  }
`

export default AffiliateCreatorCo
