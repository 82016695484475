import {
  ArrowLeftOutlined,
  CheckCircleFilled,
  SettingOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import convertHtmlToReact from '@hedgedoc/html-to-react'
import { Button, Drawer } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'

import { OptInQualifications } from './OptInQualifications'
import affiliate from '../../../../assets/images/icons/campaigns/affiliate.svg'
import bespoke from '../../../../assets/images/icons/campaigns/bespoke.svg'
import giveaway from '../../../../assets/images/icons/campaigns/giveaway.svg'
import shoutout from '../../../../assets/images/icons/campaigns/shoutout.svg'
import ugc from '../../../../assets/images/icons/campaigns/ugc.svg'
import instagram from '../../../../assets/images/icons/social/instagram-color-square.svg'
import tiktok from '../../../../assets/images/icons/social/tiktok-color-square.svg'
import youtube from '../../../../assets/images/icons/social/youtube-color-square.svg'
import { API_URL } from '../../../../constants'
import { UserContext } from '../../../../contexts/UserContext'
import { useMetaData } from '../../../../custom-hooks/useMetaData'
import { ImageCarouselGallery } from '../../../general/ImageCarouselGallery'
import { ImageCarouselSimple } from '../../../general/ImageCarouselSimple'
import LoadingHeart from '../../../general/LoadingHeart'
import { CollaborationGuide } from '../../dashboard/CollaborationGuide'
import { NotFound } from '../../NotFound'
const socialIcons = { instagram, youtube, tiktok }

const SingleCampaign = () => {
  let { slug } = useParams()
  const { goBack, length } = useHistory()
  const { fetchCurrentUser } = useContext(UserContext)
  const [helpVisible, setHelpVisible] = useState(false)
  const [brandWebsite, setBrandWebsite] = useState('')

  const { data: userData, status: userStatus } = useQuery('user', fetchCurrentUser)

  const { data: campaign, status: campaignStatus } = useQuery(['campaign', slug], async () => {
    try {
      const currInviteCode = localStorage?.getItem('inviteCode') //pass inv. code to receive invitation
      const { data } = await axios.get(
        `${API_URL}/public/campaign/${slug}${currInviteCode ? `?inviteCode=${currInviteCode}` : ''}`
      )
      return data
    } catch (err) {
      if (err.response.status === 404) return null
    }
  })

  useMetaData(
    `${campaign?.title ? `${campaign.title} | Collaboration Hub` : 'Collaboration Hub'}`,
    `Collaborate with ${campaign?.brand.name} on creator.co!`,
    campaign?.thumbnailSrc
  )

  useEffect(() => {
    // scroll to top of page on load
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (campaign?.brand?.website) {
      try {
        let website = campaign.brand.website
        const hasProtocol = website.includes('https://') || website.includes('http://')
        const hasWww = website.includes('www.')

        if (!hasProtocol && !hasWww) {
          website = `https://www.${website}`
        } else if (!hasProtocol && hasWww) {
          website = `https://${website}`
        } else if (hasProtocol && !hasWww) {
          website = `https://www.${website.substr(website.indexOf('//') + 2)}`
        }

        const url = new URL(website)
        if (url.protocol !== 'http:' && url.protocol !== 'https:') {
          url.protocol = 'https:'
        }
        setBrandWebsite(url.href)
      } catch (err) {
        return
      }
    }
  }, [campaign])

  return (
    <Wrapper>
      {userStatus === 'success' && campaignStatus === 'success' ? (
        campaign ? (
          <div className='campaign-main'>
            <div className='header'>
              <Button
                type='link'
                onClick={length > 1 ? goBack : () => (window.location.href = '/collaboration-hub')}>
                <ArrowLeftOutlined /> {length > 1 ? 'Back' : 'All Campaigns'}
              </Button>
              <div className='button-group'>
                <Button type='link' onClick={() => setHelpVisible(true)}>
                  <QuestionCircleOutlined />
                </Button>
                {userData?.role === 'administrator' && (
                  <a
                    href={`${
                      process.env.NODE_ENV === 'production'
                        ? 'https://admin.creator.co/campaign/'
                        : 'http://localhost:3003/campaign/'
                    }${campaign.id}`}
                    target='_blank'
                    rel='noopener noreferrer'>
                    <Button type='link'>
                      <SettingOutlined /> Admin
                    </Button>
                  </a>
                )}
              </div>
            </div>
            <div className='campaign-body'>
              <div className='campaign-info'>
                <section className='campaign-header'>
                  <h1>{campaign.title}</h1>
                  <p className='launch-details'>
                    Launched {moment(new Date(campaign.publishDate || campaign.date)).format('ll')}
                    <br />
                    by{' '}
                    <a
                      className='brand'
                      target='_blank'
                      rel='noopener noreferrer'
                      href={brandWebsite}>
                      {campaign.brand.name}
                    </a>
                  </p>
                </section>

                <section className='image-gallery'>
                  <ImageCarouselGallery images={campaign.images} />
                </section>

                <section className='product-description'>
                  <h2>Compensation</h2>
                  {campaign.maxPaidAmount && (
                    <>
                      <div className='paid-campaign'>
                        <CheckCircleFilled /> Paid campaign: $
                        {campaign.minPaidAmount
                          ? `${campaign.minPaidAmount} to ${campaign.maxPaidAmount}`
                          : campaign.maxPaidAmount}{' '}
                        USD
                      </div>
                      <p className='disclaimer'>
                        Please note, a 5% processing fee will be deducted from the final negotiated
                        payment amount for all paid campaigns.
                      </p>
                    </>
                  )}
                  {!!campaign.metaData.affiliateCommission && (
                    <div className='affiliate-commission'>
                      <CheckCircleFilled /> Affiliate Commission:{' '}
                      <span className='value'>{campaign.metaData.affiliateCommission}%</span>
                    </div>
                  )}
                  {campaign.productDescription?.split('\n').map((line, i) => (
                    <div key={i} className='text'>
                      {convertHtmlToReact(line)}
                    </div>
                  ))}
                  <div>
                    Retail Value:{' '}
                    <span className='campaign-value'>
                      {!!campaign?.metaData?.minValue && (
                        <>
                          <span>
                            ${(Math.round(campaign.metaData?.minValue * 100) / 100).toFixed(2)}
                          </span>{' '}
                          -{' '}
                        </>
                      )}
                      $
                      {(
                        Math.round((campaign.value ? campaign.value : campaign.holdCost) * 100) /
                        100
                      ).toFixed(2)}
                    </span>
                  </div>
                  {campaign.variables?.length > 0 && (
                    <div className='variables-info'>
                      <h3>Product options</h3>
                      {campaign.variables.map((variable, i) => (
                        <div key={i} className='variable'>
                          <p className='label'>{variable.title}</p>
                          <ul>
                            {variable.options.map((option, i) => (
                              <li key={i}>
                                <div className='option'>{option.title}</div>
                                {option.description?.split('\n').map((line, i) => (
                                  <p key={i}>{convertHtmlToReact(line)}</p>
                                ))}
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                  )}
                  {!!campaign.productList?.items?.length && (
                    <div className='product-list'>
                      <h3>Product Options</h3>
                      <ul>
                        {campaign.productList.items.map((item, i) => (
                          <li key={i}>
                            <p className='item-title'>{item.title}</p>
                            <p className='item-description'>{item.description}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </section>

                <section className='campaign-description'>
                  <h2>Campaign Details</h2>
                  {campaign.description?.split('\n').map((line, i) => (
                    <div key={i} className='text'>
                      {convertHtmlToReact(line)}
                    </div>
                  ))}
                </section>
                {campaign.brand.description && (
                  <section className='brand-story'>
                    <h2>About the Brand</h2>
                    {campaign.brand.description?.split('\n').map((line, i) => (
                      <div key={i} className='text'>
                        {convertHtmlToReact(line)}
                      </div>
                    ))}
                  </section>
                )}
              </div>

              <div className='campaign-requirements'>
                <OptInQualifications campaign={campaign} userData={userData} />

                <section className='campaign-objectives'>
                  <h2>Deliverables</h2>
                  <div className='objective'>
                    {campaign.socialChannels?.map((channel, i) => (
                      <img className='icon' src={socialIcons[channel]} alt={channel} key={i} />
                    ))}
                    <img
                      className='icon'
                      src={
                        campaign.strategy === 'ugc'
                          ? ugc
                          : campaign.strategy === 'shoutout'
                            ? shoutout
                            : campaign.strategy === 'giveaway'
                              ? giveaway
                              : campaign.strategy === 'affiliate'
                                ? affiliate
                                : bespoke
                      }
                      alt=''
                    />
                    <span className='strategy'>
                      {campaign.strategy === 'ugc'
                        ? 'UGC'
                        : campaign.strategy === 'shoutout'
                          ? 'ShoutOut'
                          : campaign.strategy === 'giveaway'
                            ? 'Giveaway'
                            : campaign.strategy === 'affiliate'
                              ? 'Affiliate'
                              : 'Bespoke'}
                    </span>
                    {!!campaign.contentFormats?.length &&
                      !!campaign.contentFormats[0].length &&
                      campaign.contentFormats.map(
                        format =>
                          !!format.length && (
                            <span key={format} className='format'>
                              {format === 'Video Post' ? 'Video' : format}
                              {campaign.metaData?.contentFormatCounts?.[format?.toLowerCase()] &&
                                ` (${
                                  campaign.metaData?.contentFormatCounts?.[format?.toLowerCase()]
                                })`}
                            </span>
                          )
                      )}
                  </div>
                  {campaign.visuals?.length > 0 && (
                    <div className='objective'>
                      <h3>Visuals & Theme</h3>
                      {campaign.visuals?.split('\n').map((line, i) => (
                        <div key={i} className='text'>
                          {convertHtmlToReact(line)}
                        </div>
                      ))}
                    </div>
                  )}
                  {campaign.caption?.length > 0 && (
                    <div className='objective'>
                      <h3>Caption</h3>
                      {campaign.caption?.split('\n').map((line, i) => (
                        <div key={i} className='text'>
                          {convertHtmlToReact(line)}
                        </div>
                      ))}
                    </div>
                  )}
                  {(campaign.hashtags?.length > 0 || campaign.photoTags?.length > 0) && (
                    <div className='objective'>
                      <h3>Hashtags & Mentions</h3>
                      <p className='tags'>
                        {campaign.hashtags?.map((tag, i) => (
                          <span key={i} className='tag'>
                            #{tag}
                          </span>
                        ))}
                        {campaign.photoTags?.map((tag, i) => (
                          <span key={i} className='tag'>
                            @{tag}
                          </span>
                        ))}
                      </p>
                    </div>
                  )}
                  {campaign.strategy === 'giveaway' && (
                    <div className='objective'>
                      <h3>Giveaway guidelines / Prize description</h3>
                      {(campaign.metaData?.giveawayDescription || campaign.prizeDescription)
                        ?.split('\n')
                        .map((line, i) => (
                          <div key={i} className='text'>
                            {convertHtmlToReact(line)}
                          </div>
                        ))}
                    </div>
                  )}
                </section>

                {campaign.metaData?.inspirationImages?.length > 0 && (
                  <section className='inspiration-gallery'>
                    <h2>Inspiration Board</h2>
                    <p>Use these images to help guide your content&apos;s artistic direction.</p>
                    <ImageCarouselSimple images={campaign.metaData.inspirationImages} />
                  </section>
                )}
              </div>
            </div>
          </div>
        ) : (
          <NotFound
            message={'Campaign not found.'}
            buttonText={'All Campaigns'}
            to={'/collaboration-hub'}
          />
        )
      ) : (
        <div className='loading'>
          <LoadingHeart />
        </div>
      )}

      <Drawer
        title='Collaboration Guide'
        open={helpVisible}
        onClose={() => setHelpVisible(false)}
        width={window.innerWidth > 600 ? 500 : '100vw'}>
        <CollaborationGuide />
      </Drawer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  // MOBILE
  .campaign-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 10px 10px 70px 10px;
    color: #777e90;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .button-group {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }
  h1,
  h2,
  h3 {
    font-family: 'Poppins-Bold', sans-serif;
    color: #23262f;
  }
  h1 {
    font-size: 1.2rem;
    margin: 0;
  }
  h2 {
    font-size: 1.1rem;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 20px;
  }
  .campaign-body {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  section {
    background: #fff;
    padding: 30px 20px;
    border-radius: 10px;
    .disclaimer {
      padding: 5px;
      background: #eee;
      border-radius: 3px;
      font-size: 0.8rem;
      border: 1px solid #e6e6e6;
      color: #666;
    }
  }
  .expand-variables {
    color: ${props => props.theme.crcoTechBlue};
    cursor: pointer;
    transition: 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    &.expand {
      .arrow {
        transform: rotate(180deg);
      }
    }
  }
  .variables-selection {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    .variable {
      opacity: 0;
      visibility: hidden;
      display: flex;
      flex-direction: column;
      transition: 0.3s ease-in-out;
      max-height: 0;
      .label {
        margin: 0;
      }
    }
    &.expand {
      .variable {
        opacity: 1;
        visibility: visible;
        padding: 10px 0;
        max-height: 500px;
      }
    }
  }
  .text {
    font-family: 'Campton-Medium', Verdana, sans-serif;
    word-break: break-word;
    margin-bottom: 10px;
  }
  .campaign-info,
  .campaign-requirements {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .campaign-info {
    .launch-details {
      font-size: 14px;
      margin: 10px 0 0 0;
    }
    .product-description {
      .paid-campaign,
      .affiliate-commission {
        background: ${props => props.theme.crcoLettuce};
        color: #fff;
        width: fit-content;
        font-size: 0.8rem;
        padding: 3px 5px 0 5px;
        border-radius: 20px;
        margin-bottom: 10px;
        .anticon {
          border: 1px solid white;
          border-radius: 50%;
          padding: 1px;
        }
      }
    }
    .campaign-value {
      color: ${props => props.theme.crcoTechBlue};
    }
    .variables-info {
      margin-top: 40px;
      .label {
        margin: 10px 0 5px 0;
        color: ${props => props.theme.crcoTechBlue};
      }
      ul {
        font-family: 'Campton-Medium', Verdana, sans-serif;
        font-size: 0.8rem;
        padding: 0 0 0 10px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        .option {
          font-family: 'Campton-Medium', Verdana, sans-serif;
        }
        p {
          margin: 5px 0 0 0;
        }
        a {
          font-family: 'Campton-Medium', Verdana, sans-serif;
          text-decoration: underline;
        }
      }
    }
    .product-list {
      margin-top: 40px;
      p {
        margin: 0;
      }
      ul {
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-height: 300px;
        overflow-y: auto;
        ${props => props.theme.scrollbar}
      }
      .item-title {
        font-weight: bold;
      }
      .item-description {
        font-size: 0.8rem;
        color: ${props => props.theme.crcoGrey};
      }
    }
  }
  .campaign-objectives {
    .icon {
      height: 20px;
      margin-right: 5px;
    }
    .objective {
      margin-bottom: 20px;
      .strategy {
        color: #027df0;
        margin: 0 5px;
      }
      .format {
        background: #ecf6ff;
        color: #027df0;
        padding: 3px 5px 2px 5px;
        margin-right: 5px;
        border-radius: 3px;
        font-size: 0.8rem;
      }
      .tags {
        display: flex;
        flex-wrap: wrap;
        .tag {
          color: ${props => props.theme.crcoTechBlue};
          margin-right: 10px;
        }
      }
    }
  }
  .loading {
    flex: 1;
    display: flex;
    align-items: center;
  }

  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    .campaign-main {
      max-width: 600px;
      margin: auto;
      padding: 20px 20px 80px 20px;
    }
    h1 {
      font-size: 1.4rem;
    }
    h2 {
      font-size: 1.2rem;
    }
    .campaign-body {
      gap: 20px;
    }
    .campaign-info,
    .campaign-requirements {
      gap: 20px;
    }
  }

  @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
    .campaign-main {
      max-width: 1200px;
      padding: 30px;
    }
    h1 {
      font-size: 1.5rem;
    }
    h2 {
      font-size: 1.3rem;
    }
    .header {
      margin-bottom: 10px;
    }
    .campaign-body {
      flex-direction: row;
    }
    section {
      border-radius: 20px;
      padding: 40px;
    }
    .campaign-info {
      flex: 1;
    }
    .campaign-requirements {
      flex: 1;
    }
    .expand-variables {
      display: none;
    }
    .variables-selection {
      padding-bottom: 10px;
      .variable {
        opacity: 1;
        visibility: visible;
        padding: 10px 0;
        max-height: 500px;
      }
    }
  }
`

export default SingleCampaign
