import { CheckCircleOutlined, CloseCircleOutlined, UserOutlined } from '@ant-design/icons'
import { Modal, Button, Select, Alert, DatePicker, message } from 'antd'
import axios from 'axios'
import { Formik } from 'formik'
import { Form, Input } from 'formik-antd'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import * as yup from 'yup'

import instagram from '../../../../assets/images/icons/social/instagram-color-square.svg'
import tiktok from '../../../../assets/images/icons/social/tiktok-color-square.svg'
import youtube from '../../../../assets/images/icons/social/youtube-color-square.svg'
import { API_URL } from '../../../../constants'
import { GlobalContext } from '../../../../contexts/GlobalContext'
import { UserContext } from '../../../../contexts/UserContext'
import { findProfile } from '../../../../utils'
import { FormItem } from '../../../general/forms/FormItem'
import PhoneInput from '../../../general/forms/PhoneInput'
import { SocialConnect } from '../../dashboard/social-accounts/SocialConnect'

const socialIcons = { instagram, youtube, tiktok }
const { Option } = Select

export const OptInModal = ({
  open,
  optInLoading,
  handleOptIn,
  handleCancel,
  userData,
  campaign,
  selectedVariables,
  setSelectedVariables,
  selectedProduct,
  setSelectedProduct,
  selectedChannels,
  setSelectedChannels,
  socialConnects,
  notEligibleData,
}) => {
  const lastLocation = useLocation()
  const { updateCreatorProfile } = useContext(UserContext)
  const { getCategories } = useContext(GlobalContext)

  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])

  const { data: productListItems, status: productListItemsStatus } = useQuery(
    ['product-list-items', campaign.productListId],
    () =>
      campaign.productListId
        ? axios
            .get(`${API_URL}/brand/${campaign.brandId}/product-list/${campaign.productListId}/item`)
            .then(r => r.data.items)
        : []
  )

  const { data: categories } = useQuery('categories', getCategories)

  const modalTitle = () => {
    switch (open) {
      case 'login':
        return 'Sign In'
      case 'variables':
        return 'Select Product'
      case 'product':
        return 'Select Product'
      case 'channels':
        return 'Select Channels'
      case 'social-connect':
        return 'Connect Socials'
      case 'phone':
        return 'Verify Phone'
      case 'profile':
        return 'Complete Profile'
      case 'not-eligible':
        return 'Not Eligible'
      default:
        return ''
    }
  }

  const initialValues = {
    // profile info form
    shippingAddress1: userData?.creatorProfile?.shippingAddress1 || '',
    shippingAddress2: userData?.creatorProfile?.shippingAddress2 || '',
    shippingCity: userData?.creatorProfile?.shippingCity || '',
    shippingRegion: userData?.creatorProfile?.shippingRegion || '',
    shippingCountry: userData?.creatorProfile?.shippingCountry || '',
    shippingPostcode: userData?.creatorProfile?.shippingPostcode || '',
    birthDate: userData?.creatorProfile?.birthDate || '',
    gender: userData?.creatorProfile?.gender || '',
    niches: userData?.creatorProfile?.niches?.map(({ category }) => category.id),
  }

  const validationSchema = yup.object().shape({
    // using empty array and anonymous functions to apply conditional validation with when()
    shippingAddress1: yup.string().when([], {
      is: () => campaign.requiresShipping,
      then: yup.string().min(2, 'Too short').required('Required'),
    }),
    shippingAddress2: yup.string(), // optional
    shippingCity: yup.string().when([], {
      is: () => campaign.requiresShipping,
      then: yup.string().min(2, 'Too short').required('Required'),
    }),
    shippingRegion: yup.string().when([], {
      is: () => campaign.requiresShipping,
      then: yup.string().required('Required'),
    }),
    shippingCountry: yup.string().when([], {
      is: () => campaign.countries?.length > 0 || campaign.requiresShipping,
      then: yup.string().required('Required'),
    }),
    shippingPostcode: yup.string().when([], {
      is: () => campaign.requiresShipping,
      then: yup.string().required('Required'),
    }),
    birthDate: yup.string().required('Required'),
    gender: yup.string().required('Required'),
    niches: yup.array().when([], {
      is: () => campaign.categories?.length > 0,
      then: yup
        .array()
        .required('Required')
        .min(1, 'Select at least 1')
        .max(4, 'Select a maximum of 4'),
    }),
  })

  const handleSubmit = async (data, { setStatus }) => {
    setStatus('')
    const result = await updateCreatorProfile(data)
    if (result.error) {
      setStatus(result.error)
    } else {
      message.success('Profile Updated')
      handleOptIn()
    }
  }

  // getting countries, states, and categories
  useEffect(() => {
    axios
      .get('../json/countries.json')
      .then(res => {
        setCountries(res.data.countries)
      })
      .catch(() => {}) // TODO: handle error

    axios.get('../json/states.json').then(res => {
      setStates(res.data.states)
    })
  }, [])

  return (
    <Modal title={modalTitle()} open={open} onCancel={handleCancel} footer={null}>
      <Wrapper>
        {open === 'login' && (
          <>
            <Alert
              message='Please sign in to proceed.'
              type='info'
              showIcon
              data-cy='login-alert'
            />
            <div className='buttons'>
              <Link to={`/signup?redirect=${lastLocation.pathname}`} className='cta'>
                <Button type='secondary'>Create Account</Button>
              </Link>
              <Link to={`/login?redirect=${lastLocation.pathname}`} className='cta'>
                <Button type='primary'>
                  <UserOutlined />
                  Sign In
                </Button>
              </Link>
            </div>
          </>
        )}

        {open === 'variables' && (
          <>
            <Alert message='Select your product preference(s).' type='info' showIcon />
            {campaign.variables.map((variable, i) => (
              <div className='selection' key={i}>
                <label>{variable.title}</label>
                <Select
                  getPopupContainer={trigger => trigger.parentNode}
                  virtual={false}
                  key={variable.id}
                  placeholder='Select an option'
                  onChange={e =>
                    setSelectedVariables(prev => ({
                      ...prev,
                      [`${variable.id}`]: e,
                    }))
                  }>
                  {variable.options.map(option => (
                    <Option key={option.id} value={option.id}>
                      {option.title}
                    </Option>
                  ))}
                </Select>
              </div>
            ))}
            <div className='buttons'>
              <Button type='secondary' onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                type='primary'
                onClick={handleOptIn}
                loading={optInLoading}
                disabled={Object.keys(selectedVariables).length !== campaign.variables.length}>
                Continue
              </Button>
            </div>
          </>
        )}

        {open === 'product' && (
          <>
            <Alert message='Select your preferred product' type='info' showIcon />
            <Select
              getPopupContainer={trigger => trigger.parentNode}
              virtual={false}
              placeholder='Select'
              showArrow
              allowClear
              loading={productListItemsStatus === 'loading'}
              onChange={e => setSelectedProduct(e)}>
              {(productListItems || [])
                .filter(a => !a.deactivated)
                .map(item => (
                  <Option key={item.id} value={item.id}>
                    {item.title}
                  </Option>
                ))}
            </Select>

            <div className='buttons'>
              <Button type='secondary' onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                type='primary'
                onClick={handleOptIn}
                loading={optInLoading}
                disabled={!selectedProduct}>
                Continue
              </Button>
            </div>
          </>
        )}

        {open === 'channels' && (
          <>
            <Alert message='Select at least one channel.' type='info' showIcon />
            <div className='selection'>
              <div className='label'>Social Channel</div>
              <Select
                getPopupContainer={trigger => trigger.parentNode}
                virtual={false}
                mode='multiple'
                placeholder='Select'
                showArrow
                allowClear
                onChange={e => setSelectedChannels(e)}>
                {campaign.socialChannels.map(channel => (
                  <Option key={channel} value={channel}>
                    <img src={socialIcons[channel]} alt='' className='icon' />
                    {channel === 'instagram'
                      ? 'Instagram'
                      : channel === 'tiktok'
                        ? 'TikTok'
                        : 'YouTube'}
                  </Option>
                ))}
              </Select>
            </div>
            <div className='buttons'>
              <Button type='secondary' onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                type='primary'
                onClick={handleOptIn}
                loading={optInLoading}
                disabled={!selectedChannels.length}>
                Continue
              </Button>
            </div>
          </>
        )}

        {open === 'social-connect' && (
          <>
            {socialConnects.filter(platform => findProfile(userData?.socialProfiles, platform))
              .length ? (
              <Alert message='Socials connected.' type='success' showIcon />
            ) : (
              <Alert message='Please connect your socials.' type='info' showIcon />
            )}
            <div className='selection'>
              {socialConnects.map((platform, i) => {
                const socialProfile = findProfile(userData?.socialProfiles, platform)
                return socialProfile ? (
                  <div key={i} className='connected-platform'>
                    <div className='channel-info'>
                      <CheckCircleOutlined /> <p className='handle'>{socialProfile.username}</p>
                    </div>
                    <img className='icon' src={socialIcons[platform]} alt={platform} />
                  </div>
                ) : (
                  <SocialConnect platform={platform} key={i} />
                )
              })}
            </div>
            <div className='buttons'>
              <Button type='secondary' onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                type='primary'
                onClick={handleOptIn}
                loading={optInLoading}
                disabled={
                  // prevent user from proceeding if they have not connected all required socials
                  socialConnects.filter(
                    platform => !findProfile(userData?.socialProfiles, platform)
                  ).length
                }>
                Continue
              </Button>
            </div>
          </>
        )}

        {open === 'phone' && (
          <Formik
            initialValues={{
              twilioVerified: userData?.extraData?.twilioVerified,
            }}
            validationSchema={yup.object().shape({
              twilioVerified: yup
                .boolean()
                .required('Please verify your phone number')
                .oneOf([true], 'Please verify your phone number'),
            })}>
            {({ values, setFieldValue }) => (
              <Form>
                {values.twilioVerified ? (
                  <Alert message='Phone number verified' type='success' showIcon />
                ) : (
                  <Alert message='Please verify your phone number.' type='info' showIcon />
                )}
                <div className='inputs'>
                  <FormItem
                    name='twilioVerified'
                    label='Phone Number'
                    info='We require a verified phone number for SMS notifications.'>
                    <PhoneInput
                      onSuccess={() => {
                        setFieldValue('twilioVerified', true)
                      }}
                    />
                  </FormItem>
                </div>
                <div className='buttons'>
                  <Button type='secondary' onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button
                    type='primary'
                    onClick={handleOptIn}
                    loading={optInLoading}
                    disabled={!values.twilioVerified}>
                    Continue
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        )}

        {open === 'profile' && (
          <>
            <Alert message='Please complete your profile.' type='info' showIcon />
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}>
              {({ values, setValues, setFieldValue, handleSubmit, isSubmitting }) => (
                <Form>
                  <div className='inputs'>
                    {campaign.requiresShipping ? (
                      <>
                        <FormItem
                          name='shippingAddress1'
                          label='Street Address'
                          subtext='Your shipping address is required to receive products for this collaboration.'>
                          <Input name='shippingAddress1' />
                        </FormItem>

                        <FormItem
                          name='shippingAddress2'
                          label='Apt / Suite / Unit'
                          sublabel='(optional)'>
                          <Input name='shippingAddress2' />
                        </FormItem>

                        <FormItem label='Country' name='shippingCountry'>
                          <Select
                            getPopupContainer={trigger => trigger.parentNode}
                            virtual={false}
                            name='shippingCountry'
                            showSearch
                            autoComplete='none'
                            style={{ width: '100%' }}
                            listHeight={256}
                            defaultValue={values.shippingCountry}
                            optionFilterProp='label'
                            onChange={e => {
                              setValues({ ...values, shippingCountry: e })
                              setFieldValue('shippingRegion', '', true)
                            }}>
                            {countries.map(country => (
                              <Option
                                key={country.id}
                                value={country.country_code}
                                label={country.name}>
                                {country.name}
                              </Option>
                            ))}
                          </Select>
                        </FormItem>

                        <FormItem label='State / Province' name='shippingRegion'>
                          <Select
                            name='shippingRegion'
                            showSearch
                            autoComplete='none'
                            style={{ width: '100%' }}
                            listHeight={256}
                            optionFilterProp='label'
                            defaultValue={values.shippingRegion}
                            onChange={e => {
                              setValues({
                                ...values,
                                shippingRegion: e,
                              })
                            }}>
                            {states
                              .filter(state => state.country_code === values.shippingCountry)
                              .map(state => (
                                <Option key={state.id} value={state.name} label={state.name}>
                                  {state.name}
                                </Option>
                              ))}
                          </Select>
                        </FormItem>

                        <FormItem name='shippingCity' label='City'>
                          <Input name='shippingCity' />
                        </FormItem>

                        <FormItem name='shippingPostcode' label='Postcode'>
                          <Input name='shippingPostcode' />
                        </FormItem>
                      </>
                    ) : (
                      campaign.countries?.length > 0 &&
                      !userData.creatorProfile?.shippingCountry && (
                        <FormItem label='Country' name='shippingCountry'>
                          <Select
                            getPopupContainer={trigger => trigger.parentNode}
                            virtual={false}
                            name='shippingCountry'
                            showSearch
                            autoComplete='none'
                            style={{ width: '100%' }}
                            listHeight={256}
                            defaultValue={values.shippingCountry}
                            optionFilterProp='label'
                            onChange={e => {
                              setValues({ ...values, shippingCountry: e })
                              setFieldValue('shippingRegion', '', true)
                            }}>
                            {countries.map(country => (
                              <Option
                                key={country.id}
                                value={country.country_code}
                                label={country.name}>
                                {country.name}
                              </Option>
                            ))}
                          </Select>
                        </FormItem>
                      )
                    )}

                    {!userData?.creatorProfile?.birthDate && (
                      <FormItem label='Date of birth' name='birthDate'>
                        <DatePicker
                          getPopupContainer={trigger => trigger.parentNode}
                          format='MMMM D, Y'
                          name='birthDate'
                          allowClear={false}
                          style={{ width: '100%' }}
                          disabledDate={current => current.isAfter(moment())}
                          defaultValue={values.birthDate ? moment(values.birthDate) : undefined}
                          onChange={e => {
                            setValues({ ...values, birthDate: e?.toISOString() })
                          }}
                        />
                      </FormItem>
                    )}

                    {!userData.creatorProfile?.gender && (
                      <FormItem label='Gender' name='gender'>
                        <Select
                          getPopupContainer={trigger => trigger.parentNode}
                          virtual={false}
                          name='gender'
                          showArrow
                          allowClear
                          optionFilterProp='label'
                          style={{ width: '100%' }}
                          defaultValue={userData.creatorProfile?.gender}
                          onChange={e => {
                            setValues(prev => ({
                              ...prev,
                              gender: e,
                            }))
                          }}>
                          <Option value='female'>Female</Option>
                          <Option value='male'>Male</Option>
                          <Option value='non-binary'>Non-binary</Option>
                        </Select>
                      </FormItem>
                    )}

                    {campaign.categories?.length > 0 &&
                      !userData.creatorProfile?.niches?.length && (
                        <FormItem label='Niches' sublabel='(areas of influence)' name='niches'>
                          <Select
                            getPopupContainer={trigger => trigger.parentNode}
                            virtual={false}
                            name='niches'
                            showArrow
                            placeholder='Select'
                            optionFilterProp='label'
                            filterOption
                            mode='multiple'
                            style={{ width: '100%' }}
                            loading={!categories?.length}
                            defaultValue={userData.creatorProfile?.niches?.map(
                              ({ category }) => category.id
                            )}
                            onChange={e => {
                              setValues(prev => ({ ...prev, niches: e }))
                            }}>
                            {categories?.map(category => (
                              <Option label={category.title} key={category.id} value={category.id}>
                                {category.title}
                              </Option>
                            ))}
                          </Select>
                        </FormItem>
                      )}
                  </div>

                  <div className='buttons'>
                    <Button type='secondary' onClick={handleCancel}>
                      Cancel
                    </Button>
                    <Button
                      type='primary'
                      onClick={handleSubmit}
                      loading={isSubmitting || optInLoading}>
                      Continue
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        )}

        {open === 'not-eligible' && (
          <>
            <div className='not-eligible'>
              <Alert
                message='Requirements not met'
                type='warning'
                showIcon
                style={{ width: '100%' }}
                data-cy='not-eligible-alert'
              />
              {notEligibleData?.message?.map((error, i) => (
                <div key={i}>
                  <div className='title'>{error.title}</div>
                  <div className='message'>
                    <CloseCircleOutlined /> {error.message}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div`
  width: 100%;
  max-width: 320px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .selection {
    display: flex;
    flex-direction: column;

    .label {
      font-weight: bold;
      margin-bottom: 4px;
    }

    .icon {
      height: 20px;
      margin-right: 5px;
    }
  }

  form {
    display: flex;
    flex-direction: column;

    .inputs {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
    }
  }

  .connected-platform {
    background: #ecf6ff;
    border: 1px solid ${props => props.theme.crcoTechBlue};
    border-radius: 20px;
    color: ${props => props.theme.crcoTechBlue};
    padding: 4px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    margin: 5px 0;
    width: 100%;
    min-height: 40px;

    .channel-info {
      display: flex;
      align-items: center;
      gap: 5px;
      overflow: hidden;

      p {
        margin: 0;
      }

      .handle {
        font-size: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .status {
        font-size: 0.8rem;
      }
    }

    .icon {
      height: 25px;
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-top: 20px;
  }

  .not-eligible {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .title {
      color: #333;
    }

    .message {
      color: #999;
      font-size: 0.8rem;
    }
  }
`
