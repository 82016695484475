import { useContext } from 'react'
import { useQuery } from 'react-query'

import { CreatorProfileEditor } from './CreatorProfileEditor'
import { CreatorProfileIntro } from './CreatorProfileIntro'
import { UserContext } from '../../../../contexts/UserContext'
import { useMetaData } from '../../../../custom-hooks/useMetaData'

export const CreatorProfileSettings = () => {
  useMetaData(
    'My Profile | Creator Dashboard',
    'Manage your official Creator Profile.',
    'https://creatorco.nyc3.cdn.digitaloceanspaces.com/assets/preview-images/meta-preview.png'
  )

  const { fetchCurrentUser } = useContext(UserContext)
  const { data: userData } = useQuery('user', fetchCurrentUser)

  return userData?.hasSubscription && userData?.creatorProfile?.profileSlug ? (
    <CreatorProfileEditor userData={userData} />
  ) : (
    <CreatorProfileIntro userData={userData} />
  )
}
