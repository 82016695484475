import axios from 'axios'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { API_URL } from '../../constants'

const AffiliateRedirect = () => {
  const { id } = useParams()

  useEffect(() => {
    axios
      .get(`${API_URL}/opt-in/affiliate-redirect/${id}`)
      .then(res => (window.location.href = res.data))
  }, [id])

  return <></>
}

export default AffiliateRedirect
