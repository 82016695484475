import React, { useContext } from 'react'
import styled from 'styled-components/macro'

import { OptInContext } from '../../../../../contexts/OptInContext'

export const BRAND_SUBMIT_GIVEAWAY_SHIPMENT_TRACKING_LINK = ({ campaignStep }) => {
  const { optIn } = useContext(OptInContext)
  const stepReached = campaignStep.placement <= optIn.currentStep?.placement
  return (
    <Wrapper className='programmed-step'>
      {campaignStep.optInStep?.completed ? (
        <div>
          <p>The brand has submitted the giveaway shipment tracking link.</p>
          <a target='_blank' rel='noopener noreferrer' href={campaignStep.optInStep?.data?.url}>
            {campaignStep.optInStep?.data?.url}
          </a>
        </div>
      ) : (
        stepReached && <p>The brand needs to submit the giveaway shipment tracking link.</p>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div``
