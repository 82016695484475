import { Spin } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components/macro'

const VideoItem = ({ url, autoPlay }) => {
  const [loaded, setLoaded] = useState(false)

  return (
    <>
      {!loaded && <Spin />}
      <Video
        preload='auto'
        onLoadedData={() => setLoaded(true)}
        controls={false}
        muted={true}
        autoPlay={!!autoPlay}
        loop={!!autoPlay}
        isVisible={loaded}>
        <source src={url} type='video/mp4' />
      </Video>
    </>
  )
}

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  // prevent video from showing before it's loaded
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`
export default VideoItem
