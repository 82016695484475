import { Button, message } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import exampleProfile from '../../../../assets/images/example-profile1.png'
import customize from '../../../../assets/images/icons/customize.svg'
import earn from '../../../../assets/images/icons/earn.svg'
import showcase from '../../../../assets/images/icons/portfolio.svg'
import premium from '../../../../assets/images/icons/premium.svg'
import { API_URL } from '../../../../constants'
import { ImageLoader } from '../../../general/ImageLoader'

export const CreatorProfileIntro = ({ userData }) => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const planId = 'creator-profile-early-access' // temporary early access plan (discounted)

  useEffect(() => {
    // check if user is subscribed but hasn't complete setup flow (no handle) - if so, redirect to setup flow
    if (userData.hasSubscription && !userData.creatorProfile?.profileSlug) {
      history.push('/creator-profile-setup')
    }
  }, [history, userData])

  const openPayment = () => {
    setLoading(true)
    let chargebeeInstance = window.Chargebee.getInstance()
    chargebeeInstance.closeAll()

    // open the chargebee checkout modal
    axios
      .get(`${API_URL}/creator-subscription/${userData.id}/?planId=${planId}`)
      .then(res => {
        setLoading(false)
        chargebeeInstance.openCheckout({
          hostedPage: () => {
            return new Promise(function (resolve) {
              resolve(res.data)
            })
          },
          error: () => {
            message.error('Something went wrong. Please try again later.')
          },
          success: hostedPageId => {
            // confirm subscription in backend
            axios
              .post(`${API_URL}/creator-subscription`, { hostedPageId })
              .then(() => {
                chargebeeInstance.closeAll()
                // redirect to creator profile setup (social connect, claim handle)
                history.push('/creator-profile-setup')
              })
              .catch(() => {
                message.error('Something went wrong. Please try again later.')
              })
          },
          close: () => {},
        })
      })
      .catch(() => {
        message.error('Something went wrong. Please try again later.')
      })
  }

  return (
    <Wrapper>
      <section className='hero-banner'>
        <div className='text'>
          <h1>
            The Ultimate
            <br />
            Link in Bio
          </h1>
          <p>
            Introducing our new Creator Profile, designed specifically for social media influencers
            like you. For a limited time, subscribe to get your profile for only{' '}
            <strong>$1.99 (USD) per month</strong> - forever.
          </p>
          <div className='url-container'>
            <div className='url'>
              <span className='prefix'>https://</span>
              <span className='handle'>yourhandle</span>
              <span className='suffix'>.creator.co</span>
            </div>
          </div>
          <div className='buttons'>
            <Button type='primary' onClick={openPayment} loading={loading}>
              Subscribe Now
            </Button>
            <a
              href={
                process.env.NODE_ENV === 'development'
                  ? 'http://example.localhost:3002'
                  : 'https://example.creator.co'
              }
              className='preview-link'
              target='_blank'
              rel='noopener noreferrer'>
              <Button type='secondary'>View Example</Button>
            </a>
          </div>
        </div>
        <div className='image'>
          <ImageLoader src={exampleProfile} alt='' />
        </div>
      </section>
      <div className='feature-cards'>
        <div className='feature-card'>
          <div className='icon-wrapper pink'>
            <img src={customize} alt='' />
          </div>
          <h3>Customize your profile</h3>
          <p>
            Make it your own with a custom URL, theme, and engaging bio. Introduce yourself to
            brands in a way that&apos;s unique to you.
          </p>
        </div>
        <div className='feature-card'>
          <div className='icon-wrapper blue'>
            <img src={showcase} alt='' />
          </div>
          <h3>Showcase your content</h3>
          <p>
            Connect your channels and display your latest content in a beautiful gallery. Showcase
            your best work and upload your rates.
          </p>
        </div>
        <div className='feature-card'>
          <div className='icon-wrapper green'>
            <img src={earn} alt='' />
          </div>
          <h3>Earn more commissions</h3>
          <p>
            Share your favorite recommended products all in one place to easily make more sales as
            an affiliate. No more links in your bio!
          </p>
        </div>
      </div>
      <section className='early-access'>
        <div className='tag-wrapper'>
          <img src={premium} alt='' /> <span className='tag'>Limited Time Offer</span>
        </div>
        <h2>Subscribe now for your early-bird discount</h2>
        <p>
          For a limited time, we&apos;re offering an early access discount! As a subscriber,
          you&apos;ll receive all feature upgrades at <strong>no extra cost</strong>. You will also
          influence the new features we build and roll out.
        </p>
        <p>
          Cancel at any time, but you won&apos;t be able to get this price again. We&apos;re also
          launching a free version with limited functionality, so you can always downgrade and still
          keep your handle.
        </p>
        <div className='price'>
          <span className='amount'>$1.99</span>
          <span className='suffix'>/ month (USD) - forever!</span>
        </div>
        <Button type='primary' onClick={openPayment} loading={loading}>
          Claim Discount
        </Button>
      </section>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  section {
    padding: 40px 20px;
  }
  .hero-banner {
    display: flex;
    flex-direction: column;
    gap: 40px;
    background: #d6e7f7;
    border-radius: 24px;
    .image {
      flex: 1;
      border-radius: 20px;
      overflow: hidden;
    }
  }
  h1 {
    font-family: 'Gilroy-ExtraBold', sans-serif;
    font-size: 2.2rem;
    line-height: 1.2;
    color: #23262f;
  }
  p {
    color: #777e90;
  }
  .url-container {
    background: #e8f4ff;
    padding: 5px 10px;
    border: 1px solid #d6e7f7;
    border-radius: 20px;
    margin: 30px 0;
    .prefix,
    .suffix {
      opacity: 0.6;
    }
    .handle {
      color: ${props => props.theme.crcoTechBlue};
    }
  }
  .buttons {
    display: flex;
    gap: 20px;
  }
  .feature-cards {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .feature-card {
    background: #fff;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 4px 8px 20px 4px rgba(154, 175, 193, 0.12);
    .icon-wrapper {
      border-radius: 50%;
      padding: 5px;
      position: relative;
      height: 50px;
      width: 50px;
      margin: 10px 0 20px 10px;
      &.pink {
        background: #feeae7;
      }
      &.blue {
        background: #ecf5fd;
      }
      &.green {
        background: #ddf8e9;
      }
      img {
        position: absolute;
        top: -10px;
        left: -10px;
      }
    }
  }
  .early-access {
    background: #fff;
    border-radius: 24px;
    .tag-wrapper {
      display: flex;
      align-items: center;
      gap: 8px;
      img {
        height: 25px;
        width: 25px;
      }
    }
    .tag {
      background: #f1edf8;
      color: #9d9de8;
      width: fit-content;
      border-radius: 15px;
      font-size: 0.8rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      padding: 5px 20px;
    }
    h2 {
      color: #23262f;
      line-height: 1.2;
      margin: 20px 0;
    }
    p {
      max-width: 500px;
    }
    ul {
      list-style: none;
      padding: 0;
      font-size: 0.9rem;
      li {
        display: flex;
        gap: 8px;
        padding: 10px 0;
      }
    }
    .price {
      display: flex;
      align-items: center;
      color: #23262f;
      margin-bottom: 20px;
      .amount {
        font-size: 2rem;
        font-weight: 600;
        color: #9d9de8;
      }
      .suffix {
        opacity: 0.7;
      }
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    gap: 30px;
    section {
      padding: 60px 40px;
    }
    h1 {
      font-size: 2.5rem;
    }
    h2 {
      font-size: 2rem;
    }
    .feature-cards {
      flex-direction: row;
      flex-wrap: wrap;
    }
    .feature-card {
      min-width: 250px;
      padding: 40px;
      flex: 1;
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
    max-width: 1200px;
    margin: auto;
    gap: 40px;
    padding: 40px;
    .hero-banner {
      flex-direction: row;
      align-items: center;
      gap: 60px;
      .text {
        flex: 1;
      }
    }
    h1 {
      font-size: 3rem;
    }
    h2 {
      font-size: 2.2rem;
    }
  }
`
