import { RightOutlined, SettingOutlined } from '@ant-design/icons'
import { Button, Empty } from 'antd'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useInfiniteQuery } from 'react-query'
import { Link } from 'react-router-dom'

import { API_URL } from '../../../constants'
import { findProfile } from '../../../utils'
import { CampaignCard } from '../campaignHub/CampaignCard'
import { GhostCampaignCard } from '../campaignHub/GhostCampaignCard'

export const FeaturedCampaigns = ({ userData }) => {
  const [featuredCampaigns, setFeaturedCampaigns] = useState([])
  const [countries, setCountries] = useState([])

  const calculateAge = dob => {
    if (!dob) {
      return null
    } else {
      const today = new Date()
      const birthDate = new Date(dob)
      let age = today.getFullYear() - birthDate.getFullYear()
      const m = today.getMonth() - birthDate.getMonth()
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age = age - 1
      }
      return age
    }
  }

  const instagramProfile = findProfile(userData?.socialProfiles, 'instagram')
  const tiktokProfile = findProfile(userData?.socialProfiles, 'tiktok')
  const youtubeProfile = findProfile(userData?.socialProfiles, 'youtube')
  const userAge = calculateAge(userData?.creatorProfile?.birthDate)
  const userCountry = userData?.creatorProfile?.shippingCountry
  const countryName = countries.find(country => country.country_code === userCountry)?.name
  const userNiches = userData?.creatorProfile?.niches?.map(item => item.category.slug)

  useEffect(() => {
    axios
      .get('../json/countries.json')
      .then(res => {
        setCountries(res.data.countries)
      })
      .catch(() => {}) // TODO: handle error
  }, [])

  const fetchCampaigns = async ({ pageParam }) => {
    const { data } = await axios.post(
      `${API_URL}/public/collab-hub/campaigns`,
      {
        instagramReach: instagramProfile?.followerCount,
        youtubeReach: youtubeProfile?.followerCount,
        tiktokReach: tiktokProfile?.followerCount,
        instagramEngagement: instagramProfile?.engagement,
        youtubeEngagement: youtubeProfile?.engagement,
        tiktokEngagement: tiktokProfile?.engagement,
        age: userAge,
        country: countryName,
        categories: userNiches,
      },
      {
        params: {
          sort: 'date-desc',
          page: pageParam,
        },
      }
    )

    return data
  }

  const { data, status } = useInfiniteQuery('featured-campaigns', fetchCampaigns, {
    getNextPageParam: lastPage => lastPage.nextCursor,
  })

  useEffect(() => {
    if (data) {
      const pageArrays = data.pages.map(page => page.campaigns)
      const campaignsArray = Array.prototype.concat.apply([], pageArrays)
      setFeaturedCampaigns(
        campaignsArray.map(item => (
          <CampaignCard key={item.id} campaign={item} userData={userData} />
        ))
      )
    }
  }, [data, userData])

  return (
    <section>
      <h2>Featured Collaborations</h2>
      {!!featuredCampaigns?.length && <p>These campaigns may a great fit for you!</p>}

      <div className='campaigns'>
        {status === 'success' ? (
          featuredCampaigns?.length > 0 ? (
            featuredCampaigns
          ) : (
            <div className='no-results'>
              <Empty description='Sorry, no campaigns match your profile right now.' />
              <Link to='/my-settings'>
                <SettingOutlined /> Update Settings
              </Link>
            </div>
          )
        ) : (
          Array.from(Array(6).keys()).map(item => <GhostCampaignCard key={item} />)
        )}
      </div>

      <Link to='/collaboration-hub' className='cta'>
        <Button type='link'>
          See All <RightOutlined />
        </Button>
      </Link>
    </section>
  )
}
