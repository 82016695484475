import React, { useContext } from 'react'
import styled from 'styled-components/macro'

import { OptInContext } from '../../../../../contexts/OptInContext'

export const BRAND_SUBMIT_WEB_REDEMPTION_CODE_2 = ({ step, optInStep }) => {
  const { optIn } = useContext(OptInContext)
  const isCurrentStep = step.placement === optIn.currentStep?.placement

  return (
    <Wrapper className='programmed-step'>
      {optInStep?.completed ? (
        // TODO: display code 2 (what is the difference between this and the other codes?)
        <p>The brand has submitted the web redemption code 2.</p>
      ) : (
        isCurrentStep && <p>The brand needs to submit the web redemption code 2.</p>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div``
