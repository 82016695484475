import {
  ArrowLeftOutlined,
  InboxOutlined,
  CheckCircleFilled,
  HeartFilled,
  OrderedListOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons'
import convertHtmlToReact from '@hedgedoc/html-to-react'
import { Button, Drawer, Rate, message, Alert, Modal, Tag, Tooltip } from 'antd'
import axios from 'axios'
import { Form, Formik } from 'formik'
import { Input } from 'formik-antd'
import moment from 'moment'
import React, { useState, useEffect, useContext } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useQuery, useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'

import { CampaignThumbnail } from './CampaignThumbnail'
import ContentSubmission from './ContentSubmission'
import CreateShopifyOrder from './CreateShopifyOrder'
import { OptInAffiliateDetails } from './OptInAffiliateDetails'
import { OptInPaymentDetails } from './OptInPaymentDetails'
import { TooltipText } from './OptInsListItem'
import { Steps } from './Steps'
import affiliate from '../../../../assets/images/icons/campaigns/affiliate.svg'
import bespoke from '../../../../assets/images/icons/campaigns/bespoke.svg'
import giveaway from '../../../../assets/images/icons/campaigns/giveaway.svg'
import shoutout from '../../../../assets/images/icons/campaigns/shoutout.svg'
import ugc from '../../../../assets/images/icons/campaigns/ugc.svg'
import instagram from '../../../../assets/images/icons/social/instagram-color-square.svg'
import tiktok from '../../../../assets/images/icons/social/tiktok-color-square.svg'
import youtube from '../../../../assets/images/icons/social/youtube-color-square.svg'
import { API_URL } from '../../../../constants'
import { OptInContext } from '../../../../contexts/OptInContext'
import { UserContext } from '../../../../contexts/UserContext'
import { useMetaData } from '../../../../custom-hooks/useMetaData'
import { getAverageRating } from '../../../../utils'
import { FormItem } from '../../../general/forms/FormItem'
import { ImageCarouselSimple } from '../../../general/ImageCarouselSimple'
import LoadingHeart from '../../../general/LoadingHeart'
import { Pulser } from '../../../general/Pulser'
import { NotFound } from '../../NotFound'
import { Chat } from '../Chat'

const socialIcons = { instagram, youtube, tiktok }

export const SingleOptIn = () => {
  const queryClient = new useQueryClient()
  const { id } = useParams()
  const history = useHistory()
  const { fetchCurrentUser } = useContext(UserContext)
  const { setOptIn } = useContext(OptInContext)

  // #region State
  const [messages, setMessages] = useState([])
  const [lastMessage, setLastMessage] = useState(null)
  const [stepsVisible, setStepsVisible] = useState(false)
  const [chatVisible, setChatVisible] = useState(false)
  const [infoVisible, setInfoVisible] = useState(false)
  const [reinviteLoading, setReinviteLoading] = useState(false)
  const [brandWebsite, setBrandWebsite] = useState('')
  const [createShopifyOrderOpen, setCreateShopifyOrderOpen] = useState(false)

  const [title, setTitle] = useState('My Campaigns | Creator Dashboard')
  const description = 'View and manage your campaign.'
  const [previewImage, setPreviewImage] = useState(
    'https://creatorco.nyc3.cdn.digitaloceanspaces.com/assets/preview-images/meta-preview.png'
  )
  const [hasAffiliateLinks, setHasAffiliateLinks] = useState(false)
  // #endregion State

  // #region Queries
  const { data: userData } = useQuery('user', fetchCurrentUser)
  const { data: optIn, status } = useQuery(['opt-in', id], async () => {
    try {
      const { data } = await axios.get(`${API_URL}/me/opt-in/${id}`)
      return data
    } catch (err) {
      return null
    }
  })
  // #endregion Queries

  // #region Functions
  useEffect(() => {
    if (optIn) {
      setOptIn(optIn)

      if (
        optIn.campaign?.metaData?.affiliateLinks?.length > 0 ||
        optIn.creatorAffiliateLinks?.length > 0 ||
        optIn.campaign?.metaData?.impactRadiusAffiliateLinks?.length > 0 ||
        optIn.campaign?.metaData?.rakutenAffiliateLinks?.length > 0 ||
        optIn.campaign?.metaData?.shareasaleAffiliateLinks?.length > 0
      ) {
        setHasAffiliateLinks(true)
      }
    }
  }, [optIn, setOptIn])

  useEffect(() => {
    if (optIn?.chat?.messages) {
      setMessages(optIn.chat.messages)
    }
  }, [optIn])

  useEffect(() => {
    if (messages.length) {
      setLastMessage(messages.slice(-1)[0])
    }
  }, [messages])

  // updating meta tags for link preview
  useEffect(() => {
    if (optIn?.campaign) {
      setTitle(`${optIn.campaign.title} | My Campaigns | Creator Dashboard`)
      setPreviewImage(
        optIn.campaign.primaryImageSrc ||
          'https://creatorco.nyc3.cdn.digitaloceanspaces.com/assets/preview-images/meta-preview.png'
      )
    }
  }, [optIn])

  useMetaData(title, description, previewImage)

  const handleSubmitCouponCode = async values => {
    await axios
      .post(`${API_URL}/creator/opt-in/${optIn.id}/shopify/discount-code`, values)
      .then(() => {
        message.success('Discount code submitted successfully!')
        queryClient.invalidateQueries('opt-in')
      })
      .catch(err => {
        console.log(err)
        message.error('Error submitting discount code.')
      })
  }
  // #endregion Functions

  const acceptReinvite = () => {
    setReinviteLoading(true)
    // set opt-in to 'activated' and set 'reinvited' to null in backend
    axios
      .post(`${API_URL}/opt-in/${optIn.id}/accept-reinvite`)
      .then(() => {
        message.success('Invite accepted!')
        queryClient.invalidateQueries('opt-in')
      })
      .finally(() => {
        setReinviteLoading(false)
      })
  }

  useEffect(() => {
    if (optIn?.campaign?.brand?.website) {
      try {
        let website = optIn.campaign.brand.website
        const hasProtocol = website.includes('https://') || website.includes('http://')
        const hasWww = website.includes('www.')

        if (!hasProtocol && !hasWww) {
          website = `https://www.${website}`
        } else if (!hasProtocol && hasWww) {
          website = `https://${website}`
        } else if (hasProtocol && !hasWww) {
          website = `https://www.${website.substr(website.indexOf('//') + 2)}`
        }

        const url = new URL(website)
        if (url.protocol !== 'http:' && url.protocol !== 'https:') {
          url.protocol = 'https:'
        }
        setBrandWebsite(url.href)
      } catch (err) {
        return
      }
    }
  }, [optIn])

  return (
    <Wrapper>
      {status === 'success' ? (
        optIn ? (
          <>
            <div className='header-buttons'>
              <Button
                type='link'
                onClick={() => {
                  history.push('/my-campaigns')
                }}>
                <ArrowLeftOutlined /> Back
              </Button>
              <div className='button-group'>
                <Button type='link' onClick={() => setInfoVisible(true)}>
                  <InfoCircleOutlined />
                </Button>
                <Button
                  className='chat-button'
                  type='secondary'
                  icon={lastMessage && !lastMessage.read ? <Pulser /> : <InboxOutlined />}
                  onClick={() => setChatVisible(true)}>
                  Chat
                </Button>
              </div>
            </div>
            <div className='main'>
              <div className='campaign-info'>
                <div className='info-section basic'>
                  <h1>{optIn.campaign.title}</h1>
                  <p>
                    by{' '}
                    <a
                      className='brand'
                      target='_blank'
                      rel='noopener noreferrer'
                      href={brandWebsite}>
                      {optIn.campaign.brand.name}
                    </a>
                  </p>
                  <div className='opt-in-details'>
                    <CampaignThumbnail optIn={optIn} />
                    <div>
                      <div className='strategy'>
                        <img
                          className='icon'
                          src={
                            optIn.campaign.strategy === 'ugc'
                              ? ugc
                              : optIn.campaign.strategy === 'shoutout'
                                ? shoutout
                                : optIn.campaign.strategy === 'giveaway'
                                  ? giveaway
                                  : optIn.campaign.strategy === 'affiliate'
                                    ? affiliate
                                    : bespoke
                          }
                          alt=''
                        />
                        {optIn.campaign.socialChannels?.map((channel, i) => (
                          <img src={socialIcons[channel]} alt={channel} key={i} />
                        ))}
                        {!!optIn.campaign.contentFormats?.[0]?.length &&
                          optIn.campaign.contentFormats.map(
                            format =>
                              !!format.length && (
                                <span key={format} className='format'>
                                  {format === 'Video Post' ? 'Video' : format}
                                  {optIn.campaign.metaData?.contentFormatCounts?.[
                                    format?.toLowerCase()
                                  ] &&
                                    ` (${
                                      optIn.campaign.metaData?.contentFormatCounts?.[
                                        format?.toLowerCase()
                                      ]
                                    })`}
                                </span>
                              )
                          )}
                      </div>
                      <p className='date'>
                        {optIn.status === 'pending' ? (
                          <>Opted in: {moment(optIn.created).format('ll')}</>
                        ) : optIn.status === 'activated' ? (
                          <>Activated: {moment(optIn.activated).format('ll')}</>
                        ) : optIn.status === 'completed' ? (
                          <>Completed: {moment(optIn.completed).format('ll')}</>
                        ) : (
                          optIn.status === 'cancelled' && (
                            <>Cancelled: {moment(optIn.cancelled).format('ll')}</>
                          )
                        )}
                      </p>
                    </div>
                  </div>
                </div>

                {optIn.status === 'cancelled' && optIn.reinvited && (
                  <div className='info-section reinvite'>
                    <Alert
                      type='info'
                      message={`You've been reinvited to this campaign.`}
                      showIcon
                    />
                    <Button
                      loading={reinviteLoading}
                      type='primary'
                      onClick={e => {
                        e.preventDefault()
                        acceptReinvite()
                      }}>
                      Accept Invite
                    </Button>
                  </div>
                )}

                {optIn.rating && (
                  <div className='info-section'>
                    <h2>
                      Your Rating: {getAverageRating(optIn.rating)}
                      /5
                    </h2>
                    <div>
                      <Rate
                        value={getAverageRating(optIn.rating)}
                        character={<HeartFilled />}
                        className='rate'
                        disabled
                        allowHalf
                      />
                      <Tooltip
                        title={
                          <TooltipText>
                            <p>
                              <b>Average Rating: {getAverageRating(optIn.rating)}</b>
                            </p>
                            <p>Content: {optIn.rating.content || 0}</p>
                            <p>Creativity: {optIn.rating.creativity || 0}</p>
                            <p>Communication: {optIn.rating.communication || 0}</p>
                            <p>Overall: {optIn.rating.overall || 0}</p>
                          </TooltipText>
                        }>
                        <InfoCircleOutlined />
                      </Tooltip>
                    </div>
                  </div>
                )}

                {['activated', 'completed'].includes(optIn.status) &&
                  !!optIn.campaign.steps?.length && (
                    <div className='info-section actions'>
                      <h2>Campaign Steps</h2>
                      {optIn.status === 'activated' && (
                        <div className='current-step'>
                          {optIn.currentStep ? (
                            <>
                              <span className='step-info'>
                                <b>
                                  Step {optIn.currentStep.placement} of{' '}
                                  {optIn.campaign.steps.length}
                                </b>
                                {optIn.currentStep &&
                                  (optIn.currentStep.actionee === 'creator' ? (
                                    <span className='step creator'>Action Required</span>
                                  ) : (
                                    <span className='step brand'>Waiting on Brand</span>
                                  ))}
                              </span>
                              {optIn.currentStep.customStepTitle ||
                                optIn.currentStep.step.title.replace('Old: ', '')}
                            </>
                          ) : (
                            <span className='step-info'>
                              <CheckCircleOutlined /> All steps complete!
                            </span>
                          )}
                        </div>
                      )}
                      {optIn.status === 'completed' && (
                        <div className='current-step'>
                          <span className='step-info'>
                            <CheckCircleOutlined /> All steps complete!
                          </span>
                        </div>
                      )}
                      {optIn.status !== 'cancelled' && (
                        <Button type='link' onClick={() => setStepsVisible(true)}>
                          <OrderedListOutlined /> View Steps
                        </Button>
                      )}
                    </div>
                  )}

                {/* Content Submission */}
                {!!optIn.socialPosts.length && (
                  <div className='info-section'>
                    <ContentSubmission
                      optInId={optIn.id}
                      socialPosts={optIn.socialPosts}
                      shoutoutStepsCompleted={optIn.shoutoutStepsCompleted}
                    />
                  </div>
                )}

                {hasAffiliateLinks && ['activated', 'completed'].includes(optIn.status) && (
                  <OptInAffiliateDetails userData={userData} optIn={optIn} />
                )}

                {!!optIn.campaign.maxPaidAmount && (
                  <OptInPaymentDetails id={id} optIn={optIn} userData={userData} />
                )}
                {optIn.campaign.metaData.shopifyPriceRuleId && (
                  <div className='info-section'>
                    <h2>Shopify Discount Code</h2>
                    {optIn.shopifyDiscountCodes?.length ? (
                      <div>
                        Your code: <b>{optIn.shopifyDiscountCodes[0].code}</b>
                      </div>
                    ) : (
                      <Formik initialValues={{ code: '' }} onSubmit={handleSubmitCouponCode}>
                        {({ isSubmitting }) => (
                          <Form>
                            <FormItem label='Please generate a discount code for your audience.'>
                              <div className='coupon-code'>
                                <Input name='code' />
                                <Button htmlType='submit' loading={isSubmitting} type='primary'>
                                  Submit
                                </Button>
                              </div>
                            </FormItem>
                          </Form>
                        )}
                      </Formik>
                    )}
                  </div>
                )}

                <div className='info-section compensation'>
                  <h2>Compensation</h2>
                  <div className='details-text'>
                    {!!optIn.campaign.maxPaidAmount && (
                      <>
                        <div className='paid-campaign'>
                          <CheckCircleFilled /> Paid campaign: $
                          {optIn.campaign.minPaidAmount
                            ? `${optIn.campaign.minPaidAmount} to ${optIn.campaign.maxPaidAmount}`
                            : optIn.campaign.maxPaidAmount}{' '}
                          USD
                        </div>
                        <p className='disclaimer'>
                          Please note, a 5% processing fee will be deducted from the final
                          negotiated payment amount.
                        </p>
                      </>
                    )}
                    {optIn.campaign.productDescription?.split('\n').map((line, i) => (
                      <div key={i} className='text'>
                        {convertHtmlToReact(line)}
                      </div>
                    ))}
                    <div>
                      Retail Value:{' '}
                      <span className='campaign-value'>
                        {!!optIn?.campaign?.metaData?.minValue && (
                          <>
                            <span>
                              $
                              {(Math.round(optIn.campaign?.metaData?.minValue * 100) / 100).toFixed(
                                2
                              )}
                            </span>{' '}
                            -{' '}
                          </>
                        )}
                        $
                        {(
                          Math.round(
                            (optIn.campaign.value
                              ? optIn.campaign.value
                              : optIn.campaign.holdCost) * 100
                          ) / 100
                        ).toFixed(2)}
                      </span>
                    </div>
                    {!!optIn.campaign.variables?.length && (
                      <div className='variables'>
                        <h3>Product Options</h3>
                        {optIn.campaign.variables.map(variable => {
                          const optInVariable = optIn.variables.find(
                            option => variable.id === option.variableId
                          )
                          return (
                            <div className='option' key={variable.id}>
                              <p className='label'>{variable.title}</p>
                              {variable.options.map(option => (
                                <p
                                  className={`value ${
                                    optInVariable?.selectedOption.id === option.id && 'selected'
                                  }`}
                                  key={option.id}>
                                  • {option.title}
                                  {optInVariable?.selectedOption.id === option.id && (
                                    <Tag color='green'>
                                      <CheckCircleOutlined /> Selected
                                    </Tag>
                                  )}
                                </p>
                              ))}
                            </div>
                          )
                        })}
                      </div>
                    )}
                    {optIn.productListItem && (
                      <div className='selected-product'>
                        <h3>Product</h3>
                        <div className='item-info'>
                          <Tag color='green' icon={<CheckCircleOutlined />}>
                            Selected
                          </Tag>
                          <p className='item-title'>{optIn.productListItem.title}</p>
                        </div>
                        <p className='item-description'>{optIn.productListItem.description}</p>
                      </div>
                    )}
                  </div>

                  {['activated', 'completed'].includes(optIn.status) &&
                    optIn.campaign.hasProducts && (
                      <div className='shopify-orders'>
                        {optIn.extraData.ordersData?.length ? (
                          <>
                            {optIn.extraData.ordersData.map(order => (
                              <div className='order-details' key={order}>
                                <p>
                                  Order# {order.id} - {moment(order.orderCreatedAt).format('ll')}
                                </p>
                              </div>
                            ))}
                          </>
                        ) : (
                          <Button onClick={() => setCreateShopifyOrderOpen(true)} type='primary'>
                            Create Order
                          </Button>
                        )}
                      </div>
                    )}
                </div>

                <div className='info-section objectives'>
                  <h2>Deliverables</h2>
                  <div className='objective'>
                    {optIn.campaign.socialChannels?.map((channel, i) => (
                      <img className='icon' src={socialIcons[channel]} alt={channel} key={i} />
                    ))}
                    <img
                      className='icon'
                      src={
                        optIn.campaign.strategy === 'ugc'
                          ? ugc
                          : optIn.campaign.strategy === 'shoutout'
                            ? shoutout
                            : optIn.campaign.strategy === 'giveaway'
                              ? giveaway
                              : optIn.campaign.strategy === 'affiliate'
                                ? affiliate
                                : bespoke
                      }
                      alt=''
                    />
                    <span className='strategy'>
                      {optIn.campaign.strategy === 'ugc'
                        ? 'UGC'
                        : optIn.campaign.strategy === 'shoutout'
                          ? 'ShoutOut'
                          : optIn.campaign.strategy === 'giveaway'
                            ? 'Giveaway'
                            : optIn.campaign.strategy === 'affiliate'
                              ? 'Affiliate'
                              : 'Bespoke'}
                    </span>
                    {!!optIn.campaign.contentFormats?.length &&
                      !!optIn.campaign.contentFormats[0].length &&
                      optIn.campaign.contentFormats.map(
                        format =>
                          !!format.length && (
                            <span key={format} className='format'>
                              {format === 'Video Post' ? 'Video' : format}
                              {optIn.campaign.metaData?.contentFormatCounts?.[
                                format?.toLowerCase()
                              ] &&
                                ` (${
                                  optIn.campaign.metaData?.contentFormatCounts?.[
                                    format?.toLowerCase()
                                  ]
                                })`}
                            </span>
                          )
                      )}
                  </div>
                  {!!optIn.extraData?.channels?.length && (
                    <>
                      <h3>Channels</h3>
                      <div className='details-text'>
                        <p>
                          You opted to post on:{' '}
                          <b>
                            {optIn.extraData.channels
                              .map(channel =>
                                channel === 'instagram'
                                  ? 'Instagram'
                                  : channel === 'tiktok'
                                    ? 'TikTok'
                                    : channel === 'youtube' && 'YouTube'
                              )
                              .join(', ')}
                          </b>
                        </p>
                      </div>
                    </>
                  )}
                  {optIn.campaign.visuals?.length > 0 && (
                    <>
                      <h3>Visuals & Theme</h3>
                      <div className='details-text'>
                        {optIn.campaign.visuals?.split('\n').map((line, i) => (
                          <div key={i} className='text'>
                            {convertHtmlToReact(line)}
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                  {optIn.campaign.caption?.length > 0 && (
                    <>
                      <h3>Caption</h3>
                      <div className='details-text'>
                        {optIn.campaign.caption?.split('\n').map((line, i) => (
                          <div key={i} className='text'>
                            {convertHtmlToReact(line)}
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                  {(optIn.campaign.hashtags?.length > 0 ||
                    optIn.campaign.photoTags?.length > 0) && (
                    <>
                      <h3>Hashtags & Mentions</h3>
                      <div className='details-text'>
                        <p className='tags'>
                          {optIn.campaign.hashtags.map((tag, key) => (
                            <CopyToClipboard
                              key={`${key}-${tag}`}
                              text={`#${tag}`}
                              onCopy={() => message.success('Copied!')}>
                              <span className='tag'>#{tag}</span>
                            </CopyToClipboard>
                          ))}
                          {optIn.campaign.photoTags.map((tag, key) => (
                            <CopyToClipboard
                              key={`${key}-${tag}`}
                              text={`@${tag}`}
                              onCopy={() => message.success('Copied!')}>
                              <span className='tag'>@{tag}</span>
                            </CopyToClipboard>
                          ))}
                        </p>
                        <p className='info-text'>
                          <InfoCircleOutlined /> Click on a tag to copy it
                        </p>
                      </div>
                    </>
                  )}
                </div>

                {optIn.campaign.metaData?.inspirationImages?.length > 0 && (
                  <div className='info-section'>
                    <h2>Inspiration Board</h2>
                    <p className='info-text'>
                      Use these images to help guide your content&apos;s artistic direction.
                    </p>
                    <ImageCarouselSimple images={optIn.campaign.metaData.inspirationImages} />
                  </div>
                )}
              </div>

              <div className='desktop-chat'>
                <Chat campaign={optIn.campaign} brand={optIn.campaign.brand} optIn={optIn} />
              </div>
            </div>

            {/* DRAWERS / MODALS */}
            <Drawer
              title='Miscellaneous Info'
              open={infoVisible}
              onClose={() => setInfoVisible(false)}
              width={window.innerWidth > 600 ? 500 : '100vw'}>
              <DrawerInner>
                <h1>{optIn.campaign.title}</h1>
                <h2>Campaign Details</h2>
                <div className='details-text'>
                  {optIn.campaign.description?.split('\n').map((line, i) => (
                    <p key={i} className='text'>
                      {convertHtmlToReact(line)}
                    </p>
                  ))}
                </div>
                <h2>About the Brand</h2>
                <div className='details-text'>
                  {optIn.campaign.brand.description?.split('\n').map((line, i) => (
                    <p key={i} className='text'>
                      {convertHtmlToReact(line)}
                    </p>
                  ))}
                </div>
              </DrawerInner>
            </Drawer>

            <Drawer
              title='Campaign steps'
              open={stepsVisible}
              onClose={() => setStepsVisible(false)}
              width={window.innerWidth > 600 ? 500 : '100vw'}>
              <Steps />
            </Drawer>

            <Drawer
              title='Chat'
              className='no-padding'
              open={chatVisible}
              onClose={() => setChatVisible(false)}
              destroyOnClose={true}
              width={window.innerWidth > 600 ? 500 : '100vw'}>
              {chatVisible && (
                <Chat campaign={optIn.campaign} brand={optIn.campaign.brand} optIn={optIn} />
              )}
            </Drawer>

            <Modal
              footer={null}
              title={'Product selection'}
              open={createShopifyOrderOpen}
              onCancel={() => setCreateShopifyOrderOpen(false)}>
              <CreateShopifyOrder
                optInId={optIn.id}
                maxShopifyProducts={optIn.campaign.metaData.maxShopifyProducts}
                close={() => setCreateShopifyOrderOpen(false)}
              />
            </Modal>
          </>
        ) : (
          <NotFound
            message={'Opt-in not found.'}
            buttonText={'My Campaigns'}
            to={'/my-campaigns'}
          />
        )
      ) : (
        <div className='loading'>
          <LoadingHeart />
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  width: 100vw;
  color: #777e90;
  h1,
  h2,
  h3 {
    font-family: 'Poppins-Bold', sans-serif;
    color: #23262f;
  }
  h1 {
    font-size: 1.2rem;
    margin: 0;
  }
  h2 {
    font-size: 1.1rem;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 20px;
  }
  .header-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 10px;
    z-index: 1;
    .button-group {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }
  .back-btn {
    background: #fff;
    color: ${props => props.theme.crcoTechBlue};
    border: 0;
    padding: 2px 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    border-radius: 40px;
    &:hover {
      color: ${props => props.theme.crcoMidnight};
    }
    .anticon {
      margin-right: 5px;
    }
  }
  .chat-button {
    position: relative;
    display: flex;
    align-items: center;
  }
  .campaign-info {
    display: flex;
    flex-direction: column;
    .info-section {
      background: #fff;
      padding: 20px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      &:last-child {
        margin-bottom: 0;
        border-bottom: 0;
      }
      .paid-campaign {
        background: ${props => props.theme.crcoLettuce};
        color: #fff;
        width: fit-content;
        font-size: 0.8rem;
        padding: 3px 5px 0 5px;
        border-radius: 20px;
        margin-bottom: 10px;
        .anticon {
          border: 1px solid white;
          border-radius: 50%;
          padding: 1px;
        }
      }
      .coupon-code {
        display: flex;
        gap: 12px;
        margin-top: 10px;
      }
      .disclaimer {
        padding: 5px;
        background: #eee;
        border-radius: 3px;
        font-size: 0.8rem;
        border: 1px solid #e6e6e6;
        color: #666;
      }
      .details-text {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        .campaign-value {
          color: ${props => props.theme.crcoTechBlue};
        }
        .text {
          margin: 0 0 10px 0;
          word-break: break-word;
        }
        .icon {
          height: 20px;
        }
        .tags {
          display: flex;
          flex-wrap: wrap;
          .tag {
            color: ${props => props.theme.crcoTechBlue};
            margin-right: 10px;
            cursor: pointer;
            transition: 0.2s ease-in-out;
            &:hover {
              color: ${props => props.theme.crcoMidnight};
            }
          }
        }
      }
      .shopify-orders {
        display: flex;
        justify-content: flex-end;
      }
      &.objectives {
        .strategy {
          font-size: 1rem;
          color: ${props => props.theme.crcoTechBlue};
        }
        .icon {
          height: 20px;
          margin-right: 5px;
        }
        .objective {
          margin-bottom: 20px;
          .strategy {
            color: #027df0;
            margin: 0 5px;
          }
          .format {
            background: #ecf6ff;
            color: #027df0;
            padding: 3px 5px 2px 5px;
            margin-right: 5px;
            border-radius: 3px;
            font-size: 0.8rem;
          }
          .tags {
            display: flex;
            flex-wrap: wrap;
            .tag {
              color: ${props => props.theme.crcoTechBlue};
              margin-right: 10px;
            }
          }
        }
      }
      .rate {
        color: ${props => props.theme.crcoCoral};
        margin-right: 10px;
      }
    }
    .basic {
      .opt-in-details {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
        margin-top: 10px;
      }
      .strategy {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        img {
          height: 20px;
        }
        .format {
          background: #ecf6ff;
          color: #027df0;
          display: grid;
          place-content: center;
          padding: 0 5px;
          border-radius: 3px;
          font-size: 0.7rem;
        }
      }
      .payout {
        font-size: 0.8rem;
        margin-top: 5px;
        color: rgba(0, 0, 0, 0.3);
        .amount {
          color: #00c457;
          font-weight: bold;
        }
      }
      .date {
        font-size: 0.8rem;
        margin-top: 8px;
      }
    }
    .payment {
      .receipt {
        border: 1px solid #e6e6e6;
        padding: 10px;
        border-radius: 5px;
        .line {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #999;
          padding: 5px 0;
          span {
            color: #000;
          }
          &.total {
            border-top: 1px dotted #ccc;
            .status-tag {
              color: #666;
              font-size: 0.7rem;
              padding: 3px 10px 2px 10px;
              border-radius: 10px;
              &.paid {
                background: #dceeff;
                color: ${props => props.theme.crcoTechBlue};
              }
            }
            .num {
              color: ${props => props.theme.crcoTechBlue};
              font-size: 1.2rem;
            }
          }
        }
      }
      .num {
        font-weight: bold;
      }
      .negotiation {
        border: 1px solid #e6e6e6;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        padding: 20px;
        .offer {
          font-size: 1.2rem;
          margin-bottom: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          font-weight: bold;
          .prev-amount {
            color: #bbb;
          }
          .arrow {
            color: #bbb;
            font-size: 0.8rem;
            margin-bottom: 4px;
          }
          .new-amount {
            color: ${props => props.theme.crcoTechBlue};
          }
        }
        .actions {
          display: flex;
          gap: 12px;
          & > button {
            flex: 1;
          }
        }
      }
      .payout-settings {
        margin-top: 20px;
      }
    }
    .awin-affiliate {
      .awin-commission {
        margin-bottom: 12px;
      }
      .awin-links {
      }
    }

    .creator-affiliate {
      button {
        margin-left: -5px;
      }
      .creator-affiliate-commission {
        display: flex;
        flex-direction: column;
        gap: 5px;
        border: 1px solid #e6e6e6;
        padding: 10px;
        border-radius: 5px;
        .line {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #999;
          padding: 5px 0;
          span {
            color: #000;
          }
          &.total {
            border-top: 1px dotted #ccc;
            .status-tag {
              background: #ddd;
              color: #666;
              font-size: 0.7rem;
              padding: 3px 10px 2px 10px;
              border-radius: 10px;
              &.paid {
                background: #dceeff;
                color: ${props => props.theme.crcoTechBlue};
              }
            }
            .num {
              color: ${props => props.theme.crcoTechBlue};
              font-size: 1.2rem;
            }
          }
        }
      }
      .num {
        font-weight: bold;
      }
    }
  }
  .awin-affiliate {
    .awin-commission {
      margin-bottom: 12px;
    }
    .awin-links {
    }
  }

  .creator-affiliate {
    button {
      margin-left: -5px;
    }

    .creator-affiliate-commission {
      display: flex;
      flex-direction: column;
      gap: 5px;
      border: 1px solid #e6e6e6;
      border-radius: 5px;
    }

    .affiliate-links {
      padding: 10px 15px;

      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: flex-start;

      &:not(:last-child) {
        border-bottom: 1px solid #e6e6e6;
      }

      .urlPath,
      .affiliate-commission,
      .affiliate-description {
        opacity: 70%;
      }
    }
  }

  .reinvite {
    align-items: center;
  }
  .actions {
    .current-step {
      width: 100%;
      margin-bottom: 20px;
      .step-info {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      .step {
        background: ${props => props.theme.crcoTechBlue};
        color: #fff;
        border-radius: 10px;
        display: block;
        text-align: center;
        padding: 2px 10px 0 10px;
        font-size: 0.7rem;
        text-transform: uppercase;
        .anticon {
          margin-right: 5px;
        }
        &.creator {
          background: ${props => props.theme.crcoCoral};
        }
        &.brand {
          background: #aaa;
        }
      }
    }
    button {
      width: fit-content;
    }
  }
  .variables {
    margin-top: 20px;
    p {
      margin: 0;
    }
    .option {
      margin-bottom: 10px;
      .label {
        color: ${props => props.theme.crcoTechBlue};
      }
      .value {
        &.selected {
          color: #24262f;
        }
      }
      .ant-tag {
        margin: 0 0 0 5px;
      }
    }
  }
  .selected-product {
    margin-top: 20px;
    p {
      margin: 0;
    }
    .item-info {
      display: flex;
      align-items: center;
    }
    .item-title {
      font-weight: bold;
    }
    .item-description {
      font-size: 0.8rem;
      color: ${props => props.theme.crcoGrey};
    }
  }
  .desktop-chat {
    display: none;
  }
  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    width: 100%;
    h1 {
      font-size: 1.4rem;
    }
    h2 {
      font-size: 1.2rem;
    }
    .header-buttons {
      padding: 15px;
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpointDesktopWide}) {
    max-height: calc(100vh - 50px);
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 0 10px;
    overflow: hidden;
    h1 {
      font-size: 1.5rem;
    }
    h2 {
      font-size: 1.3rem;
    }
    .header-buttons {
      padding: 15px 0;
      .chat-button {
        display: none;
      }
    }
    .main {
      display: flex;
      margin-bottom: 20px;
      border-radius: 5px;
      max-height: 100%;
      overflow: hidden;
      .campaign-info {
        flex: 1;
        ${props => props.theme.scrollbar};
        padding-right: 4px;
        margin-right: 4px;
        overflow: auto;
        .info-section {
          border-radius: 5px;
          &.affiliate {
            word-break: break-word;
          }
        }
      }
      .desktop-chat {
        display: block;
        flex: 1;
      }
    }
  }
`

const DrawerInner = styled.div`
  color: #777e90;
  h1,
  h2,
  h3 {
    font-family: 'Poppins-Bold', sans-serif;
    color: #23262f;
  }
  h1 {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  h2 {
    font-size: 1.1rem;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 20px;
  }
  .details-text {
    font-size: 0.9rem;
    margin-bottom: 20px;
  }
`
