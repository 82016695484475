import {
  HeartOutlined,
  LoadingOutlined,
  MessageOutlined,
  ThunderboltOutlined,
} from '@ant-design/icons'
import { Empty } from 'antd'
import axios from 'axios'
import numeral from 'numeral'
import React, { useState, useEffect, useRef } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components/macro'

import instagram from '../../../../assets/images/icons/social/instagram-color-square.svg'
import tiktok from '../../../../assets/images/icons/social/tiktok-color-square.svg'
import youtube from '../../../../assets/images/icons/social/youtube-color-square.svg'
import { ImageLoader } from '../../../../components/general/ImageLoader'
import { API_URL } from '../../../../constants'
const platformIcons = { instagram, youtube, tiktok }

export const ContentCarousel = ({ example, content, socials }) => {
  const [loading, setLoading] = useState(undefined)
  const [highlightedContent, setHighlightedContent] = useState(example ? content : undefined)

  useEffect(() => {
    setLoading(true)
    if (!example && content) {
      axios
        .post(`${API_URL}/social-posts`, { ids: content })
        .then(res => {
          setHighlightedContent(res.data)
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    } else setLoading(false)
  }, [content, example])

  const sliderRef = useRef()

  const sliderSettings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    pauseOnHover: true,
    pauseOnFocus: true,
    pauseOnDotsHover: true,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  const calculateEngagement = (likes, comments, platform) => {
    const total = likes + comments
    const followers = socials.find(social => social.platform === platform)?.followerCount
    const engagement = total / followers
    return numeral(engagement).format('0.0%')
  }

  return (
    <Wrapper>
      {loading ? (
        // TODO: ghost cards
        <LoadingOutlined spin />
      ) : !highlightedContent?.length ? (
        <div className='empty'>
          <Empty description='No content.' />
        </div>
      ) : highlightedContent?.length < 3 ? (
        <div className='empty'>
          <Empty description='Not enough content.' />
        </div>
      ) : (
        <Slider className='slider' ref={sliderRef} {...sliderSettings}>
          {highlightedContent.map((content, key) => (
            <div key={key}>
              <div className='content-card'>
                <a href={content.url} target='_blank' rel='noopener noreferrer'>
                  <div className='platform-icon'>
                    <img
                      className='icon'
                      src={platformIcons[content.platform]}
                      alt={content.platform}
                    />
                  </div>
                  <div className='thumbnail'>
                    <ImageLoader src={content.thumbnailUrl} alt='' />
                  </div>
                  <div className='text'>
                    <p className='username'>@{content.username}</p>
                    <p className='title'>{content.title || '-'}</p>
                    <div className='metrics'>
                      <div className='row'>
                        <span className='label'>
                          <HeartOutlined /> Likes
                        </span>
                        <span className='value'>{numeral(content.likes).format('0a')}</span>
                      </div>
                      <div className='row'>
                        <span className='label'>
                          <MessageOutlined /> Comments
                        </span>
                        <span className='value'>{numeral(content.comments).format('0a')}</span>
                      </div>
                      <div className='row'>
                        <span className='label'>
                          <ThunderboltOutlined /> Engagement
                        </span>
                        <span className='value'>
                          {calculateEngagement(content.likes, content.comments, content.platform)}
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          ))}
        </Slider>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  max-width: 100%;
  position: relative;
  .empty {
    background: ${props => props.theme.primaryColor400};
    padding: 20px;
    border-radius: 24px;
  }
  .slick-prev:before,
  .slick-next:before {
    color: ${props => props.theme.primaryTextColor};
    opacity: 0.3;
  }
  .slider {
    .content-card {
      background: ${props => props.theme.primaryColor400};
      border-radius: 24px;
      overflow: hidden;
      margin: auto 10px;
      position: relative;
      &:hover {
        .platform-icon {
          background: ${props => props.theme.primaryColor100};
        }
      }
      @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
        margin: auto 20px;
      }
      .platform-icon {
        position: absolute;
        padding: 5px 0 5px 5px;
        background: ${props => props.theme.primaryColor300};
        border-radius: 10px 0 0 10px;
        right: 0;
        top: 20px;
        transition: 0.2s ease-in-out;
        .icon {
          height: 20px;
        }
      }
      .thumbnail {
        background: ${props => props.theme.primaryColor200};
        aspect-ratio: 1;
        width: 100%;
        overflow: hidden;
      }
      .text {
        padding: 20px;
      }
      .username {
        background: ${props => props.theme.primaryColor200};
        color: ${props => props.theme.primaryColor100};
        padding: 3px 10px;
        border-radius: 4px;
        margin-bottom: 10px;
        width: fit-content;
      }
      .info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;
      }
      .title {
        color: ${props => props.theme.secondaryTextColor};
        font-size: 12px;
        min-height: 56px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .date {
        color: ${props => props.theme.primaryTextColor};
        opacity: 0.5;
        text-transform: uppercase;
        font-size: 0.8rem;
      }
      .type {
        background: ${props => props.theme.background};
        color: ${props => props.theme.primaryColor100};
        padding: 2px 5px 0 5px;
        border-radius: 5px;
        font-size: 0.8rem;
        border: 1px solid transparent;
        display: flex;
        align-items: center;
        grid-gap: 10px;
      }
      .metrics {
        color: ${props => props.theme.primaryTextColor};
        font-size: 14px;
        margin-top: 5px;
        padding-top: 5px;
        border-top: 1px solid ${props => props.theme.darkTransparency};
        .row {
          display: flex;
          justify-content: space-between;
          padding-top: 8px;
        }
        .label {
          opacity: 0.5;
          display: flex;
          align-items: center;
          gap: 10px;
        }
        .value {
          color: ${props => props.theme.primaryColor100};
          font-weight: bold;
        }
      }
    }
    .slick-dots {
      width: fit-content;
      bottom: -40px;
      position: absolute;
      padding: 0;
      margin: 0;
      display: flex !important;
      gap: 12px;
      left: 50%;
      transform: translateX(-50%);
      li {
        list-style: none;
        background: ${props => props.theme.slickDotInactive};
        height: 10px;
        width: 10px;
        border-radius: 10px;
        margin: 0;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        &:hover,
        &.slick-active {
          background: ${props => props.theme.slickDotActive};
        }
        &.slick-active {
          width: 35px;
        }
        button {
          opacity: 0;
        }
      }
    }
  }
`
