import {
  CheckCircleOutlined,
  HeartOutlined,
  StarOutlined,
  LoadingOutlined,
  EnvironmentOutlined,
} from '@ant-design/icons'
import { Empty, Button } from 'antd'
import axios from 'axios'
import moment from 'moment'
import numeral from 'numeral'
import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useQuery } from 'react-query'
import styled, { ThemeProvider } from 'styled-components'

import { ContentCarousel } from './ContentCarousel'
import { exampleProfile, darkProfile, bryceProfile } from './dummy-profile-data'
import { themes } from './themes'
import instagram from '../../../../assets/images/icons/social/instagram-color-square.svg'
import tiktok from '../../../../assets/images/icons/social/tiktok-color-square.svg'
import youtube from '../../../../assets/images/icons/social/youtube-color-square.svg'
import imagePlaceholder from '../../../../assets/images/image-placeholder.svg'
import creatorcoLogo from '../../../../assets/images/primary-logo.svg'
import { API_URL } from '../../../../constants'
import { useMetaData } from '../../../../custom-hooks/useMetaData'
import { ImageLoader } from '../../../general/ImageLoader'
const platformIcons = { instagram, youtube, tiktok }

export const CreatorProfile = ({ subdomain }) => {
  // #region States
  const example = ['example', 'example-dark', 'bryce-harper'].includes(subdomain)
  const [theme, setTheme] = useState(themes.lightTheme)
  const [notFound, setNotFound] = useState(undefined)
  const [profileSettings, setProfileSettings] = useState(undefined)
  const [selectedChannel, setSelectedChannel] = useState(undefined)
  // #endregion States

  // #region Queries
  const { data, status } = useQuery('creator-profile', async () => {
    const { data } = await axios.get(`${API_URL}/public/creator-profile/${subdomain}`)
    return data
  })
  // #endregion Queries

  // #region Functions
  // setting profile info based on subdomain
  useEffect(() => {
    if (example) {
      setProfileSettings(
        subdomain === 'example'
          ? exampleProfile
          : subdomain === 'example-dark'
            ? darkProfile
            : bryceProfile
      )
    } else if (data?.profile) {
      const { profile } = data
      const socialProfiles = profile.user.socialProfiles
      const platforms = ['instagram', 'tiktok', 'youtube']

      const findProfile = platform =>
        socialProfiles.find(
          profile => profile.visibility === 'public' && profile.platform === platform
        )

      const connectedSocials = platforms
        .filter(platform => findProfile(platform))
        .map(platform => {
          const socialProfile = findProfile(platform)
          return {
            platform,
            username: socialProfile.username,
            url: socialProfile.profileUrl,
            profilePicUrl: socialProfile.profilePicUrl,
            followerCount: socialProfile.followerCount,
            engagement: socialProfile.engagement,
            updated: socialProfile.updated,
            phylloData: {
              id: socialProfile.phylloData.account.id,
              platform_profile_name: socialProfile.phylloData.platform_profile_name,
              introduction: socialProfile.phylloData.introduction,
            },
          }
        })

      setProfileSettings({
        profileImg: profile.profileSettings?.profileImg,
        name: `${profile.user.firstName} ${profile.user.lastName}`,
        email: profile.profileSettings?.email,
        profession: profile.profileSettings?.profession,
        bio: profile.profileSettings?.bio,
        shippingRegion: profile.shippingRegion,
        shippingCountry: profile.shippingCountry,
        birthDate: profile.birthDate,
        showLocation: profile.profileSettings?.showLocation,
        showAge: profile.profileSettings?.showAge,
        certifications: profile.profileSettings?.certifications,
        creatorValues: profile.profileSettings?.creatorValues,
        niches: profile.niches.map(niche => niche.category.title),
        socials: connectedSocials,
        rates: profile.profileSettings?.rates,
        highlightedContent: profile.profileSettings?.highlightedContent,
        recommendedProducts: profile.profileSettings?.recommendedProducts,
        customLinks: profile.profileSettings?.customLinks,
        theme: profile.profileSettings?.theme || 'lightTheme',
      })
    } else if (status === 'error') {
      setNotFound(true)
    }
  }, [example, data, status, notFound, subdomain])

  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [previewImage, setPreviewImage] = useState('')

  // switching to theme from profile settings
  useEffect(() => {
    if (profileSettings?.theme) {
      setTheme(themes[profileSettings.theme])
    }
    if (profileSettings?.socials) {
      setSelectedChannel(profileSettings?.socials[0])
    }
    // updating meta tags for link preview based on profile settings
    if (notFound) {
      setTitle('Not found | Creator Profile')
      setDescription('Oops, this profile does not exist.')
      setPreviewImage(
        'https://creatorco.nyc3.cdn.digitaloceanspaces.com/assets/preview-images/meta-preview.png'
      )
    } else if (profileSettings) {
      setTitle(`${profileSettings?.name} | Creator Profile`)
      setDescription(`View ${profileSettings?.name}'s official creator.co profile.`)
      setPreviewImage(
        profileSettings?.profileImg ||
          'https://creatorco.nyc3.cdn.digitaloceanspaces.com/assets/preview-images/meta-preview.png'
      )
    }
  }, [notFound, profileSettings, profileSettings?.socials, profileSettings?.theme])

  // Setting current section in nav based on scroll position
  const sections = document.querySelectorAll('section')
  const navLinks = document.querySelectorAll('.nav-link')
  const [currentSection, setCurrentSection] = useState('')

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  const handleScroll = () => {
    let current = ''

    // Total document height, viewport height, and vertical scroll position
    const scrollHeight = document.documentElement.scrollHeight
    const scrollTop = window.scrollY || window.pageYOffset // The latter is for older versions of IE
    const windowHeight = window.innerHeight

    // Check if the user has scrolled to the bottom of the page to set Links section as active
    // Account for fractional pixels with Math.ceil
    if (Math.ceil(scrollTop + windowHeight) >= scrollHeight) {
      current = 'links'
    } else {
      sections.forEach(section => {
        const sectionTop = section.offsetTop
        if (window.scrollY >= sectionTop - 200) {
          current = section.getAttribute('id')
        }
      })
    }

    setCurrentSection(current)
  }

  useEffect(() => {
    navLinks.forEach(li => {
      li.classList.remove('active')
      if (li.classList.contains(currentSection)) {
        li.classList.add('active')
      }
    })
  }, [currentSection, navLinks])

  useMetaData(title, description, previewImage)

  // intersection observer for scrolling fade-in animations
  const faders = document.querySelectorAll('.fade-in')

  const appearOptions = {
    // threshold controls how much of the target should be visible before the effect runs
    threshold: 0.5,
  }

  const appearOnScroll = new IntersectionObserver(function (entries, appearOnScroll) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('appear')
        appearOnScroll.unobserve(entry.target)
      }
    })
  }, appearOptions)

  faders.forEach(fader => {
    appearOnScroll.observe(fader)
  })

  // calculating age from birthDate
  const calculateAge = birthDate => {
    const today = new Date()
    const birthdate = new Date(birthDate)
    const age = today.getFullYear() - birthdate.getFullYear()
    const m = today.getMonth() - birthdate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
      return age - 1
    }
    return age
  }

  // floating action button scroll functionality
  const [isScrolled, setIsScrolled] = useState(false)
  window.onscroll = () => {
    if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
      setIsScrolled(true)
    } else setIsScrolled(false)
  }
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  // #endregion Functions

  return (
    <ThemeProvider theme={theme}>
      {/* DYNAMICALLY SET META TAGS */}
      <Helmet>
        <meta property='og:title' content={title} />
        <meta property='og:description' content={description} />
        <meta property='og:image' content={previewImage} />
      </Helmet>
      {/* END META TAGS */}

      <Wrapper>
        {profileSettings ? (
          <>
            <div className='sections'>
              <Bio id='bio'>
                <div className='banner' />
                <div className='section-inner'>
                  <div className='outer-image-border'>
                    <div className='creator-image'>
                      <ImageLoader src={profileSettings.profileImg || theme.profilePlaceholder} />
                    </div>
                  </div>
                  <div className='creator-info'>
                    <p className='job-title'>{profileSettings.profession}</p>
                    <h1 className='name'>{profileSettings.name}</h1>
                    <div className='personal-info'>
                      {profileSettings.showLocation && (
                        <p className='location'>
                          <EnvironmentOutlined /> {profileSettings.shippingRegion},{' '}
                          {profileSettings.shippingCountry}
                        </p>
                      )}
                      {profileSettings.showLocation &&
                        profileSettings.showAge &&
                        profileSettings.birthDate &&
                        '•'}
                      {profileSettings.showAge && (
                        <p className='age'>
                          {profileSettings.birthDate &&
                            'Age: ' + calculateAge(profileSettings.birthDate)}
                        </p>
                      )}
                    </div>

                    <div className='bio'>
                      {profileSettings.bio &&
                        profileSettings.bio.split('\n').map((line, key) => <p key={key}>{line}</p>)}
                    </div>
                    <div className='social-links'>
                      {profileSettings.email && (
                        <a href={`mailto:${profileSettings.email}`}>
                          <button className='email-btn'>Email Me</button>
                        </a>
                      )}
                      {profileSettings.socials?.map((social, key) => (
                        <a href={social.url} key={key} target='_blank' rel='noopener noreferrer'>
                          <img
                            className='icon'
                            src={platformIcons[social.platform]}
                            alt={social.platform}
                          />
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </Bio>

              <ProfileNav>
                <div className='nav-inner'>
                  <button className='bio-link' onClick={() => scrollToTop()}>
                    <div
                      className='avatar'
                      style={{
                        background: `url(${
                          profileSettings.profileImg || theme.profilePlaceholder
                        }) center center / cover`,
                      }}
                    />
                    <span className='name'>{profileSettings.name}</span>
                  </button>
                  <div className='nav-links'>
                    <a className='nav-link influence' href='#influence'>
                      Niche
                    </a>
                    <a className='nav-link channels' href='#channels'>
                      Channels
                    </a>
                    {profileSettings.recommendedProducts && (
                      <a className='nav-link products' href='#products'>
                        Products
                      </a>
                    )}
                    {profileSettings.customLinks && (
                      <a className='nav-link links' href='#links'>
                        Links
                      </a>
                    )}
                  </div>
                </div>
              </ProfileNav>
              {(profileSettings.certifications ||
                profileSettings.creatorValues ||
                profileSettings.niches) && (
                <AreasOfInfluence id='influence'>
                  <div className='section-inner'>
                    {profileSettings.certifications?.length > 0 && (
                      <div className='group fade-in'>
                        <p className='label'>
                          <CheckCircleOutlined /> Certifications
                        </p>
                        <div className='tags'>
                          {profileSettings.certifications?.map(certification => (
                            <span className='tag' key={certification}>
                              {certification}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}
                    {profileSettings.creatorValues?.length > 0 && (
                      <div className='group fade-in'>
                        <p className='label'>
                          <HeartOutlined /> Values
                        </p>
                        <div className='tags'>
                          {profileSettings.creatorValues?.map(value => (
                            <span className='tag' key={value}>
                              {value}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}
                    {profileSettings.niches?.length > 0 && (
                      <div className='group fade-in'>
                        <p className='label'>
                          <StarOutlined /> Niche
                        </p>
                        <div className='tags'>
                          {profileSettings.niches?.map(niche => (
                            <span className='tag' key={niche}>
                              {niche}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </AreasOfInfluence>
              )}

              {selectedChannel && (
                <Channels id='channels'>
                  <div className='section-inner'>
                    <h2>
                      My Channels & Rates
                      <div className='border' />
                    </h2>
                    <div className='channel-info-wrapper'>
                      <div className='channel-card'>
                        <div className='channel-toggles'>
                          {profileSettings.socials?.map((social, key) => (
                            <button
                              key={key}
                              className={`toggle-btn ${
                                selectedChannel.platform === social.platform && 'selected'
                              }`}
                              onClick={() => setSelectedChannel(social)}>
                              <img
                                className='social-icon'
                                src={platformIcons[social.platform]}
                                alt={social.platform}
                              />
                            </button>
                          ))}
                        </div>
                        <div className='profile'>
                          <div className='profile-thumbnail'>
                            <ImageLoader src={selectedChannel.profilePicUrl} alt='' />
                          </div>
                          <a
                            className='handle'
                            href={selectedChannel.profileUrl}
                            target='_blank'
                            rel='noopener noreferrer'>
                            @{selectedChannel.username}
                          </a>
                        </div>
                        <div className='stats'>
                          <span className='stat followers'>
                            <p className='value'>
                              {selectedChannel.followerCount > 999
                                ? numeral(selectedChannel.followerCount).format('0.0a')
                                : selectedChannel.followerCount || 0}
                            </p>
                            <p className='label'>FOLLOWERS</p>
                          </span>
                          <span className='stat engagement'>
                            <p className='value'>{selectedChannel.engagement || 0}%</p>
                            <p className='label'>ENGAGEMENT</p>
                          </span>
                        </div>
                        <div className='bio'>
                          {selectedChannel.phylloData?.introduction
                            ? selectedChannel.phylloData?.introduction
                                .split('\n')
                                .map((line, key) => <p key={key}>{line}</p>)
                            : 'No bio.'}
                        </div>
                        <p className='date'>
                          Last Updated:{' '}
                          {selectedChannel.updated
                            ? moment(selectedChannel.updated).format('ll')
                            : 'Never'}
                        </p>
                      </div>
                      {profileSettings.rates?.[0]?.service &&
                        (profileSettings.rates?.[0]?.minPrice ||
                          profileSettings.rates?.[0]?.maxPrice) && (
                          <div className='rates'>
                            <div className='rate-cards'>
                              {profileSettings.rates.map(
                                (item, key) =>
                                  item.service &&
                                  (item.minPrice || item.maxPrice) && (
                                    <div className='rate-card fade-in' key={key}>
                                      <p className='service'>{item.service}</p>
                                      <p className='description'>{item.description}</p>
                                      <p className='price'>
                                        ${item.minPrice.toLocaleString()}
                                        {item.maxPrice && ` - $${item.maxPrice.toLocaleString()}`}
                                      </p>
                                    </div>
                                  )
                              )}
                            </div>
                          </div>
                        )}
                    </div>

                    <h2>
                      Content Highlights
                      <div className='border' />
                    </h2>
                    <div className='content'>
                      <ContentCarousel
                        example={example}
                        content={profileSettings.highlightedContent}
                        socials={profileSettings.socials}
                      />
                    </div>
                  </div>
                </Channels>
              )}

              {profileSettings.recommendedProducts?.length > 0 && (
                <RecommendedProducts id='products'>
                  <div className='section-inner'>
                    <h2>
                      Recommended Products <div className='border' />
                    </h2>
                    <div className='product-gallery'>
                      <div className='padding' />
                      {profileSettings.recommendedProducts.map((product, key) => (
                        <a
                          className='product-card'
                          key={key}
                          href={product.url}
                          target='_blank'
                          rel='noopener noreferrer'>
                          {product.previewUrl ? (
                            <div className='thumbnail'>
                              <ImageLoader src={product.previewUrl} alt='' />
                            </div>
                          ) : (
                            <div className='placeholder-thumbnail'>
                              <ImageLoader src={imagePlaceholder} alt='' />
                            </div>
                          )}
                          <div className='text'>
                            <p className={`title ${!product.title && 'no-info'}`}>
                              {product.title || 'Info unavailable.'}
                            </p>
                            <p className='description'>{product.description}</p>
                            <p className='sitename'>{product.siteName}</p>
                          </div>
                        </a>
                      ))}
                      <div className='padding' />
                    </div>
                  </div>
                </RecommendedProducts>
              )}

              {profileSettings.customLinks?.[0]?.title && profileSettings.customLinks[0]?.url && (
                <CustomLinks id='links'>
                  <div className='section-inner'>
                    <h2>
                      Links <div className='border' />
                    </h2>
                    <div className='links'>
                      {profileSettings.customLinks.map(
                        (link, key) =>
                          link.title &&
                          link.url && (
                            <a
                              className='link fade-in'
                              key={key}
                              href={link.url}
                              target='_blank'
                              rel='noopener noreferrer'>
                              {link.title}
                            </a>
                          )
                      )}
                    </div>
                  </div>
                </CustomLinks>
              )}

              <footer>
                <div className='footer-inner'>
                  <a className='logo' href='https://creator.co'>
                    <img src={theme.creatorcoLogo} alt='creatorco' />
                  </a>
                  <p>
                    Made with 💙 by <a href='https://creator.co'>Creator.co</a>
                  </p>
                </div>
              </footer>

              <FloatingActionButton
                onClick={() => scrollToTop()}
                className={isScrolled && 'visible'}
              />
            </div>
          </>
        ) : notFound ? (
          <div className='not-found'>
            <img className='logo' src={creatorcoLogo} alt='creatorco' />
            <Empty description='Profile not found.' />
            <a href='https://creator.co' className='cta'>
              <Button type='primary'>Go Home</Button>
            </a>
          </div>
        ) : (
          <div className='loading'>
            <LoadingOutlined spin />
          </div>
        )}
      </Wrapper>
    </ThemeProvider>
  )
}

const Wrapper = styled.main`
  background: ${props => props.theme.primaryColor300};
  color: ${props => props.theme.primaryTextColor};
  min-height: 100vh;
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  .not-found {
    background: #fff;
    max-width: 300px;
    display: grid;
    place-content: center;
    text-align: center;
    margin: auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: ${props => props.theme.crcoBoxShadow};
    .logo {
      height: 25px;
      margin: 0 auto 40px auto;
    }
    .cta {
      margin: 20px auto 0 auto;
    }
  }
  .loading {
    display: grid;
    place-content: center;
    margin: auto;
  }
  .theme-toggles {
    position: fixed;
    right: 0;
    z-index: 100;
  }
  h2,
  h3 {
    color: ${props => props.theme.primaryTextColor};
    text-align: left;
    margin-bottom: 30px;
  }
  h2 {
    font-size: 18px;
  }
  .border {
    height: 1px;
    background: ${props => props.theme.darkTransparency};
    margin-top: 10px;
  }
  p {
  }
  .sections {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  section {
    width: 100%;
    scroll-margin-top: 60px;
    .section-inner {
      max-width: 1200px;
      margin: auto;
    }
  }
  .fade-in {
    opacity: 0;
    transform: translateY(50%);
    transition: 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .fade-in.appear {
    opacity: 1;
    transform: translateY(0);
  }
  footer {
    background: ${props => props.theme.lightTransparency};
    padding: 10px 20px;
    .footer-inner {
      width: 100%;
      max-width: 1200px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      grid-gap: 20px;
    }
    .logo img {
      height: 20px;
    }
    p {
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 0.6rem;
      margin: 0;
      padding-top: 5px;
      text-align: right;
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    h2 {
      margin-bottom: 40px;
    }
    section {
      padding: 40px;
    }
    footer {
      .logo img {
        height: 25px;
      }
      p {
        font-size: 0.7rem;
      }
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
    section {
      padding: 80px 40px;
    }
  }
`

const Bio = styled.section`
  background: ${props => props.theme.primaryColor400};
  position: relative;
  .banner {
    background: ${props => props.theme.backgroundPattern};
    position: absolute;
    top: 20px;
    left: 20px;
    height: 160px;
    width: calc(100% - 40px);
    border-radius: 20px;
  }
  .section-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 80px 20px;
  }
  .outer-image-border {
    background: ${props => props.theme.primaryColor400};
    border-radius: 50%;
    height: 200px;
    width: 200px;
    padding: 15px;
  }
  .creator-image {
    height: 100%;
    width: 100%;
    background: linear-gradient(
      to bottom right,
      ${props => props.theme.primaryColor100},
      ${props => props.theme.accentColor} 80%
    );
    border-radius: 50%;
    padding: 5px;
    img {
      border-radius: 50%;
      border: 3px solid ${props => props.theme.primaryColor400};
    }
  }
  .creator-info {
    text-align: center;
    max-width: 600px;
  }
  .job-title {
    text-transform: uppercase;
    opacity: 0.5;
    margin: 0;
  }
  .name {
    font-family: 'Gilroy-ExtraBold', sans-serif;
    color: ${props => props.theme.primaryTextColor};
    font-size: 32px;
  }
  .personal-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 10px;
    font-family: 'Gilroy-ExtraBold', sans-serif;
  }
  .age {
    margin: 0;
  }
  .location {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin: 0;
  }
  .bio {
    max-width: 700px;
    margin: 20px 0;
    font-size: 15px;
    color: ${props => props.theme.secondaryTextColor};
    p {
      margin: 0;
    }
  }
  .email-btn {
    background: ${props => props.theme.primaryColor100};
    color: #fff;
    height: 44px;
    padding: 0 40px;
    border: 0;
    border-radius: 22px;
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }
  .social-links {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    grid-gap: 20px;
    a {
      .icon {
        height: 30px;
        transition: 0.2s ease-in-out;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    .banner {
      height: 240px;
    }
    .outer-image-border {
      width: 300px;
      height: 300px;
    }
    .creator-info {
      margin-top: 40px;
    }
    .job-title {
      font-size: 24px;
    }
    .name {
      font-size: 36px;
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
    .banner {
      max-width: 1200px;
      left: 50%;
      transform: translateX(-50%);
    }
    .section-inner {
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      gap: 40px;
      .outer-image-border {
        width: 400px;
        height: 400px;
        padding: 25px;
      }
      .creator-info {
        text-align: left;
        margin-top: 140px;
        flex: 1;
      }
      .creator-image img {
        border: 5px solid ${props => props.theme.primaryColor400};
      }
      .name {
        font-size: 54px;
        line-height: 1;
        margin-bottom: 20px;
      }
      .personal-info,
      .social-links {
        justify-content: flex-start;
      }
    }
  }
`
const ProfileNav = styled.nav`
  background: ${props => props.theme.lightTransparency};
  backdrop-filter: blur(10px);
  position: sticky;
  z-index: 10;
  top: -1px;
  margin-bottom: 40px;
  padding: 10px 20px;
  border-top: 1px solid ${props => props.theme.darkTransparency};
  .nav-inner {
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin: auto;
    gap: 10px;
    .bio-link {
      background: ${props => props.theme.primaryColor200};
      border: none;
      padding: 5px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
      .avatar {
        height: 30px;
        width: 30px;
        border-radius: 50%;
      }
      .name {
        display: none;
        margin: 0 10px;
      }
    }
    .nav-links {
      display: flex;
      align-items: center;
      font-size: 12px;
    }
    .bio-link,
    .nav-link {
      color: ${props => props.theme.primaryTextColor};
      transition: 0.2s ease-in-out;
      &.active {
        color: #fff;
        background: ${props => props.theme.primaryColor100};
      }
      @media (hover: hover) {
        &:hover {
          color: #fff;
          background: ${props => props.theme.primaryColor100};
        }
      }
    }
    .nav-link {
      border-radius: 20px;
      padding: 0 10px;
    }
    @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
      justify-content: space-between;
      .bio-link .name {
        display: block;
      }
      .nav-links {
        grid-gap: 10px;
        font-size: 1rem;
      }
    }
  }
`

const AreasOfInfluence = styled.section`
  padding: 40px 20px;
  .section-inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    grid-gap: 40px;
  }
  .group {
    background: ${props => props.theme.primaryColor400};
    flex: 1;
    padding: 40px 20px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    margin: auto;
    position: relative;
    .label {
      background: ${props => props.theme.primaryColor100};
      color: #fff;
      text-transform: uppercase;
      letter-spacing: 1px;
      padding: 5px 10px;
      border-radius: 4px;
      margin-bottom: 20px;
      position: absolute;
      top: -15px;
      .anticon {
        margin-right: 8px;
      }
    }
    .tags {
      display: flex;
      flex-wrap: wrap;
      grid-gap: 10px;
      .tag {
        padding: 5px 10px;
        background: ${props => props.theme.primaryColor200};
        color: ${props => props.theme.primaryTextColor};
        border-radius: 3px;
      }
    }
  }

  @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
    .section-inner {
      flex-direction: row;
      align-items: flex-start;
    }
    .group {
      margin: 0;
    }
  }
`

const Channels = styled.section`
  padding: 40px 20px;
  .channel-info-wrapper {
    margin: 20px auto 60px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .channel-card {
    background: ${props => props.theme.primaryColor400};
    width: 100%;
    max-width: 500px;
    border-radius: 24px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .channel-toggles {
      display: flex;
      grid-gap: 10px;
      border-bottom: 1px solid ${props => props.theme.darkTransparency};
      padding-bottom: 20px;
      button {
        background: transparent;
        border: 0;
        border-radius: 12px;
        margin: 0;
        padding: 10px;
        opacity: 0.5;
        transition: 0.2s ease-in-out;
        cursor: pointer;
        &:hover {
          opacity: 1;
        }
        .social-icon {
          height: 25px;
          width: 25px;
        }
        &.selected {
          background: ${props => props.theme.primaryColor200};
          opacity: 1;
        }
      }
    }
    .profile {
      display: flex;
      align-items: center;
      gap: 20px;
      .profile-thumbnail {
        height: 60px;
        width: 60px;
        border-radius: 12px;
        overflow: hidden;
      }
      .handle {
        background: ${props => props.theme.primaryColor200};
        color: ${props => props.theme.primaryColor100};
        padding: 3px 10px;
        border-radius: 4px;
        width: fit-content;
        transition: 0.2s ease-in-out;
        border: 1px solid transparent;
        display: flex;
        align-items: center;
        gap: 5px;
        &:hover {
          border: 1px solid ${props => props.theme.primaryColor100};
        }
      }
    }
    .stats {
      width: 100%;
      display: flex;
      .stat {
        flex: 1;
        &.engagement {
          text-align: right;
        }
        p {
          margin: 0;
        }
        .value {
          font-size: 18px;
          font-weight: bold;
        }
        .label {
          color: ${props => props.theme.primaryTextColor};
          text-transform: uppercase;
          letter-spacing: 1px;
          opacity: 0.5;
          font-size: 0.7rem;
        }
      }
    }
    .bio {
      color: ${props => props.theme.secondaryTextColor};
      font-size: 14px;
      p {
        margin: 0;
      }
    }
    .date {
      font-size: 10px;
      opacity: 0.3;
      text-align: right;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 40px;
  }
  .rates {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .rate-cards {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
    .rate-card {
      background: ${props => props.theme.primaryColor400};
      border-radius: 24px;
      padding: 20px;
      flex: 1;
      width: 100%;
      max-width: 500px;
      p {
        margin: 5px 0;
      }
      .service {
        color: ${props => props.theme.primaryTextColor};
        font-size: 1rem;
      }
      .price {
        color: ${props => props.theme.primaryColor100};
        font-size: 18px;
        white-space: nowrap;
      }
      .description {
        color: ${props => props.theme.secondaryTextColor};
        font-size: 13px;
      }
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    .channel-info-wrapper {
      flex-direction: row;
      align-items: flex-start;
      gap: 35px;
    }
    .channel-card {
      .profile {
        .profile-thumbnail {
          height: 75px;
          width: 75px;
        }
      }
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpointDesktopWide}) {
    .rate-cards {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
    }
  }
`
const RecommendedProducts = styled.section`
  padding: 40px 0;
  h2 {
    margin-left: 20px;
    margin-right: 20px;
  }
  .product-gallery {
    max-width: 100%;
    display: flex;
    gap: 20px;
    overflow: auto;
    ::-webkit-scrollbar {
      display: none;
    }
    .product-card {
      background: ${props => props.theme.primaryColor400};
      border-radius: 24px;
      overflow: hidden;
      min-width: 80%;
      max-width: 400px;
      display: flex;
      flex-direction: column;
      &:hover {
        .title {
          color: ${props => props.theme.primaryColor100};
        }
      }
      .placeholder-thumbnail {
        background: ${props => props.theme.primaryColor200};
        display: grid;
        place-content: center;
        height: 150px;
        img {
          opacity: 0.3;
          height: 50px;
          width: 50px;
        }
      }
      .thumbnail {
        height: 150px;
        overflow: hidden;
      }
      .text {
        flex: 1;
        padding: 20px;
        color: ${props => props.theme.primaryTextColor};
        display: flex;
        flex-direction: column;
      }
      .title {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: 0.2s ease-in-out;
        &.no-info {
          opacity: 0.5;
        }
      }
      .description {
        color: ${props => props.theme.secondaryTextColor};
        font-size: 14px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .sitename {
        margin-top: auto;
        font-size: 0.8rem;
      }
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    h2 {
      margin-left: 0;
      margin-right: 0;
    }
    .product-gallery {
      flex-wrap: wrap;
      grid-gap: 30px;
      justify-content: center;
      .padding {
        display: none;
      }
      .product-card {
        min-width: auto;
        width: 300px;
        .placeholder-thumbnail,
        .thumbnail {
          height: 200px;
        }
      }
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
    .product-gallery {
      grid-gap: 40px;
    }
  }
`

const CustomLinks = styled.section`
  padding: 40px 20px;
  .links {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    margin: auto;
    .link {
      background: ${props => props.theme.primaryColor100};
      color: #fff;
      padding: 10px 20px;
      border-radius: 20px;
      text-align: center;
      transition: 0.2s ease-in-out;
      &:hover {
        background: #000;
      }
    }
  }
`

const FloatingActionButton = styled.button`
  position: fixed;
  z-index: 50;
  bottom: 60px;
  right: 20px;
  height: 30px;
  width: 30px;
  background: ${props => props.theme.primaryColor300};
  box-shadow:
    0 0 5px ${props => props.theme.darkTransparency},
    ${props => props.theme.glassEffect};
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  border: 1px solid ${props => props.theme.lightTransparency};
  border-radius: 50%;
  display: grid;
  place-content: center;
  transition: 0.2s ease-in-out;
  visibility: hidden;
  opacity: 0;
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-style: solid;
    border-color: ${props => props.theme.primaryTextColor};
    border-width: 1px 1px 0 0;
    transform: rotate(-45deg);
    margin-top: 4px;
  }
  &.visible {
    visibility: visible;
    opacity: 1;
  }
`
