import { QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Drawer, Tabs } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components/macro'

import { CollaborationGuide } from './CollaborationGuide'
import { InvitesList } from './invites/InvitesList'
import { OptInsList } from './opt-ins/OptInsList'
import { useMetaData } from '../../../custom-hooks/useMetaData'

export const CampaignDashboard = () => {
  useMetaData(
    'My Campaigns | Creator Dashboard',
    'View and manage your collaborations.',
    'https://creatorco.nyc3.cdn.digitaloceanspaces.com/assets/preview-images/meta-preview.png'
  )

  const urlParams = new URLSearchParams(window.location.search)
  let tab = urlParams.get('tab')
  if (!tab) {
    tab = 'opt-ins'
    window.history.replaceState(null, '', `${window.location.pathname}?tab=opt-ins`)
  }
  const [activeKey, setActiveKey] = useState(tab)
  const [guideOpen, setGuideOpen] = useState(false)

  return (
    <Wrapper>
      <div className='tab-header-wrapper'>
        <div className='tab-header'>
          <div className='header-text'>
            <h1>My Campaigns</h1>
          </div>
          <Button type='link' icon={<QuestionCircleOutlined />} onClick={() => setGuideOpen(true)}>
            Help
          </Button>
          <Drawer
            title='Collaboration Guide'
            open={guideOpen}
            onClose={() => setGuideOpen(false)}
            width={window.innerWidth > 600 ? 500 : '100vw'}>
            <CollaborationGuide />
          </Drawer>
        </div>
      </div>

      <Tabs
        activeKey={activeKey}
        items={[
          { key: 'opt-ins', label: 'Opt-Ins', children: <OptInsList /> },
          {
            key: 'invites',
            label: 'Invites',
            children: <InvitesList />,
          },
        ]}
        onChange={key => {
          setActiveKey(key)
          // set tab in url (helps preserve location when navigating)
          window.history.replaceState(null, '', `${window.location.pathname}?tab=${key}`)
        }}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: #fff;

  .tab-header-wrapper {
    max-width: 1400px;
    padding: 20px;
    margin: 0 auto;
  }
  .tab-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    .header-text {
      display: flex;
      align-items: center;
      gap: 12px;
    }
    .platform-icon {
      margin-left: 10px;
      height: 20px;
    }
  }
  h1 {
    font-family: 'Gilroy-ExtraBold', sans-serif;
    font-size: 1.5rem;
    margin: 0;
  }

  .ant-tabs-nav {
    margin: 0;
    border-bottom: 1px solid #dbdbee;
  }
  .ant-tabs-nav-wrap {
    max-width: 1400px;
    margin: 0 auto -1px auto; // hide the bottom border
  }
  .ant-tabs {
    height: calc(100vh - 128px);
  }
  .ant-tabs-ink-bar {
    display: none; // hide active underline
  }
  .ant-tabs-tab {
    background: #dbdbee;
    color: ${props => props.theme.crcoMidnight};
    font-size: 16px;
    padding: 4px 18px;
    border-radius: 5px 5px 0 0;
    margin-left: 20px;
    cursor: pointer;
    transition: 0.2s ease-in-out !important;
    border: 1px solid transparent;
    border-bottom: none;
    &.ant-tabs-tab-active {
      background: #f4f4f8;
      border-color: #dbdbee;
      .ant-tabs-tab-btn {
        color: ${props => props.theme.crcoTechBlue};
      }
    }
  }
  .ant-tabs-content-holder {
    background: #f4f4f8;
    .ant-tabs-content {
      height: 100%;
      .ant-tabs-tabpane {
        height: 100%;
      }
    }
  }
`
