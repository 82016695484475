import moment from 'moment'

import bryceContent from '../../../../assets/images/stock-photos/bryce-content.jpg'
import bryceProduct1 from '../../../../assets/images/stock-photos/bryce-product1.png'
import bryceProduct2 from '../../../../assets/images/stock-photos/bryce-product2.jpg'
import bryceProduct3 from '../../../../assets/images/stock-photos/bryce-product3.webp'
import bryce1 from '../../../../assets/images/stock-photos/bryce1.png'
import bryce2 from '../../../../assets/images/stock-photos/bryce2.png'
import exampleContent1 from '../../../../assets/images/stock-photos/example-content1.jpg'
import exampleContent2 from '../../../../assets/images/stock-photos/example-content2.jpg'
import exampleContent3 from '../../../../assets/images/stock-photos/example-content3.jpg'
import exampleContent4 from '../../../../assets/images/stock-photos/example-content4.jpg'
import exampleContent5 from '../../../../assets/images/stock-photos/example-content5.jpg'
import exampleContent6 from '../../../../assets/images/stock-photos/example-content6.jpg'
import exampleProduct1 from '../../../../assets/images/stock-photos/example-product1.jpeg'
import exampleProduct2 from '../../../../assets/images/stock-photos/example-product2.jpeg'
import exampleProduct3 from '../../../../assets/images/stock-photos/example-product3.jpeg'
import tahiti1 from '../../../../assets/images/stock-photos/tahiti1.png'
import tahiti3 from '../../../../assets/images/stock-photos/tahiti3.png'
import tahiti4 from '../../../../assets/images/stock-photos/tahiti4.png'

export const exampleProfile = {
  theme: 'dreamyTheme',
  profileImg: tahiti4,
  name: 'Tahiti Spears',
  email: 'email@example.com',
  profession: 'Professional Photographer',
  bio: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec est orci, maximus in maximus in, fermentum lobortis nisi. Donec odio magna, scelerisque at lorem vel, rhoncus feugiat nibh. Integer consectetur mauris quis pretium ullamcorper.`,
  shippingRegion: 'California',
  shippingCountry: 'USA',
  birthDate: '1990-01-01T07:00:00.000Z',
  showLocation: true,
  showAge: true,
  certifications: ['Registered Nurse', 'Certified Aesthetician'],
  creatorValues: ['Eco-friendly', 'Zero Waste', 'Nature Advocate', 'Vegan'],
  niches: ['Healthy Lifestyle', 'Outdoors'],
  socials: [
    {
      platform: 'instagram',
      username: 'tahiti.spears',
      profileUrl: 'https://www.instagram.com',
      profilePicUrl: tahiti3,
      followerCount: 55000,
      engagement: 2.5,
      updated: '2022-07-26T15:53:19.911281',
      phylloData: {
        introduction:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      },
    },
    {
      platform: 'tiktok',
      username: 'spears.t',
      profileUrl: 'https://www.tiktok.com',
      profilePicUrl: tahiti1,
      followerCount: 1100000,
      engagement: 2,
      updated: '2022-07-26T15:53:19.911281',
      phylloData: {
        introduction:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      },
    },
    {
      platform: 'youtube',
      username: 'spearstube',
      profileUrl: 'https://www.youtube.com',
      profilePicUrl: tahiti4,
      followerCount: 30000,
      engagement: 1,
      updated: '2022-07-26T15:53:19.911281',
      phylloData: {
        introduction:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      },
    },
  ],
  rates: [
    {
      service: 'Story',
      minPrice: 20,
      maxPrice: 150,
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
    {
      service: 'Reel',
      minPrice: 100,
      maxPrice: 300,
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
    {
      service: 'Video',
      minPrice: 500,
      maxPrice: 2000,
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
    {
      service: 'Post',
      minPrice: 75,
      maxPrice: 350,
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
  ],
  highlightedContent: [
    {
      username: 'tahiti.spears',
      thumbnailUrl: exampleContent1,
      platform: 'instagram',
      posted: moment(),
      url: 'https://www.google.com',
      type: 'Post',
      title: `My dog Muffin loves @chippinpet treats so much - she won't eat anything else! 🐶 #TreatYourPet #ChippinPetPartner`,
      likes: Math.floor(Math.random() * 100000),
      comments: Math.floor(Math.random() * 1000),
    },
    {
      username: 'spears.t',
      thumbnailUrl: exampleContent3,
      platform: 'tiktok',
      posted: moment(),
      url: 'https://www.google.com',
      type: 'Post',
      title: `Upgrade your style with @leisara_purses. Can't get enough of the chic design and the roomy interior! 👜💫 #LeisaraCollab #FashionForward`,
      likes: Math.floor(Math.random() * 100000),
      comments: Math.floor(Math.random() * 1000),
    },
    {
      username: 'spears.t',
      thumbnailUrl: exampleContent4,
      platform: 'tiktok',
      posted: moment(),
      url: 'https://www.google.com',
      type: 'Post',
      title: `Feeling extra beautiful today thanks to @glossier. Their new makeup line is everything! 💄💖 #GlossierPartner #MakeupMagic`,
      likes: Math.floor(Math.random() * 100000),
      comments: Math.floor(Math.random() * 1000),
    },
    {
      username: 'tahiti.spears',
      thumbnailUrl: exampleContent2,
      platform: 'instagram',
      posted: moment(),
      url: 'https://www.google.com',
      type: 'Post',
      title: `Can’t keep my fur babies away from their new favorite treats! @tyleespets freeze-dried pet treats are a total hit in our house. 🐶💛 #TyleesPartner #PetTreats`,
      likes: Math.floor(Math.random() * 100000),
      comments: Math.floor(Math.random() * 1000),
    },
    {
      username: 'tahiti.spears',
      thumbnailUrl: exampleContent5,
      platform: 'instagram',
      posted: moment(),
      url: 'https://www.google.com',
      type: 'Post',
      title: `Stepping into the work week with confidence thanks to my wardrobe staples from @reitmans. Professional can be stylish too! 💼👠 #FashionCollab #WorkwearStyle`,
      likes: Math.floor(Math.random() * 100000),
      comments: Math.floor(Math.random() * 1000),
    },
    {
      username: 'tahiti.spears',
      thumbnailUrl: exampleContent6,
      platform: 'instagram',
      posted: moment(),
      url: 'https://www.google.com',
      type: 'Post',
      title: `Adding some festive cheer with this delicious cranberry cocktail from @wyderscider 🍹❄️🎄 #CranberryCocktails #HolidayCheers`,
      likes: Math.floor(Math.random() * 100000),
      comments: Math.floor(Math.random() * 1000),
    },
  ],
  recommendedProducts: [
    {
      url: 'https://www.apple.com/ca/ipad-air/',
      previewUrl: exampleProduct1,
      title: 'iPad Air',
      siteName: 'Apple',
      description:
        'The new iPad Air has an all-screen design, 10.9″ display, M1 chip, Center Stage; works with Apple Pencil and Magic Keyboard; comes in five colours.',
    },
    {
      url: 'https://www.apple.com/ca/iphone-14/',
      previewUrl: exampleProduct2,
      title: 'iPhone 14',
      siteName: 'Apple',
      description:
        'iPhone 14 features a new design, Super Retina XDR display, ProMotion, A16 Bionic chip, 5G, and advanced camera system with Cinematic mode.',
    },
    {
      url: 'https://www.apple.com/ca/apple-watch-series-8/',
      previewUrl: exampleProduct3,
      title: 'Apple Watch Series 8',
      siteName: 'Apple',
      description:
        'Apple Watch Series 8 features a larger, re-engineered Always-On Retina display, new watch faces, and advanced health features.',
    },
  ],
  customLinks: [
    {
      title: 'My Website',
      url: 'https://www.google.com',
    },
    {
      title: 'Content Portfolio',
      url: 'https://www.google.com',
    },
    {
      title: 'LinkedIn',
      url: 'https://www.linkedin.com',
    },
  ],
}

export const darkProfile = {
  ...exampleProfile,
  theme: 'darkTheme',
}

export const bryceProfile = {
  theme: 'baseballTheme',
  profileImg: bryce1,
  name: 'Bryce Harper',
  email: 'email@example.com',
  profession: 'MLB Player',
  bio: `Philadelphia Phillies
  Left-handed right fielder ⚾️`,
  shippingRegion: 'Philadelphia',
  shippingCountry: 'USA',
  birthDate: '1992-10-16T07:00:00.000Z',
  showLocation: true,
  showAge: true,
  certifications: ['MLB Player', 'Professional Athlete'],
  creatorValues: ['Family', 'Teamwork', 'Community'],
  niches: ['Sports', 'Baseball'],
  socials: [
    {
      platform: 'instagram',
      username: 'bryceharper3',
      profileUrl: 'https://www.instagram.com/bryceharper3/',
      profilePicUrl: bryce2,
      followerCount: 1700000,
      engagement: 3.8,
      updated: '2023-06-18T09:22:41.911281',
      phylloData: {
        introduction: `𝐓 𝐇 𝐑 𝐄 𝐄
          Athlete
          •God•KMH•Krew & Brooklyn•Phillies
          bit.ly/3Itmbbf`,
      },
    },
  ],
  rates: [
    {
      service: 'Signed Baseball',
      minPrice: 50,
      maxPrice: 200,
      description: 'Get a personally signed baseball by Bryce Harper.',
    },
    {
      service: 'Meet and Greet',
      minPrice: 1000,
      maxPrice: 5000,
      description: 'Have a one-on-one meeting with Bryce Harper.',
    },
    {
      service: 'Baseball Clinic',
      minPrice: 5000,
      maxPrice: 10000,
      description: 'Join a baseball clinic hosted by Bryce Harper.',
    },
    {
      service: 'Brand Endorsement',
      minPrice: 10000,
      maxPrice: 50000,
      description: 'Get Bryce Harper to endorse your brand or product.',
    },
  ],
  highlightedContent: Array.from(Array(6).keys()).map(() => ({
    username: 'bryceharper3',
    thumbnailUrl: bryceContent,
    platform: 'instagram',
    posted: moment(),
    url: 'https://www.instagram.com/p/CoIpe9MS9yC/',
    type: 'Post',
    title: `My phamily, my team, my vibe. It’s all repped in my Gatorade Gx Collaboration Bottle!🔥 Check it out here gatorade.com/bryce-harper`,
    likes: 1230000,
    comments: 12300,
    saves: 300,
    shares: 0,
    impressions: 2500,
    reach: null,
    views: 5400,
  })),
  recommendedProducts: [
    {
      url: 'https://www.gatorade.com/bryce-harper',
      previewUrl: bryceProduct1,
      title: 'Elena Delle Donne Gx Bottle w/ Gx Pods',
      siteName: 'Gatorade',
      description:
        'Elena’s individuality shines in her Gx Bottle, with a handwritten “Be You” message that comes to life when mixed with a colored Gx Pod.',
    },
    {
      url: 'https://www.google.com',
      previewUrl: bryceProduct2,
      title: 'Baseball Gloves',
      siteName: 'SportsApparel',
      description: 'These Bryce Harper signature gloves are designed for comfort and durability.',
    },
    {
      url: 'https://www.google.com',
      previewUrl: bryceProduct3,
      title: 'Baseball Cleats',
      siteName: 'SportsApparel',
      description:
        'Step up your game with these Bryce Harper signature cleats designed for speed and traction.',
    },
  ],
  customLinks: [
    {
      title: 'Official Website',
      url: 'https://www.bryceharper.com',
    },
    {
      title: 'Baseball Highlights',
      url: 'https://www.youtube.com/BryceHarperHighlights',
    },
    {
      title: 'Foundation',
      url: 'https://www.bryceharperfoundation.org',
    },
  ],
}
