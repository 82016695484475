import React, { useContext } from 'react'
import styled from 'styled-components/macro'

import { OptInContext } from '../../../../../contexts/OptInContext'

export const BRAND_SUBMIT_WEB_REDEMPTION_CODE = ({ campaignStep }) => {
  const { optIn } = useContext(OptInContext)
  const stepReached = campaignStep.placement <= optIn.currentStep?.placement

  return (
    <Wrapper className='programmed-step'>
      {campaignStep.optInStep?.completed ? (
        // TODO: display code (unique?)
        <p>The brand has submitted the web redemption code.</p>
      ) : (
        stepReached && <p>The brand needs to submit the web redemption code.</p>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div``
