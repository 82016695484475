import './App.less'
import './assets/index.css'
import './service/requests'
import LogRocket from 'logrocket'
import { useContext, useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import Cookies from 'universal-cookie'

import { ErrorFallBack } from './components/general/ErrorFallBack'
import Header from './components/layout/Header'
import { MobileMenu } from './components/layout/MobileMenu'
import AffiliateRedirect from './components/screens/AffiliateRedirect'
import Campaigns from './components/screens/campaignHub/Campaigns'
import OptInConfirmation from './components/screens/campaignHub/singleCampaign/OptInConfirmation'
import SingleCampaign from './components/screens/campaignHub/singleCampaign/SingleCampaign'
import { CreatorProfile } from './components/screens/dashboard/creator-profile/CreatorProfile'
import Dashboard from './components/screens/dashboard/Dashboard'
import { Login } from './components/screens/Login'
import { Logout } from './components/screens/Logout'
import { NotFound } from './components/screens/NotFound'
import { Signup } from './components/screens/onboarding/Signup'
import { Welcome } from './components/screens/onboarding/Welcome'
import { CHARGEBEE_SITE } from './constants'
import { UserContext } from './contexts/UserContext'
import { theme } from './GlobalStyles'

export const App = () => {
  const cookies = new Cookies()
  let subdomain
  const appDomain = process.env.REACT_APP_DOMAIN_NAME.split('.')[0]
  const host = window.location.host
  const details = window.navigator.userAgent
  const isMobile = /Android|webOS|iPhone|iPad/i.test(details)
  const arr = host
    .split('.')
    .slice(
      0,
      host.includes('localhost')
        ? -1
        : (process.env.REACT_APP_CP_DOMAIN_NAME.split('.').length - 1) * -1
    )
  if (arr.length > 0) subdomain = arr[0]

  const { isLoggedIn } = useContext(UserContext)

  const dashboardRoutes = [
    '/my-dashboard',
    '/my-campaigns',
    '/my-campaigns/:id',
    '/my-wishlist',
    '/affiliate',
    '/affiliate/:tab',
    '/my-settings',
    '/my-profile',
    '/creator-profile-setup',
  ]

  useEffect(() => {
    window.Chargebee.init({
      site: CHARGEBEE_SITE,
      iframeOnly: isMobile ? true : false,
    })
    window.civchat = {
      apiKey: 'GnXIMO',
    }
  }, [isMobile])

  useEffect(() => {
    // for bypassing opt-in requirements (need to capture at high level in case user needs to sign up first)
    const urlParams = new URLSearchParams(window.location.search)
    const inviteCode = urlParams.get('inviteCode')

    if (inviteCode) localStorage.setItem('inviteCode', inviteCode)
  }, [])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const debug = urlParams.get('debug')
    if (debug == 'true') {
      window.logRocketInitialized = true
      LogRocket.init('zbp1lp/creator-co', {
        requestSanitizer: request => {
          if (request.url.toLowerCase().indexOf('/login') !== -1) request.body.password = null
          return request
        },
      })
    }
  }, [])

  let params = new URL(document.location).searchParams
  if (params.get('utm_campaign'))
    cookies.set('utm_campaign', params.get('utm_campaign'), {
      path: '/',
      maxAge: 2592000,
      // domain: '.creator.co',
    })

  if (params.get('utm_medium'))
    cookies.set('utm_medium', params.get('utm_medium'), {
      path: '/',
      maxAge: 2592000,
      // domain: '.creator.co',
    })

  if (params.get('utm_source'))
    cookies.set('utm_source', params.get('utm_source'), {
      path: '/',
      maxAge: 2592000,
      // domain: '.creator.co',
    })

  if (params.get('utm_content'))
    cookies.set('utm_content', params.get('utm_content'), {
      path: '/',
      maxAge: 2592000,
      // domain: '.creator.co',
    })

  if (params.get('utm_term'))
    cookies.set('utm_term', params.get('utm_term'), {
      path: '/',
      maxAge: 2592000,
      // domain: '.creator.co',
    })

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ErrorBoundary fallback={<ErrorFallBack />} showDialog={true}>
          {!subdomain ||
          appDomain === subdomain ||
          subdomain.includes('creatorco-creators') ||
          !isNaN(subdomain) ? (
            <>
              {/* uncomment error below to test fallback UI */}
              {/* {new Error('Test')} */}
              <Header />
              <MobileMenu />
              <Switch>
                <Route path='/signup'>
                  <Signup />
                </Route>
                <Route path='/welcome'>
                  <Welcome />
                </Route>
                <Route path='/login'>
                  <Login />
                </Route>
                <Route path='/logout'>
                  <Logout />
                </Route>
                <Route path='/collaboration-hub'>
                  <Campaigns />
                </Route>
                <Route path='/campaign/:slug'>
                  <SingleCampaign />
                </Route>
                <Route path='/campaigns/:slug'>
                  <SingleCampaign />
                </Route>
                <Route path='/affiliate-redirect/:id'>
                  <AffiliateRedirect />
                </Route>
                {isLoggedIn ? (
                  <>
                    <Route exact path={dashboardRoutes}>
                      <Dashboard />
                    </Route>
                    <Route exact path='/'>
                      <Redirect to='/my-dashboard' />
                    </Route>
                    <Route path='/opt-in-confirmation/:id'>
                      <OptInConfirmation />
                    </Route>
                  </>
                ) : (
                  // redirect to login if trying to access dashboard routes without token
                  <Route exact path={['/', ...dashboardRoutes]}>
                    <Redirect to='/login' />
                  </Route>
                )}
                {/* FIXME: everything works well except for this, it won't show up for not found routes (low priority) */}
                <Route>
                  <NotFound />
                </Route>
              </Switch>
            </>
          ) : (
            <CreatorProfile subdomain={subdomain} />
          )}
        </ErrorBoundary>
      </Router>
      <ReactQueryDevtools />
    </ThemeProvider>
  )
}
