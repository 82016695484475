import { Button, Empty } from 'antd'
import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'

import { InvitesListItem } from './InvitesListItem'
import { UserContext } from '../../../../contexts/UserContext'
import { GhostOptInListItem } from '../opt-ins/GhostOptInListItem'
import { Wrapper } from '../opt-ins/OptInsList'

export const InvitesList = () => {
  const { fetchInvites } = useContext(UserContext)

  const { data: invites, status } = useQuery('invites', fetchInvites)

  return (
    <Wrapper>
      <div className='wrapper-inner'>
        {status === 'success' &&
          (invites?.length ? (
            <section className='list'>
              {invites.map((item, index) => (
                <InvitesListItem invite={item} key={index} />
              ))}
            </section>
          ) : (
            <section className='empty'>
              <Empty description={`You have no collaboration invites.`} />
              <Link to='/collaboration-hub' className='cta'>
                <Button type='primary'>Explore Campaigns</Button>
              </Link>
            </section>
          ))}

        {status === 'loading' && (
          <section className='list'>
            {Array.from(Array(3).keys()).map(item => (
              <GhostOptInListItem key={item} />
            ))}
          </section>
        )}
      </div>
    </Wrapper>
  )
}
