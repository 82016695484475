// Helper function to check if url comes from a video
export const isVideo = url => {
  try {
    const ext = new URL(url)?.pathname?.toLowerCase()
    return ext?.endsWith('mp4') || ext?.endsWith('mov')
  } catch {
    return false
  }
}

export const findProfile = (profiles, platform) =>
  profiles?.find(profile => profile.platform === platform)

export const getAverageRating = rating => {
  const averageRating =
    ((rating.content || 0) +
      (rating.creativity || 0) +
      (rating.communication || 0) +
      (rating.overall || 0)) /
    4

  return Number(averageRating.toFixed(2))
}

// Extract from a URL the file name
export const extractFileNameFromUrl = url => {
  try {
    return new URL(url)?.pathname.split('/').pop()
  } catch (e) {
    return url
  }
}

export const getOptInAffiliateDomain = (optInId, domains) => {
  if (process.env.NODE_ENV !== 'production') return process.env.REACT_APP_AFFILIATE_API_URL

  // Map hash to an index in the domains array
  const domainIndex = optInId % domains.length

  return `https://affiliate.${domains[domainIndex]}`
}
