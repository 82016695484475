import { CaretRightOutlined, LoadingOutlined } from '@ant-design/icons'
import { Collapse } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components/macro'

import { BRAND_ACTIVATE_INFLUENCER } from './steps/BRAND_ACTIVATE_INFLUENCER'
import { BRAND_REVIEW_CONTENT_PREVIEW } from './steps/BRAND_REVIEW_CONTENT_PREVIEW'
import { BRAND_SCORE_SHOUTOUT } from './steps/BRAND_SCORE_SHOUTOUT'
import { BRAND_SUBMIT_GIVEAWAY_SHIPMENT_TRACKING_LINK } from './steps/BRAND_SUBMIT_GIVEAWAY_SHIPMENT_TRACKING_LINK'
import { BRAND_SUBMIT_SHIPMENT_TRACKING_LINK } from './steps/BRAND_SUBMIT_SHIPMENT_TRACKING_LINK'
import { BRAND_SUBMIT_WEB_REDEMPTION_CODE } from './steps/BRAND_SUBMIT_WEB_REDEMPTION_CODE'
import { BRAND_SUBMIT_WEB_REDEMPTION_CODE_2 } from './steps/BRAND_SUBMIT_WEB_REDEMPTION_CODE_2'
import { CreatorSubmitSocialPostStep } from './steps/CreatorSubmitSocialPostStep'
import { CustomStep } from './steps/CustomStep'
import { GiveawayWinnerDetailsStep } from './steps/GiveawayWinnerDetailsStep'
import { INFLUENCER_SUBMIT_CONTENT_PREVIEW } from './steps/INFLUENCER_SUBMIT_CONTENT_PREVIEW'
import { API_URL } from '../../../../constants'
import { OptInContext } from '../../../../contexts/OptInContext'
const { Panel } = Collapse

export const Steps = () => {
  const { optIn } = useContext(OptInContext)

  const { data: campaignSteps, status: campaignStepsStatus } = useQuery(
    ['campaignSteps', optIn.id],
    async () => {
      const { data } = await axios.get(`${API_URL}/opt-in/${optIn.id}/steps`)
      return data
    }
  )

  return (
    <Wrapper>
      <h2 className='title'>
        <span className='campaign'>{optIn.campaign.title}</span>
      </h2>
      {campaignStepsStatus === 'success' && (
        <Collapse
          className='steps'
          bordered={false}
          defaultActiveKey={[`${optIn.currentStep?.placement || 0}`]}
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
          {campaignSteps.map(step => {
            return (
              <Panel
                key={step.placement}
                className={`step ${
                  step.placement < optIn.currentStep?.placement
                    ? 'completed'
                    : step.placement > optIn.currentStep?.placement
                      ? 'not-ready'
                      : 'current'
                }`}
                header={
                  <span className='title'>
                    <b>Step {step.placement}:</b>{' '}
                    {step.step?.title.replace('Old: ', '') || step.customStepTitle}
                  </span>
                }>
                {step.optInStep?.completed && (
                  <>
                    <span className='status completed'>Completed</span>
                    <div className='date'>
                      {moment(step.optInStep?.completed).format('MMM D YYYY, h:mm a')}
                    </div>
                  </>
                )}
                {step.placement === optIn.currentStep?.placement &&
                  (step.actionee === 'creator' ? (
                    <span className='status current creator'>Action required</span>
                  ) : (
                    <span className='status current brand'>Waiting on brand</span>
                  ))}
                {step.placement > optIn.currentStep?.placement && (
                  <span className='status not-ready'>Previous step incomplete</span>
                )}
                <div className='actions'>
                  {step.stepId ? (
                    <ProgrammedStep campaignStep={step} />
                  ) : (
                    <CustomStep campaignStep={step} />
                  )}
                </div>
              </Panel>
            )
          })}
        </Collapse>
      )}
      {campaignStepsStatus === 'loading' && <LoadingOutlined spin />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .title {
    .campaign {
      color: ${props => props.theme.crcoTechBlue};
    }
  }

  .steps {
    background: ${props => props.theme.crcoOffWhite};
    border-radius: 5px;
    .step {
      &:last-child {
        border: 0;
      }
      &.current {
        .ant-collapse-header {
          .anticon {
            color: ${props => props.theme.crcoTechBlue};
          }
          .title {
            color: ${props => props.theme.crcoTechBlue};
          }
        }
      }
      &.not-ready {
        opacity: 0.5;
      }
      .ant-collapse-header {
        .title {
          margin: 0;
        }
      }
      .status {
        color: #fff;
        border-radius: 10px;
        display: block;
        text-align: center;
        padding: 2px 10px 0 10px;
        width: 220px;
        font-size: 0.7rem;
        margin: 0 auto;
        text-transform: uppercase;
        .anticon {
          margin-right: 5px;
        }
        &.completed {
          background: ${props => props.theme.crcoLettuce};
        }
        &.creator {
          background: ${props => props.theme.crcoCoral};
        }
        &.brand {
          background: #999;
        }
        &.not-ready {
          background: #ddd;
          color: #666;
        }
      }
      .date {
        color: #aaa;
        text-align: center;
        font-size: 0.8rem;
        margin-top: 5px;
      }
      .actions {
        padding: 10px 0;
        .programmed-step,
        .custom-step {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: left;
          .step-status {
            margin: 0;
            max-width: 300px;
          }
          form {
            margin: 10px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
          }
        }
      }
    }
  }
`

const ProgrammedStep = ({ campaignStep }) => {
  switch (campaignStep?.step?.slug) {
    case 'creator-submit-social-post-url':
      return <CreatorSubmitSocialPostStep campaignStep={campaignStep} />
    case 'creator-provide-giveaway-winner-details':
      return <GiveawayWinnerDetailsStep campaignStep={campaignStep} />
    // TODO: not set up or being used yet
    // case 'content-preview':
    //   return (
    //     <ContentPreviewStep
    //       optIn={optIn}
    //       step={step}
    //       optInStep={optInStep}
    //       completeStep={completeStep}
    //     />
    //   )
    // deprecated steps are capitalized to be distinct from other steps as they will eventually be removed
    case 'deprecated-brand-activate-influencer':
      return <BRAND_ACTIVATE_INFLUENCER campaignStep={campaignStep} />
    case 'deprecated-influencer-submit-content-preview':
      return <INFLUENCER_SUBMIT_CONTENT_PREVIEW campaignStep={campaignStep} />
    case 'deprecated-brand-review-content-preview':
      return <BRAND_REVIEW_CONTENT_PREVIEW campaignStep={campaignStep} />
    case 'deprecated-brand-score-shoutout':
      return <BRAND_SCORE_SHOUTOUT campaignStep={campaignStep} />
    case 'deprecated-brand-submit-giveaway-shipment-tracking-link':
      return <BRAND_SUBMIT_GIVEAWAY_SHIPMENT_TRACKING_LINK campaignStep={campaignStep} />
    case 'deprecated-brand-submit-shipment-tracking-link':
      return <BRAND_SUBMIT_SHIPMENT_TRACKING_LINK campaignStep={campaignStep} />
    case 'deprecated-brand-submit-web-redemption-code':
      return <BRAND_SUBMIT_WEB_REDEMPTION_CODE campaignStep={campaignStep} />
    case 'deprecated-brand-submit-web-redemption-code-2':
      return <BRAND_SUBMIT_WEB_REDEMPTION_CODE_2 campaignStep={campaignStep} />
    case 'deprecated-brand-submit-unique-promotion-code':
      return <BRAND_SUBMIT_WEB_REDEMPTION_CODE unique campaignStep={campaignStep} />
    default:
      return ''
  }
}
