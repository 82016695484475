import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { UserContext } from '../../contexts/UserContext'

export const Logout = () => {
  const history = useHistory()
  const { logoutHandler } = useContext(UserContext)

  useEffect(() => {
    logoutHandler()
    history.push('/login')
  }, [logoutHandler, history])

  return <></>
}
