import { Button, DatePicker, Empty } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useState } from 'react'
import { useInfiniteQuery } from 'react-query'
import styled from 'styled-components'

import ImpactAffiliateEvent from './ImpactAffiliateEvent'
import { API_URL } from '../../../../constants'
import LoadingHeart from '../../../general/LoadingHeart'

const AffiliateImpact = () => {
  const sixtyDaysAgo = new Date().getTime() - 1000 * 60 * 60 * 24 * 60
  const [fromDate, setFromDate] = useState(moment(sixtyDaysAgo))
  const [toDate, setToDate] = useState(moment(new Date()))

  const { data, status, fetchNextPage, isFetchingNextPage, hasNextPage } = useInfiniteQuery(
    'impact-affiliate',
    async ({ pageParam = 1 }) => {
      const { data } = await axios.get(
        `${API_URL}/creator/affiliate/impact-metrics/${pageParam}/`,
        {
          params: {
            fromDate: fromDate ? fromDate.toDate().getTime() : null,
            toDate: toDate ? toDate.toDate().getTime() : null,
          },
        }
      )
      return data
    },
    {
      getNextPageParam: lastPage => lastPage.nextPage,
    }
  )

  return (
    <Wrapper>
      <div className='inner'>
        <div className='inner-content'>
          <div className='filters-box'>
            <div className='filters'>
              <DatePicker
                placeholder='From Date'
                allowClear={true}
                value={fromDate}
                onChange={date => setFromDate(date ? date : null)}
              />
              <DatePicker
                placeholder='To Date'
                allowClear={true}
                value={toDate}
                onChange={date => setToDate(date ? date : null)}
              />
            </div>
          </div>
          {status === 'success' && (
            <div className='events'>
              {data.pages[0]?.totalResults ? (
                <>
                  <div className='events-header'>
                    <div className='brand'>Brand</div>
                    <div className='amount'>Sale Amount</div>
                    <div className='commission'>Commission</div>
                    <div className='date'>Date</div>
                  </div>
                  <div className='events-list'>
                    {data.pages.map(page =>
                      page.results.map(event => (
                        <div className='event' key={event.id}>
                          <ImpactAffiliateEvent event={event} />
                        </div>
                      ))
                    )}
                  </div>
                  {hasNextPage && (
                    <div className='load-more'>
                      <Button
                        onClick={() => fetchNextPage()}
                        disabled={isFetchingNextPage}
                        type='link'
                        loading={isFetchingNextPage}>
                        {'Load more'}
                      </Button>
                    </div>
                  )}
                </>
              ) : (
                <div className='empty'>
                  <Empty description='No affiliate activity found.' />
                </div>
              )}
            </div>
          )}

          {status === 'loading' && (
            <div style={{ height: '100%', display: 'grid', placeItems: 'center' }}>
              <LoadingHeart />
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  color: black;
  width: 90vw;
  max-width: 480px;
  margin: 0 auto;

  @media only screen and (min-width: ${props => props.theme.breakpointMobile}) {
    width: 100%;
    max-width: 1200px;
  }

  .inner {
    height: 100%;
  }

  .inner-content {
    background-color: #fff;
    padding: 20px 0px;
    border-radius: 10px;
    margin: 0 auto;

    .filters-box {
      background-color: #fff;
      padding: 0 20px 15px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 20px;
      margin-bottom: 24px;
      box-shadow: 0px 5px 10px -10px #000000ab;
      z-index: 1;

      .filters {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
      }
    }

    .events {
      border: 1px solid #e6e6e6;
      border-radius: 10px;
      padding: 10px;
      background-color: #f2f2f2;
      margin: 0 15px;

      .events-header {
        display: none;
        font-size: 1.1rem;
        margin-bottom: 12px;
        margin-right: 12px;
        padding-left: 12px;
        padding-right: 12px;

        .brand,
        .date,
        .amount,
        .commission {
          font-size: 1.1rem;
          text-align: center;
          flex: 1;
        }

        .date {
          flex: 2;
        }
      }

      .load-more {
        display: flex;
        justify-content: center;
        margin-top: 12px;
      }

      @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
        .events-header {
          display: flex;
        }
      }
    }

    .events-list {
      max-height: 48vh;
      overflow-y: scroll;
      ${props => props.theme.scrollbar}

      .event {
        display: flex;
        padding: 15px 12px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-items: flex-start;
        align-items: flex-start;

        .event-metrics {
          border-top: 1px solid lightgray;
          padding-top: 5px;
          display: flex;
          flex-direction: column;
          flex: 5;
          align-items: baseline;
          flex-wrap: wrap;
          gap: 10px;
          width: 100%;
          margin-top: 5px;
          justify-content: center;

          .date,
          .brand,
          .amount,
          .commission {
            flex: 1;
            font-size: 12px;
            text-align: center;
            display: flex;
            gap: 3px;
          }

          .date {
            flex: 2;
          }
        }

        &:not(:last-child) {
          border-bottom: 1px solid lightgray;
        }
      }
    }

    @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
      .affiliates {
        padding: 10px 10px;
        margin: 0 auto;
      }
    }

    @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
      .events {
        .events-list {
          .event {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: baseline;

            .event-metrics {
              border-top: 0px;
              gap: 0;
              flex-direction: row;

              .date,
              .brand,
              .amount,
              .commission {
                text-align: center;
                font-size: 1.1rem;
                flex: 1;
                display: block;
              }

              .date {
                flex: 2;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    .inner-content {
      padding: 20px;
    }
  }
`

export default AffiliateImpact
