import styled from 'styled-components/macro'

export const CollaborationGuide = () => {
  return (
    <Wrapper>
      <h1>How to: Collaborations</h1>

      <p>
        Welcome to our platform! We&apos;re excited to help you navigate through your campaigns and
        make the most of your collaborations with brands. Here&apos;s a brief guide to help you
        progress smoothly:
      </p>

      <h2>1. Opting In:</h2>
      <ul>
        <li>Browse through the available campaigns in our Collaboration Hub.</li>
        <li>
          Find the ones that align with your profile, and opt-in if you&apos;re interested in
          participating.
        </li>
        <li>
          When opting-in, we may require additional information from you depending on the campaign
          requirements. This may include:
          <ul>
            <li>Social media account connection</li>
            <li>Demographic details such as age and gender</li>
            <li>Shipping address to receive products</li>
            <li>Verified phone number for SMS notifications & communication purposes</li>
          </ul>
        </li>
        <li>
          Note that brands will review your profile before accepting or declining your
          participation.
        </li>
      </ul>

      <h2>2. Getting Accepted</h2>
      <ul>
        <li>Once a brand accepts your opt-in request, you&apos;re in!</li>
        <li>You&apos;ll receive a notification or email confirming your activation.</li>
        <li>
          Keep an eye out for any additional instructions or steps from the brand or support team
          via chat message or SMS.
        </li>
      </ul>

      <h2>3. Campaign Steps</h2>
      <ul>
        <li>Each campaign may have different steps, so pay attention to the details.</li>
        <li>
          Some common steps include:
          <ul>
            <li>
              Profile Review: The brand will activate or decline you as a creator for the campaign.
            </li>
            <li>
              Product Shipment: The brand will send you any products required for you to create
              content.
            </li>
            <li>
              ShoutOut or Post Submission: Create and submit your shoutout or post link as
              instructed.
            </li>
            <li>
              Shoutout Review: The brand will review your submission and provide feedback if
              necessary.
            </li>
            <li>
              Rating: At the end of your collaboration, the brand will submit a rating based on your
              content quality, creativity, communication, and their overall satisfaction.
            </li>
            <li>
              Payment (if applicable): If the campaign involves payment, it will be processed upon
              successful completion. Make sure to add your payout method in your settings.
            </li>
          </ul>
        </li>
      </ul>

      <h2>4. Earning Good Ratings</h2>
      <ul>
        <li>
          Your collaboration rating history is visible to brands when reviewing your opt-in request,
          so aim for a good rating on all your campaigns.
        </li>
        <li>Provide high-quality content that aligns with the brand&apos;s expectations.</li>
        <li>Follow the campaign guidelines and make sure your shoutouts meet the requirements.</li>
        <li>
          Communicate effectively with the brand and our support team to address any questions or
          concerns.
        </li>
      </ul>

      <p>
        Remember, each campaign is a valuable opportunity to showcase your creativity and build
        strong relationships with brands. If you have any questions or need assistance, our support
        team is here to help. Happy collaborating!
      </p>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  color: #777e90;
  h1,
  h2 {
    font-family: 'Poppins-Bold', sans-serif;
    color: #23262f;
  }
  h1 {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  h2 {
    font-size: 1.1rem;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 20px;
  }
  p {
    font-size: 0.9rem;
    margin-bottom: 20px;
  }
  ul {
    margin: 0 0 20px 0;
    padding: 0;
    li {
      margin: 10px;
    }
  }
`
