import {
  CloseCircleOutlined,
  CheckCircleOutlined,
  PlusOutlined,
  MinusOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import { Alert, Button, message } from 'antd'
import axios from 'axios'
import React, { useEffect, useState, useContext } from 'react'
import { CircleFlag } from 'react-circle-flags'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { OptInModal } from './OptInModal'
import globe from '../../../../assets/images/icons/globe.svg'
import instagram from '../../../../assets/images/icons/social/instagram-color-square.svg'
import tiktok from '../../../../assets/images/icons/social/tiktok-color-square.svg'
import youtube from '../../../../assets/images/icons/social/youtube-color-square.svg'
import { API_URL } from '../../../../constants'
import { UserContext } from '../../../../contexts/UserContext'
import { findProfile } from '../../../../utils'
const socialIcons = { instagram, youtube, tiktok }

export const OptInQualifications = ({ campaign, userData }) => {
  const { fetchCurrentUser, optInCreator } = useContext(UserContext)
  const history = useHistory()
  // #region State
  const [genders, setGenders] = useState(undefined)
  const [categories, setCategories] = useState('')
  const [pinned, setPinned] = useState(undefined)
  const [optInId, setOptInId] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [selectedVariables, setSelectedVariables] = useState({})
  const [selectedProduct, setSelectedProduct] = useState(undefined)
  const [selectedChannels, setSelectedChannels] = useState([])
  const [socialConnects, setSocialConnects] = useState([])
  const [optInOpen, setOptInOpen] = useState(false)
  const [optInLoading, setOptInLoading] = useState(false)
  const [notEligibleData, setNotEligibleData] = useState([])
  // #endregion State

  // #region Effects
  useEffect(() => {
    const optIn = userData?.optIns?.find(optIn => optIn.campaignId === campaign.id)
    if (optIn) {
      setOptInId(optIn.id)
    }
  }, [campaign, userData])

  const inviteCode = localStorage?.getItem('inviteCode')

  useEffect(() => {
    setPinned(userData?.campaignPins?.find(pin => pin.campaignId === campaign.id)?.id)
  }, [userData, campaign])

  useEffect(() => {
    setGenders(
      !!campaign.metaData?.genders?.[0]?.length &&
        campaign.metaData.genders
          .map(gender => gender[0].toUpperCase() + gender.substring(1))
          .join(' • ')
    )
  }, [campaign])

  useEffect(() => {
    setCategories(
      campaign.categories.length > 0 ? (
        campaign.categories.map((item, i) => (
          <span
            key={i}
            className={`niche ${
              userData?.creatorProfile?.niches.find(
                niche => niche.category.id === item.category.id
              ) && 'match'
            }`}>
            {item.category.title}
          </span>
        ))
      ) : (
        <span className='niche match'>Any niche</span>
      )
    )
  }, [campaign, userData?.creatorProfile?.niches])
  // #endregion Effects

  // #region Eligibility Checks
  // calculate user age by given date of birth
  const calculateAge = dob => {
    if (!dob) {
      return null
    } else {
      const today = new Date()
      const birthDate = new Date(dob)
      let age = today.getFullYear() - birthDate.getFullYear()
      const m = today.getMonth() - birthDate.getMonth()
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age = age - 1
      }
      return age
    }
  }

  const userAge = calculateAge(userData?.creatorProfile?.birthDate)
  const userResidence = userData?.creatorProfile?.shippingCountry

  const followerChecks = {}
  const engagementChecks = {}

  campaign?.socialChannels?.forEach(channel => {
    const socialProfile = findProfile(userData?.socialProfiles, channel)
    const followers = socialProfile?.followerCount
    const engagement = socialProfile?.engagement

    const followerEligible =
      !campaign?.[`${channel}Reach`] || followers >= campaign?.[`${channel}Reach`]
    const engagementEligible =
      !campaign?.[`${channel}Engagement`] || engagement >= campaign?.[`${channel}Engagement`]

    followerChecks[channel] = followerEligible
    engagementChecks[channel] = engagementEligible
  })

  const ageCheck =
    userAge &&
    (userAge >= campaign?.minAge || (!campaign?.minAge && userAge >= 13)) &&
    (userAge <= campaign?.maxAge || !campaign?.maxAge)

  const residenceCheck =
    !campaign?.countries.length ||
    campaign?.countries.find(el => el.country.countryCode === userResidence)

  const nicheCheck =
    !campaign?.categories.length ||
    !!userData?.creatorProfile?.niches?.find(niche =>
      campaign?.categories.find(cat => cat.category.id === niche.category.id)
    )

  const genderCheck =
    !campaign?.metaData?.genders?.[0]?.length ||
    campaign?.metaData?.genders.includes(
      userData?.creatorProfile?.gender || ('female' && 'male' && 'non-binary')
    )
  // #endregion Eligibility Checks

  // #region Handlers
  const handleWishlist = async () => {
    if (!userData) return
    setLoading(true)
    if (pinned) {
      // delete pin (remove campaign from wishlist)
      await axios.delete(`${API_URL}/creator/campaign-pin/${pinned}`).then(() => setPinned(false))
    } else {
      // create pin (add campaign to wishlist)
      await axios
        .post(`${API_URL}/creator/campaign-pin`, {
          campaignId: campaign.id,
        })
        .then(({ data }) => {
          setPinned(data.campaignPinId)
        })
    }
    setLoading(false)
  }

  const handleOptIn = async () => {
    setOptInOpen(false)

    if (!userData) {
      setOptInOpen('login')
      return
    }

    if (
      !!campaign.variables?.length &&
      Object.keys(selectedVariables).length !== campaign.variables.length
    ) {
      setOptInOpen('variables')
      return
    }

    if (!!campaign.productListId && !selectedProduct) {
      setOptInOpen('product')
      return
    }

    // Multi-channel campaign, user must select at least one channel to participate in
    if (campaign?.socialChannels?.length > 1 && !selectedChannels.length) {
      setOptInOpen('channels')
      return
    }

    setOptInLoading(true)

    // Fetching the most up to date user data
    const userInfo = await fetchCurrentUser()

    // User must connect socials for all channels they selected
    if (campaign?.socialChannels?.length > 1 && selectedChannels.length) {
      const unconnected = selectedChannels.filter(
        channel => !userInfo.socialProfiles.find(p => p.platform === channel)
      )
      if (unconnected.length) {
        setSocialConnects(unconnected)
        setOptInOpen('social-connect')
        setOptInLoading(false)
        return
      }
    }

    // Single channel campaign, user must connect social for that channel
    if (
      campaign?.socialChannels?.length === 1 &&
      !userInfo.socialProfiles.find(p => p.platform === campaign.socialChannels[0])
    ) {
      setSocialConnects([campaign.socialChannels[0]])
      setOptInOpen('social-connect')
      setOptInLoading(false)
      return
    }

    // User must have a verified phone number
    if (!userInfo.extraData?.twilioVerified) {
      setOptInOpen('phone')
      setOptInLoading(false)
      return
    }

    // User must provide additional profile info to check eligibility
    if (
      !userInfo.creatorProfile?.birthDate || // must be at least 13 or match minAge/maxAge
      !userInfo.creatorProfile?.gender ||
      (campaign.countries?.length > 0 && !userInfo.creatorProfile?.shippingCountry) ||
      (campaign.requiresShipping && !userInfo.creatorProfile?.shippingAddress1) ||
      (campaign.categories?.length > 0 && !userInfo.creatorProfile?.niches?.length)
    ) {
      setOptInOpen('profile')
      setOptInLoading(false)
      return
    }

    setOptInLoading(true)

    // Checking eligibility through backend
    const channelsToCheck = selectedChannels?.length ? selectedChannels : campaign.socialChannels

    try {
      const res = await optInCreator(
        campaign?.id,
        selectedVariables,
        selectedProduct,
        channelsToCheck
      )

      if (res.error) {
        setOptInLoading(false)
        message.error(res.error)
        return
      } else if (res.eligible) {
        setOptInLoading(false)
        localStorage.setItem('campaignId', campaign?.id)
        if (res.optInId) {
          // Google Analytics Tracking
          window.dataLayer.push({
            event: `submit_optin_cr`,
          })

          window.location.href = `/opt-in-confirmation/${res.optInId}`
        } else {
          message.error('Sorry, something went wrong.')
        }
      } else {
        setOptInLoading(false)
        setNotEligibleData(res)
        setOptInOpen('not-eligible')
      }
    } catch (err) {
      message.error('Sorry, something went wrong.')
    }
  }

  // automatically begin opt-in process if user is accepting an invite
  const urlParams = new URLSearchParams(window.location.search)
  const optIn = urlParams.get('accepting-invite')

  useEffect(() => {
    if (optIn) {
      handleOptIn()
      //remove query params to prevent opt-in from triggering on page refresh
      urlParams.delete('accepting-invite')
      history.replace({
        search: urlParams.toString(),
      })
    }
  })

  const handleCancel = () => {
    // reset opt-in state & close modal
    setOptInLoading(false)
    setSelectedVariables({})
    setSelectedProduct({})
    setSelectedChannels([])
    setOptInOpen(false)
  }
  // #endregion Handlers

  const modalProps = {
    open: optInOpen,
    optInLoading,
    handleOptIn,
    handleCancel,
    userData,
    campaign,
    selectedVariables,
    setSelectedVariables,
    selectedProduct,
    setSelectedProduct,
    selectedChannels,
    setSelectedChannels,
    socialConnects,
    notEligibleData,
  }

  return (
    <Wrapper>
      {!campaign.disableQualifications && (
        <>
          <h2>Qualifications</h2>
          {campaign.campaignInvites?.find(invite => invite.code && invite.code === inviteCode) ? (
            <div className='bypass-qualifications'>
              Congratulations{userData?.firstName ? ` ${userData.firstName}` : ''}! You have been
              invited to this campaign. The requirements for this campaign are bypassed for you, so
              click on the Opt-In button below to get started!
            </div>
          ) : (
            <>
              <p>
                <InfoCircleOutlined /> You must meet the following requirements to participate in
                this campaign. For multi-channel campaigns, you need to meet the follower &
                engagement criteria for at least one channel.
              </p>

              {/* SOCIAL CHANNELS */}
              {campaign.socialChannels?.map((channel, i) => (
                <div key={i}>
                  <div className='qualification'>
                    <span className='label'>
                      {userData && (
                        <span className='eligibility-indicator'>
                          {followerChecks[channel] ? (
                            <CheckCircleOutlined style={{ color: '#027df0' }} />
                          ) : (
                            <CloseCircleOutlined style={{ color: '#ccc' }} />
                          )}
                        </span>
                      )}
                      <img className='icon' alt='' src={socialIcons[channel]} />
                      {channel === 'youtube' ? 'Subscribers' : 'Followers'}
                    </span>
                    <span className='value'>
                      {campaign[`${channel}Reach`]
                        ? `${campaign[`${channel}Reach`].toLocaleString()}+`
                        : '-'}
                    </span>
                  </div>
                  <div className='qualification'>
                    <span className='label'>
                      {userData && (
                        <span className='eligibility-indicator'>
                          {engagementChecks[channel] ? (
                            <CheckCircleOutlined style={{ color: '#027df0' }} />
                          ) : (
                            <CloseCircleOutlined style={{ color: '#ccc' }} />
                          )}
                        </span>
                      )}
                      <img className='icon' alt='' src={socialIcons[channel]} />
                      Engagement
                    </span>
                    <span className='value'>
                      {campaign[`${channel}Engagement`]
                        ? campaign[`${channel}Engagement`] + '%+'
                        : '-'}
                    </span>
                  </div>
                </div>
              ))}

              {/* DEMOGRAPHICS */}
              {/* age range default 13+ */}
              <div className='qualification'>
                <span className='label'>
                  {userData && (
                    <span className='eligibility-indicator'>
                      {ageCheck ? (
                        <CheckCircleOutlined style={{ color: '#027df0' }} />
                      ) : (
                        <CloseCircleOutlined style={{ color: '#ccc' }} />
                      )}
                    </span>
                  )}
                  Age
                </span>
                <span className='value'>
                  {campaign.minAge && campaign.maxAge
                    ? `${campaign.minAge} to ${campaign.maxAge}`
                    : campaign.minAge && !campaign.maxAge
                      ? `${campaign.minAge}+`
                      : !campaign.minAge && campaign.maxAge
                        ? `13 to ${campaign.maxAge}`
                        : '13+'}
                </span>
              </div>
              {userData && !genderCheck && (
                <div className='qualification'>
                  <span className='label'>
                    {userData && (
                      <span className='eligibility-indicator'>
                        {genderCheck ? (
                          <CheckCircleOutlined style={{ color: '#027df0' }} />
                        ) : (
                          <CloseCircleOutlined style={{ color: '#ccc' }} />
                        )}
                      </span>
                    )}
                    Gender
                  </span>
                  <span className='value'>{genders}</span>
                </div>
              )}
              <div className='qualification'>
                <span className='label'>
                  {userData && (
                    <span className='eligibility-indicator'>
                      {residenceCheck ? (
                        <CheckCircleOutlined style={{ color: '#027df0' }} />
                      ) : (
                        <CloseCircleOutlined style={{ color: '#ccc' }} />
                      )}
                    </span>
                  )}
                  Residence
                </span>
                <span className='value'>
                  {campaign.countries?.length ? (
                    campaign.countries.map(item => (
                      <CircleFlag
                        key={item.country.id}
                        className='icon'
                        countryCode={`${item.country.countryCode.toLowerCase()}`}
                      />
                    ))
                  ) : (
                    <img className='icon' src={globe} alt='Global' />
                  )}
                </span>
              </div>
              <div className='qualification niches'>
                <span className='label'>
                  {userData && (
                    <span className='eligibility-indicator'>
                      {nicheCheck ? (
                        <CheckCircleOutlined style={{ color: '#027df0' }} />
                      ) : (
                        <CloseCircleOutlined style={{ color: '#ccc' }} />
                      )}
                    </span>
                  )}
                  Niche
                </span>
                {categories}
              </div>
            </>
          )}
        </>
      )}

      <div className='opt-in'>
        {optInId ? (
          <div className='opted-in'>
            <Alert
              type='success'
              message={
                <>
                  You are opted in! <Link to={`/my-campaigns/${optInId}`}>View Details</Link>
                </>
              }
              showIcon
            />
          </div>
        ) : (
          <>
            <div className='buttons'>
              <Button
                type='secondary'
                onClick={handleWishlist}
                loading={loading}
                icon={pinned ? <MinusOutlined /> : <PlusOutlined />}
                disabled={!userData}>
                Wishlist
              </Button>
              <Button
                type='primary'
                onClick={handleOptIn}
                loading={optInLoading}
                data-cy='opt-in-button'>
                {campaign.disableActivations ? 'Join Campaign' : 'Opt In'}
              </Button>
            </div>
          </>
        )}
      </div>

      {/* OPT IN MODAL (handles missing info & steps required to opt-in) */}
      <OptInModal {...modalProps} />
    </Wrapper>
  )
}

const Wrapper = styled.section`
  .bypass-qualifications {
    border-radius: 10px;
    padding: 30px 30px 10px 30px;
    text-align: center;
    font-size: 1.1rem;
    backdrop-filter: blur(10px);
  }
  .qualification {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    border-bottom: 1px solid #e6e6e6;
    font-size: 1rem;
    gap: 10px;
    &.niches {
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      gap: 5px;
    }
    .label {
      color: #666;
      white-space: nowrap;
      margin-right: auto;
      .anticon {
        margin-right: 10px;
      }
    }
    .value {
      color: ${props => props.theme.crcoTechBlue};
      text-align: right;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      gap: 3px;
    }
    .niche {
      background: #eee;
      color: #999;
      padding: 0 5px;
      border-radius: 3px;
      font-size: 0.8rem;
      &.match {
        background: #ecf6ff;
        color: ${props => props.theme.crcoTechBlue};
      }
    }
    .icon {
      height: 20px;
      margin-right: 5px;
    }
  }
  .opt-in {
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    margin: 0;
    padding: 10px 20px;
    z-index: 10;
    .opted-in {
      text-align: center;
    }
    .buttons {
      display: flex;
      justify-content: center;
      gap: 20px;
      button {
        flex: 1;
        width: 100%;
        max-width: 200px;
        height: 40px;
        font-size: 16px;
        &:disabled {
          pointer-events: none;
        }
      }
    }
  }

  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    .opt-in {
      display: block;
    }
  }

  @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
    .opt-in {
      background: transparent;
      position: static;
      margin-top: 20px;
      box-shadow: none;
    }
  }
`
