import { SearchOutlined } from '@ant-design/icons'
import { Button, Empty, Drawer } from 'antd'
import axios from 'axios'
import { Form, Formik } from 'formik'
import { Input } from 'formik-antd'
import React, { useEffect, useRef, useState, useContext } from 'react'
import { useInfiniteQuery } from 'react-query'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

import { GhostOptInListItem } from './GhostOptInListItem'
import { OptInFilters } from './OptInFilters'
import OptInsListItem from './OptInsListItem'
import filterIcon from '../../../../assets/images/icons/filter.svg'
import { API_URL } from '../../../../constants'
import { OptInContext } from '../../../../contexts/OptInContext'

export const OptInsList = () => {
  const { formData, setFormData } = useContext(OptInContext)
  const [optIns, setOptIns] = useState([])
  const submitRef = useRef(0)
  const [drawerOpen, setDrawerOpen] = useState()

  const {
    data: optInsData,
    status: optInsStatus,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['opt-ins', formData],
    async ({ pageParam = 0, queryKey }) => {
      const { data } = await axios.get(`${API_URL}/me/opt-ins/${pageParam}/`, {
        params: queryKey[1],
      })
      return data
    },
    {
      getNextPageParam: lastPage => lastPage.nextPage,
    }
  )

  useEffect(() => {
    if (optInsData) {
      let pages = optInsData.pages
      const pagesArray = Array.prototype.concat.apply([], pages)
      const optInArray = Array.prototype.concat.apply(
        [],
        pagesArray.map(page => page.optIns || page)
      )
      setOptIns([optInArray][0].map((optIn, i) => <OptInsListItem key={i} optIn={optIn} />))
    }
  }, [optInsData])

  const handleSubmit = async values => {
    submitRef.current++
    const thisSubmit = submitRef.current
    setTimeout(() => {
      if (thisSubmit === submitRef.current) {
        setFormData({
          ...values,
          hasUnread: values.hasUnread ? 1 : 0,
        })
      }
    }, 400)
  }

  return (
    <Wrapper>
      <div className='wrapper-inner'>
        <Formik initialValues={formData} onSubmit={handleSubmit}>
          {({ submitForm, resetForm }) => (
            <Form className='filters-wrapper'>
              <Input
                className='search'
                name='search'
                onChange={submitForm}
                onPressEnter={e => e.preventDefault()}
                placeholder='Search campaigns'
                allowClear
                prefix={<SearchOutlined />}
                style={{ width: '100%' }}
              />
              <div className='filters-menu'>
                <div className='filters-menu-inner'>
                  <OptInFilters
                    submitForm={submitForm}
                    resetForm={resetForm}
                    setFormData={setFormData}
                  />
                </div>
              </div>
              {/* MOBILE FILTER MENU DRAWER */}
              <button
                className='view-filters-btn'
                aria-label='Open Filters'
                onClick={() => setDrawerOpen(true)}>
                <img className='icon' src={filterIcon} alt='' />
              </button>
              <Drawer
                title='Filter Campaigns'
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}>
                <OptInFilters
                  submitForm={submitForm}
                  resetForm={resetForm}
                  setFormData={setFormData}
                />
              </Drawer>
            </Form>
          )}
        </Formik>

        {optInsStatus === 'success' ? (
          optIns?.length ? (
            <section className='list'>
              {optIns}
              {hasNextPage ? (
                <Button className='load-more' type='link' onClick={fetchNextPage}>
                  Load More
                </Button>
              ) : isFetchingNextPage ? (
                Array.from(Array(3).keys()).map(item => <GhostOptInListItem key={item} />)
              ) : null}
            </section>
          ) : Object.keys(formData).length ? (
            <section className='empty'>
              <Empty description='No results.' />
            </section>
          ) : (
            <section className='empty'>
              <Empty description={`You haven't opted in to any campaigns yet.`} />
              <Link to='/collaboration-hub' className='cta'>
                <Button type='primary'>Explore Campaigns</Button>
              </Link>
            </section>
          )
        ) : (
          <section className='list'>
            {Array.from(Array(6).keys()).map(item => (
              <GhostOptInListItem key={item} />
            ))}
          </section>
        )}
      </div>
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  height: 100%;
  .wrapper-inner {
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .filters-wrapper {
    background: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 8px 20px;
    width: 100%;
    display: flex;
    grid-gap: 10px;
    margin: 0 auto;
    .view-filters-btn {
      background: transparent;
      border: none;
      padding: 0;
      margin: auto;
      .icon {
        height: 20px;
        width: 20px;
      }
    }
    .filters-menu {
      height: calc(100vh - 50px);
      position: fixed;
      width: 240px;
      top: 50px;
      left: -240px;
      transition: 0.2s ease-in-out;
      z-index: 999;
      .filters-menu-inner {
        height: 100%;
        width: 100%;
        padding: 20px;
      }
    }
  }
  .list,
  .empty {
    height: 100%;
  }
  .list {
    overflow: auto;
    display: flex;
    flex-direction: column;
    .load-more {
      margin: 20px;
    }
  }
  .empty {
    width: 100%;
    display: grid;
    place-content: center;
    .cta {
      margin: 20px auto 0 auto;
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
    max-width: 1400px;
    padding: 20px;
    margin: 0 auto;
    .wrapper-inner {
      border-radius: 10px;
    }
    .filters-wrapper {
      flex-wrap: wrap;
      padding: 16px 20px;
      border-radius: 10px 10px 0 0;
      .search {
        flex: 1;
        min-width: 300px;
      }
      .view-filters-btn {
        display: none;
      }
      .filters-menu {
        position: relative;
        top: auto;
        left: auto;
        height: auto;
        width: 100%;
        flex-direction: row;
        flex: 1;
        .filters-menu-inner {
          padding: 0;
        }
      }
    }
    .list,
    .empty {
      background: #e9e9f5;
      border-radius: 0 0 10px 10px;
    }
  }
`
