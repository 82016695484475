import { LoadingOutlined, SearchOutlined } from '@ant-design/icons'
import { Checkbox, List, Input as AntInput, Button, Row, Col, message } from 'antd'
import { CountryPhoneInput, ConfigProvider } from 'antd-country-phone-input'
import axios from 'axios'
import { Form, Formik } from 'formik'
import { Input, Select } from 'formik-antd'
import React, { useContext, useEffect, useState } from 'react'
import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query'
import styled from 'styled-components'
import en from 'world_countries_lists/data/countries/en/world.json'

import shopifyIcon from '../../../../assets/images/icons/shopify-icon.svg'
import { API_URL } from '../../../../constants'
import { UserContext } from '../../../../contexts/UserContext'
import { FormItem } from '../../../general/forms/FormItem'

const CreateShopifyOrder = ({ optInId, maxShopifyProducts, close }) => {
  const queryClient = new useQueryClient()
  const [currentStep, setCurrentStep] = useState(1)
  const [selectedProducts, setSelectedProducts] = useState([])
  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])

  const { fetchCurrentUser } = useContext(UserContext)
  const { data: userData } = useQuery('user', fetchCurrentUser)

  const [search, setSearch] = useState('')
  const {
    data: productsData,
    status: productsStatus,
    isFetchingMore,
    fetchMore,
    canFetchMore,
  } = useInfiniteQuery(
    ['shopify-products', optInId, search],
    async ({ page, queryKey: [, optInId, search] }) => {
      const data = await axios
        .get(`${API_URL}/opt-in/${optInId}/shopify/products`, {
          params: {
            search,
            page,
          },
        })
        .then(res => res.data)
      return data
    },
    { getFetchMore: lastGroup => lastGroup.nextPageCursor }
  )

  const handleScroll = e => {
    if (e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight && canFetchMore) {
      fetchMore()
    }
  }

  const searchRef = React.useRef(0)
  const handleSearch = e => {
    const search = e.target.value

    searchRef.current += 1
    const currentSearch = searchRef.current
    setTimeout(() => {
      if (currentSearch === searchRef.current) {
        setSearch(search)
      }
    }, 500)
  }

  useEffect(() => {
    axios.get('../../json/countries.json').then(res => {
      setCountries(res.data.countries)
    })

    axios.get('../../json/states.json').then(res => {
      setStates(res.data.states)
    })
  }, [])

  const handleSubmit = async values => {
    await axios
      .post(`${API_URL}/creator/opt-in/${optInId}/shopify/order`, {
        products: selectedProducts,
        customer: {
          ...values,
          phone: `+${values.phoneCode}${values.phone}`,
        },
      })
      .then(() => {
        message.success('Order placed successfully!')
        close()
        queryClient.invalidateQueries('opt-in')
      })
      .catch(err => {
        message.error('Something went wrong. Please try again.')
        return err
      })
  }

  return (
    <Wrapper>
      {currentStep === 1 && (
        <div className='products'>
          <div className='products-header'>
            <AntInput
              placeholder='Search products'
              prefix={<SearchOutlined />}
              allowClear
              onChange={handleSearch}
            />
          </div>
          <div className='products-body'>
            {productsStatus === 'success' &&
              (productsData.pages[0]?.products?.length ? (
                <>
                  <List
                    loading={isFetchingMore}
                    className='products-list'
                    onScroll={handleScroll}
                    dataSource={productsData.pages.flatMap(page => page.products)}
                    rowKey={product => product.id}
                    renderItem={product => (
                      <List.Item key={product.id} className='product'>
                        <img
                          className='product-image'
                          src={product.imageUrl || shopifyIcon}
                          alt={product.title}
                        />
                        <span className='product-title'>{product.title}</span>
                        <div className='variations'>
                          {product.variations?.map(variation => (
                            <label key={variation.id} className='variation'>
                              <span>{variation.title}</span>
                              <Checkbox
                                onChange={e => {
                                  setSelectedProducts(prev =>
                                    e.target.checked
                                      ? [...prev, variation.shopifyId]
                                      : prev.filter(id => id !== variation.shopifyId)
                                  )
                                }}
                                checked={selectedProducts.includes(variation.shopifyId)}
                              />
                            </label>
                          ))}
                        </div>
                      </List.Item>
                    )}
                  />
                </>
              ) : (
                <div>No products found.</div>
              ))}
            {productsStatus === 'loading' && (
              <div className='loading'>
                <LoadingOutlined spin />
              </div>
            )}
          </div>

          <div className='navigation'>
            <Button
              disabled={selectedProducts.length !== maxShopifyProducts}
              type='primary'
              onClick={() => setCurrentStep(2)}>
              Continue
            </Button>
          </div>
        </div>
      )}

      {currentStep === 2 && (
        <div>
          <Formik
            onSubmit={handleSubmit}
            initialValues={{
              firstName: userData.firstName,
              lastName: userData.lastName,
              phone: userData.phone,
              phoneCode: userData.phoneCode || 1,
              phoneShort: userData.phoneShort || 'US',
              email: userData.email,
              address1: userData.creatorProfile.shippingAddress1,
              address2: userData.creatorProfile.shippingAddress2,
              city: userData.creatorProfile.shippingCity,
              country: userData.creatorProfile.shippingCountry,
              province: userData.creatorProfile.shippingRegion,
              zip: userData.creatorProfile.shippingPostcode,
            }}>
            {({ values, setValues, isSubmitting }) => (
              <Form className='shipping-form'>
                <p className='shipping-form-instructions'>
                  Please confirm your shipping information.
                </p>
                <Row gutter={16}>
                  <Col span={12}>
                    <FormItem size='small' name='firstName' label='First Name'>
                      <Input name='firstName' />
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem size='small' name='lastName' label='Last Name'>
                      <Input name='lastName' />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <FormItem size='small' name='email' label='Email'>
                      <Input name='email' />
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem size='small' name='phone' label='Phone'>
                      <ConfigProvider locale={en}>
                        <CountryPhoneInput
                          inline
                          name='phone'
                          value={{
                            phone: values.phone,
                            short: values.phoneShort,
                            code: values.phoneCode,
                          }}
                          onChange={ev => {
                            setValues(prev => ({
                              ...prev,
                              phone: ev.phone,
                              phoneShort: ev.short,
                              phoneCode: ev.code,
                            }))
                          }}
                        />
                      </ConfigProvider>
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <FormItem size='small' name='address1' label='Address 1'>
                      <Input name='address1' />
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem size='small' name='address2' label='Address 2'>
                      <Input name='address2' />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <FormItem size='small' name='country' label='Country'>
                      <Select
                        name='country'
                        getPopupContainer={trigger => trigger.parentNode}
                        optionFilterProp='label'
                        showSearch>
                        {countries.map(country => (
                          <Select.Option
                            key={country.country_code}
                            value={country.country_code}
                            label={country.name}>
                            {country.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem size='small' name='province' label='Region'>
                      <Select
                        getPopupContainer={trigger => trigger.parentNode}
                        virtual={false}
                        showSearch
                        name='province'
                        optionFilterProp='label'>
                        {states
                          .filter(state => state.country_code === values.country)
                          .map(state => (
                            <Select.Option key={state.id} value={state.name} label={state.name}>
                              {state.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <FormItem size='small' name='city' label='City'>
                      <Input name='city' />
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem size='small' name='zip' label='Zip'>
                      <Input name='zip' />
                    </FormItem>
                  </Col>
                </Row>
                <div className='navigation'>
                  <Button type='secondary' onClick={() => setCurrentStep(1)}>
                    Back
                  </Button>

                  <Button type='primary' htmlType='submit' loading={isSubmitting}>
                    Create Order
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .products {
    .products-body {
      height: 600px;
      margin-top: 24px;
    }
    .navigation {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;
    }
  }
  .products-list {
    margin-top: 24px;
    height: 100%;
    overflow-y: auto;

    .product {
      padding: 12px;
      display: flex;
      justify-content: flex-start;
      gap: 16px;
      .product-image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
      .product-title {
        flex: 1;
        font-size: 16px;
        font-weight: bold;
      }
      .variations {
        display: flex;
        flex-direction: column;
        gap: 12px;
        .variation {
          cursor: pointer;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 8px;
        }
      }
    }
  }
  .loading {
    height: 100%;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .shipping-form {
    .shipping-form-instructions {
      color: ${props => props.theme.crcoGrey};
      margin-bottom: 20px;
    }

    .ant-select {
      width: 100%;
    }

    .navigation {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
    }
  }
`

export default CreateShopifyOrder
