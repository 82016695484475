import { HeartFilled } from '@ant-design/icons'
import { Rate } from 'antd'
import React, { useContext } from 'react'
import styled from 'styled-components/macro'

import { OptInContext } from '../../../../../contexts/OptInContext'
import { getAverageRating } from '../../../../../utils'

export const BRAND_SCORE_SHOUTOUT = ({ campaignStep }) => {
  const { optIn } = useContext(OptInContext)
  const stepReached = campaignStep.placement <= optIn.currentStep?.placement

  return (
    <Wrapper className='programmed-step'>
      {optIn.rating ? (
        <>
          <p>The brand has submitted their rating for your collaboration.</p>
          <Rate
            value={getAverageRating(optIn.rating)}
            character={<HeartFilled />}
            className='rate'
            disabled
          />
          <div className='rating-breakdown'>
            <p>Content Quality: {optIn.rating.content}</p>
            <p>Creativity: {optIn.rating.creativity}</p>
            <p>Communication: {optIn.rating.communication}</p>
            <p>Overall: {optIn.rating.overall}</p>
          </div>
        </>
      ) : (
        stepReached && <p>The brand needs to rate your collaboration.</p>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .rating-breakdown {
    p {
      margin: 0;
      font-size: 0.8rem;
      color: ${props => props.theme.crcoGrey};
    }
  }
  .rate {
    font-size: 26px;
    color: ${props => props.theme.crcoCoral};
    background-color: #ffffff;
    border-radius: 20px;
    padding: 2px 20px;
    margin: 10px 0;
  }
`
