import { Empty, Button } from 'antd'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

import { useMetaData } from '../../custom-hooks/useMetaData'

export const NotFound = ({ message = 'Page not found.', buttonText = 'Go Home', to = '/' }) => {
  useMetaData(
    'creator.co | Page not found',
    'Oops, this page does not exist.',
    'https://creatorco.nyc3.cdn.digitaloceanspaces.com/assets/preview-images/meta-preview.png'
  )

  return (
    <Wrapper>
      <div className='inner'>
        <Empty description={message} />
        <Link to={to} className='cta'>
          <Button type='primary'>{buttonText}</Button>
        </Link>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  margin: auto;
  padding: 20px;
  display: grid;
  place-content: center;
  .inner {
    background: #fff;
    display: grid;
    place-content: center;
    text-align: center;
    margin: auto;
    padding: 40px 20px;
    border-radius: 10px;
    box-shadow: ${props => props.theme.crcoBoxShadow};
    .cta {
      margin: 20px auto 0 auto;
    }
  }
`
