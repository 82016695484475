import { ExclamationCircleFilled, DollarCircleOutlined } from '@ant-design/icons'
import { Alert, Button, DatePicker, Drawer, Empty, Select } from 'antd'
import axios from 'axios'
import moment from 'moment/moment'
import React, { useContext, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'

import { Wrapper } from './AffiliateAwin'
import { API_URL } from '../../../../constants'
import { UserContext } from '../../../../contexts/UserContext'
import LoadingHeart from '../../../general/LoadingHeart'
import PayoutSettings from '../PayoutSettings'

const AffiliateShareASale = () => {
  const { fetchCurrentUser } = useContext(UserContext)
  const { data: userData } = useQuery('user', fetchCurrentUser)

  const queryClient = useQueryClient()
  const [dateRange, setDateRange] = useState([
    moment(new Date().getTime() - 1000 * 60 * 60 * 24 * 30),
    moment(new Date()),
  ])
  const [brand, setBrand] = useState(null)
  const [showPayouts, setShowPayouts] = useState(false)

  const fetchShareASaleBrands = async () => {
    const { data } = await axios.get(`${API_URL}/me/shareasale/brands`)
    return data
  }

  const { data: shareASaleBrands, status: shareASaleBrandsStatus } = useQuery(
    ['shareasale-brands'],
    fetchShareASaleBrands
  )

  const { data: affiliateData, status: affiliateStatus } = useQuery(
    [
      'shareasale-affiliate',
      {
        dateRange: [dateRange[0].toISOString(), dateRange[1].toISOString()],
        brand,
        userId: userData.id,
      },
    ],
    async ({ queryKey: [params] }) => {
      const { data } = await axios
        .get(`${API_URL}/me/shareasale/affiliate-analytics`, {
          params,
        })
        .catch(() => {}) // TODO: handle error

      return data
    }
  )

  return (
    <Wrapper>
      <div className='inner'>
        <div className='inner-content'>
          <div className='filters-box'>
            <div className='filters'>
              <DatePicker.RangePicker
                allowClear={false}
                defaultValue={dateRange}
                disabledDate={current =>
                  current.diff(dateRange[0], 'days') > 30 || current.diff(dateRange[1], 'days') > 30
                }
                onChange={e => {
                  setDateRange(e.map(date => date._d))
                }}
              />
              <Select
                style={{ width: '160px' }}
                showArrow
                showSearch
                allowClear
                placeholder='Select a Brand'
                loading={shareASaleBrandsStatus === 'loading'}
                onChange={e => {
                  setBrand(e)
                }}>
                {shareASaleBrands?.map((brand, i) => (
                  <Select.Option key={i} value={brand?.id}>
                    {brand.name}
                  </Select.Option>
                ))}
              </Select>
            </div>

            <Button
              style={{ fontSize: '15px' }}
              type='primary'
              onClick={() => setShowPayouts(true)}
              icon={<DollarCircleOutlined />}>
              Payout history
            </Button>
            <Drawer
              onClose={() => {
                setShowPayouts(false)
                queryClient.invalidateQueries('user')
              }}
              open={showPayouts}
              footer={null}
              width={window.innerWidth > 600 ? 600 : '100vw'}>
              <PayoutSettings />
            </Drawer>

            {userData?.trolleyStatus !== 'active' && (
              <Alert
                type='warning'
                message={
                  <span>
                    You need to verify your payment details to receive commission payouts. You can
                    do so in <Link to='/my-settings'>your account settings</Link>
                  </span>
                }
                icon={<ExclamationCircleFilled />}
                showIcon
              />
            )}
          </div>

          {affiliateStatus === 'success' && (
            <div className='affiliates'>
              {affiliateData?.length ? (
                <>
                  <div className='affiliate-header'>
                    <div className='affiliate-name'>Program</div>
                    <div className='clicks'>Clicks</div>
                    <div className='sales'>Sales</div>
                    <div className='commissions'>Commissions</div>
                  </div>
                  <div className='affiliate-list'>
                    {affiliateData.map((item, i) => {
                      return (
                        <div key={i} className='affiliate'>
                          <div className='affiliate-name'>{item.advertiserName}</div>
                          <div className='affiliate-analytics'>
                            <div className='clicks'>
                              <span className='value'>{item.data.clicks}</span>
                              <span className='affiliate-analytics-text'>Clicks</span>
                            </div>
                            <span className='analytics-separator'>•</span>
                            <div className='sales'>
                              <span className='value'>
                                {item.data.sales ? item.data.sales : '-'}
                              </span>
                              <span className='affiliate-analytics-text'>Sales</span>
                            </div>
                            <span className='analytics-separator'>•</span>
                            <div className='commissions'>
                              <span>{item.data.commissions ? item.data.commissions : '-'}</span>
                              <span className='affiliate-analytics-text'>Commissions</span>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </>
              ) : (
                <div className='no-results'>
                  <Empty description='No affiliates found.' />
                </div>
              )}
            </div>
          )}
          {affiliateStatus === 'loading' && (
            <div className='loading'>
              <LoadingHeart />
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  )
}

export default AffiliateShareASale
