import styled from 'styled-components'

import { ImageLoader } from '../../../general/ImageLoader'

export const CampaignThumbnail = ({ optIn }) => {
  const thumbnailUrl = !optIn.campaign.shoptypeProduct
    ? optIn.campaign.images?.filter(image => image.type === 'primary')[0]?.sizes[1]?.url
    : optIn.campaign.shoptypeProduct.primaryImageSrc?.imageSrc

  let statusText = ''
  if (optIn.reinvited) {
    statusText = 'Reinvited'
  } else {
    switch (optIn.status) {
      case 'pending':
        statusText = 'Pending'
        break
      case 'activated':
        statusText = 'Activated'
        break
      case 'completed':
        statusText = 'Completed'
        break
      case 'cancelled':
        statusText = optIn.cancelledData?.reason === 'expired' ? 'Expired' : 'Declined'
        break
      default:
        break
    }
  }

  return (
    <ThumbnailWrapper
      status={optIn.reinvited ? 'reinvited' : optIn.status}
      className='thumbnail-portrait'>
      <div className='thumbnail'>
        <ImageLoader src={thumbnailUrl} />
      </div>
      <span style={{ marginTop: '2px' }}>{statusText}</span>
    </ThumbnailWrapper>
  )
}

export const ThumbnailWrapper = styled.div`
  width: 80px;
  height: 100px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #fff;
  margin-right: 12px;
  font-size: 0.8rem;

  .thumbnail {
    background: #fff;
    flex: 1px;
    border-radius: 5%;
    overflow: hidden;
    aspect-ratio: 1;
  }

  border: 2px solid
    ${props => {
      switch (props.status) {
        case 'pending':
          return '#bbb'
        case 'activated':
          return '#027df0'
        case 'cancelled':
          return '#FC9C44'
        case 'reinvited':
          return '#b0a1d8'
        default:
          // completed
          return '#18D96E'
      }
    }};
  background-color: ${props => {
    switch (props.status) {
      case 'pending':
        return '#bbb'
      case 'activated':
        return '#027df0'
      case 'cancelled':
        return '#FC9C44'
      case 'reinvited':
        return '#b0a1d8'
      default:
        // completed
        return '#18D96E'
    }
  }};
`
