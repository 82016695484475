import styled from 'styled-components/macro'

export const GhostOptInListItem = () => {
  return (
    <Wrapper>
      <div className='main'>
        <div className='thumbnail loading-gradient'></div>
        <div className='info'>
          <div className='row loading-gradient'></div>
          <div className='row loading-gradient'></div>
          <div className='row loading-gradient'></div>
        </div>
      </div>
      <div className='statuses'>
        <div className='status loading-gradient' />
        <div className='status loading-gradient' />
        <div className='status loading-gradient' />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 15px;
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  position: relative;
  width: 100%;
  .loading-gradient {
    border-radius: 5px;
  }
  .main {
    flex: 3;
    display: flex;
    align-items: center;
    .thumbnail {
      height: 100px;
      width: 80px;
      border-radius: 5px;
    }
    .info {
      flex: 1;
      margin-left: 10px;
      .row {
        height: 15px;
        margin-bottom: 5px;
      }
    }
  }
  .status {
    flex: 1;
    margin: 10px 0;
    height: 20px;
  }
  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    .statuses {
      display: flex;
      gap: 5px;
    }
    .status {
      height: 50px;
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
    display: flex;
    .main {
      flex: 1;
      .info .row {
        width: 80%;
        margin-bottom: 10px;
      }
    }
    .statuses {
      flex: 1;
    }
    .status {
      height: auto;
    }
  }
`
