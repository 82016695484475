import { Button } from 'antd'
import { Form, Formik } from 'formik'
import React, { useContext } from 'react'
import styled from 'styled-components/macro'

import { OptInContext } from '../../../../../contexts/OptInContext'

export const INFLUENCER_SUBMIT_CONTENT_PREVIEW = ({ campaignStep }) => {
  const { optIn, stepAction } = useContext(OptInContext)
  const stepReached = campaignStep.placement <= optIn.currentStep?.placement
  return (
    <Wrapper className='programmed-step'>
      {campaignStep.optInStep?.completed ? (
        <p>The brand has approved your content preview!</p>
      ) : (
        stepReached && (
          <div>
            <p>Please upload your content preview via chat for the brand to review.</p>
            <Formik
              initialValues={{}}
              onSubmit={async () => {
                // complete custom step
                await stepAction(optIn.id, campaignStep.id)
              }}>
              {({ isSubmitting }) => (
                <Form>
                  <Button loading={isSubmitting} htmlType='submit' type='primary'>
                    Done
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        )
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div``
