import { Button, Alert, Radio, Space } from 'antd'
import axios from 'axios'
import { useState, useEffect } from 'react'
import { useQueryClient } from 'react-query'
import styled from 'styled-components/macro'

import { API_URL } from '../../../../constants'

export const EditProfileHandle = ({ userData, setEditHandleMode }) => {
  const queryClient = new useQueryClient()
  const [loading, setLoading] = useState(false)
  const [suggestedSlugs, setSuggestedSlugs] = useState([])
  const [selectedSlug, setSelectedSlug] = useState('')
  const [status, setStatus] = useState(undefined)

  useEffect(() => {
    let handles = []
    const platforms = ['instagram', 'tiktok', 'youtube']

    platforms.forEach(platform => {
      const socialProfile = userData.socialProfiles?.find(profile => profile.platform === platform)

      if (socialProfile?.username) {
        handles.push(socialProfile.username)
      }
    })

    // cleaning up handles (removing @, periods, underscores and spaces, lowercase)
    handles = handles
      .map(handle => {
        return handle
          .replace(/@/g, '')
          .replace(/\./g, '')
          .replace(/_/g, '')
          .replace(/ /g, '')
          .toLowerCase()
      })
      // removing user's current profileSlug
      .filter(handle => handle !== userData.creatorProfile.profileSlug)

    // removing duplicates
    handles = [...new Set(handles)]

    setSuggestedSlugs(handles)
  }, [userData])

  const claimProfileHandle = async slug => {
    setLoading(true)
    try {
      await axios.post(`${API_URL}/creator-profile/${slug}`).then(() => {
        queryClient.invalidateQueries('user')
        setLoading(false)
        setEditHandleMode(false)
      })
    } catch (err) {
      setStatus(err.response.data)
      setLoading(false)
    }
  }

  return (
    <Wrapper>
      <p>Change your profile handle</p>
      <p className='info-text'>(Options generated from your connected social channels)</p>
      <Radio.Group
        onChange={e => {
          setStatus(undefined)
          setSelectedSlug(e.target.value)
        }}>
        {suggestedSlugs.length ? (
          <Space direction='vertical'>
            {suggestedSlugs.map(slug => (
              <Radio key={slug} value={slug}>
                {slug}
              </Radio>
            ))}
          </Space>
        ) : (
          <Alert message='No alternate handles available' type='warning' showIcon />
        )}
      </Radio.Group>

      {status && <Alert message={status} type='error' showIcon />}

      <div className='buttons'>
        <Button
          type='primary'
          disabled={!selectedSlug}
          loading={loading}
          onClick={() => {
            claimProfileHandle(selectedSlug)
          }}>
          Change Handle
        </Button>
        <Button type='secondary' onClick={() => setEditHandleMode(false)}>
          Cancel
        </Button>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: ${props => props.theme.crcoOffWhite};
  padding: 20px;
  .buttons {
    margin-top: 20px;
  }
`
