import { io } from 'socket.io-client'

import { API_URL } from '../constants'

const init = () => {
  return io(API_URL, { transports: ['websocket'] })
}

const socket = init()

export { socket }
