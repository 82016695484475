import { EditOutlined, SaveOutlined } from '@ant-design/icons'
import { Alert, Button, Col, message, Row, Checkbox } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import axios from 'axios'
import { Formik, useFormikContext } from 'formik'
import { Form, Input, Select, DatePicker } from 'formik-antd'
import moment from 'moment'
import React, { useContext, useState, useRef, useEffect } from 'react'
import { useQuery } from 'react-query'
import { animated, useTrail } from 'react-spring'
import styled from 'styled-components/macro'
import * as yup from 'yup'

import PayoutSettings from './PayoutSettings'
import { SocialAccounts } from './social-accounts/SocialAccounts'
import { GlobalContext } from '../../../contexts/GlobalContext'
import { UserContext } from '../../../contexts/UserContext'
import { useMetaData } from '../../../custom-hooks/useMetaData'
import { FormItem } from '../../general/forms/FormItem'
import PhoneInput from '../../general/forms/PhoneInput'
import { SavingIndicator } from '../../general/SavingIndicator'
const { Option } = Select

const ProfileSettings = () => {
  useMetaData(
    'My Settings | Creator Dashboard',
    'Adjust your account settings.',
    'https://creatorco.nyc3.cdn.digitaloceanspaces.com/assets/preview-images/meta-preview.png'
  )
  const { fetchCurrentUser } = useContext(UserContext)
  const { data: userData } = useQuery('user', fetchCurrentUser)

  const items = [
    <AccountDetails key={1} userData={userData} />,
    <Shipping key={2} userData={userData} />,
    <MiscellaneousInfo key={3} userData={userData} />,
    <SocialAccounts key={4} userData={userData} />,
    <PayoutSettings key={5} />,
    <CloseAccount key={6} userData={userData} />,
  ]

  const trail = useTrail(items.length, {
    config: {
      mass: 0.5,
      tension: 1200,
      friction: 100,
    },
    from: { left: '-16px', opacity: 0 },
    opacity: 1,
    left: '0px',
  })

  return (
    <Wrapper>
      {userData && (
        <div className='content'>
          {trail.map((props, key) => (
            <animated.div key={key} style={{ ...props, position: 'relative' }}>
              <section>{items[key]}</section>
            </animated.div>
          ))}
        </div>
      )}
    </Wrapper>
  )
}

const AccountDetails = ({ userData }) => {
  const submitRef = useRef(0)
  const { updateCurrentUser } = useContext(UserContext)
  // status for SavingIndicator component (possible values: undefined, saving, saved, error)
  const [savingStatus, setSavingStatus] = useState(undefined)

  const initialValues = {
    firstName: userData?.firstName ? userData?.firstName : '',
    lastName: userData?.lastName ? userData?.lastName : '',
    email: userData?.email ? userData?.email : '',
  }

  const schema = yup.object().shape({
    firstName: yup.string().required('Required').min(2, 'Too short'),
    lastName: yup.string().required('Required').min(2, 'Too short'),
    email: yup.string().email('Invalid email').required('Required'),
  })

  const handleSubmit = async (values, { setStatus }) => {
    const data = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
    }
    setSavingStatus('saving')
    // increment submitRef
    submitRef.current++
    // get current submitRef id
    const thisSubmit = submitRef.current
    setTimeout(async () => {
      // check if this is still the latest submit
      if (thisSubmit === submitRef.current) {
        setStatus('')
        const result = await updateCurrentUser(data)
        if (result.error) {
          setStatus(result.error)
          setSavingStatus('error')
          setTimeout(() => {
            setSavingStatus(undefined)
          }, 2000)
        } else {
          setSavingStatus('saved')
          setTimeout(() => {
            setSavingStatus(false)
          }, 2000)
        }
      }
    }, 300)
  }

  return (
    <div id='account' className='settings-section'>
      <h2>
        Account Details <SavingIndicator savingStatus={savingStatus} />
      </h2>
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
        {({ status }) => (
          <Form>
            {status && <Alert message={status} type='error' showIcon />}
            <Row gutter={12}>
              <Col md={12} xs={24}>
                <FormItem name='firstName' label='First Name'>
                  <Input name='firstName' />
                </FormItem>
              </Col>
              <Col md={12} xs={24}>
                <FormItem name='lastName' label='Last Name'>
                  <Input name='lastName' />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col md={12} xs={24}>
                <FormItem name='email' label='Email'>
                  <Input name='email' />
                </FormItem>
              </Col>
              <Col md={12} xs={24}>
                <FormItem
                  label='Phone Number'
                  info='We require a verified phone number for SMS notifications.'>
                  <PhoneInput />
                </FormItem>
              </Col>
            </Row>
            <AutoSave />
          </Form>
        )}
      </Formik>
      <Password />
    </div>
  )
}

const MiscellaneousInfo = ({ userData }) => {
  const { updateCreatorProfile } = useContext(UserContext)
  const { getCategories } = useContext(GlobalContext)

  const [savingStatus, setSavingStatus] = useState(undefined)

  const submitRef = useRef(0)

  const { data: categories } = useQuery('categories', getCategories)

  const initialValues = {
    birthDate: userData.creatorProfile?.birthDate || '',
    gender: userData.creatorProfile?.gender || '',
    niches: userData.creatorProfile?.niches?.map(({ category }) => category.id),
  }

  const schema = yup.object().shape({
    birthDate: yup.string().required('Required'),
    gender: yup.string(),
    niches: yup.array().min(1, 'Select at least 1').max(4, 'Select a maximum of 4'),
  })

  const handleSubmit = async (data, { setStatus }) => {
    setSavingStatus('saving')
    // increment submitRef
    submitRef.current++
    // get current submitRef id
    const thisSubmit = submitRef.current
    setTimeout(async () => {
      // check if this is still the latest submit
      if (thisSubmit === submitRef.current) {
        setStatus('')
        const result = await updateCreatorProfile(data)
        if (result.error) {
          setStatus(result.error)
          setSavingStatus('error')
          setTimeout(() => {
            setSavingStatus(undefined)
          }, 2000)
        } else {
          setSavingStatus('saved')
          setTimeout(() => {
            setSavingStatus(false)
          }, 2000)
        }
      }
    }, 300)
  }

  return (
    <div id='misc' className='settings-section'>
      <h2>
        Miscellaneous Info <SavingIndicator savingStatus={savingStatus} />
      </h2>
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
        {({ status, values, setValues }) => (
          <Form>
            {status && <Alert message={status} type='error' showIcon />}
            <Row gutter={12}>
              <Col md={12} xs={24}>
                <FormItem label='Date of birth' name='birthDate'>
                  <DatePicker
                    getPopupContainer={trigger => trigger.parentNode}
                    format='MMMM D, Y'
                    name='birthDate'
                    allowClear={false}
                    style={{ width: '100%' }}
                    disabledDate={current => current.isAfter(moment())}
                    onChange={e => {
                      setValues({ ...values, birthDate: e?.toISOString() })
                    }}
                  />
                </FormItem>
              </Col>
              <Col md={12} xs={24}>
                <FormItem label='Gender' name='gender'>
                  <Select
                    getPopupContainer={trigger => trigger.parentNode}
                    virtual={false}
                    name='gender'
                    placeholder='Select'
                    showArrow
                    allowClear
                    optionFilterProp='label'
                    style={{ width: '100%' }}
                    onChange={e => {
                      setValues(prev => ({ ...prev, gender: e || '' }))
                    }}>
                    <Option value='female'>Female</Option>
                    <Option value='male'>Male</Option>
                    <Option value='non-binary'>Non-binary</Option>
                  </Select>
                </FormItem>
              </Col>
            </Row>
            <FormItem label='Niches' sublabel='(areas of influence)' name='niches'>
              <Select
                getPopupContainer={trigger => trigger.parentNode}
                virtual={false}
                name='niches'
                showArrow
                placeholder='Select'
                optionFilterProp='label'
                filterOption
                mode='multiple'
                style={{ width: '100%' }}
                loading={!categories?.length}
                onChange={e => {
                  setValues(prev => ({ ...prev, niches: e }))
                }}>
                {categories?.map(category => (
                  <Option label={category.title} key={category.id} value={category.id}>
                    {category.title}
                  </Option>
                ))}
              </Select>
            </FormItem>
            <AutoSave />
          </Form>
        )}
      </Formik>
    </div>
  )
}

const Shipping = ({ userData }) => {
  const { updateCreatorProfile } = useContext(UserContext)
  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])
  const [savingStatus, setSavingStatus] = useState(undefined)

  const submitRef = useRef(0)

  const initialValues = {
    shippingAddress1: userData?.creatorProfile?.shippingAddress1 || '',
    shippingAddress2: userData?.creatorProfile?.shippingAddress2 || '',
    shippingCity: userData?.creatorProfile?.shippingCity || '',
    shippingRegion: userData?.creatorProfile?.shippingRegion || '',
    shippingCountry: userData?.creatorProfile?.shippingCountry || '',
    shippingPostcode: userData?.creatorProfile?.shippingPostcode || '',
  }

  const handleSubmit = async (data, { setStatus }) => {
    setSavingStatus('saving')
    // increment submitRef
    submitRef.current++
    // get current submitRef id
    const thisSubmit = submitRef.current
    setTimeout(async () => {
      // check if this is still the latest submit
      if (thisSubmit === submitRef.current) {
        setStatus('')
        const result = await updateCreatorProfile(data)
        if (result.error) {
          setStatus(result.error)
          setSavingStatus('error')
          setTimeout(() => {
            setSavingStatus(undefined)
          }, 2000)
        } else {
          setSavingStatus('saved')
          setTimeout(() => {
            setSavingStatus(false)
          }, 2000)
        }
      }
    }, 300)
  }

  useEffect(() => {
    axios
      .get('../json/states.json')
      .then(res => {
        setStates(res.data.states)
      })
      .catch(() => {}) // TODO: handle error
  }, [])

  useEffect(() => {
    axios
      .get('../json/countries.json')
      .then(res => {
        setCountries(res.data.countries)
      })
      .catch(() => {}) // TODO: handle error
  }, [])

  const schema = yup.object().shape({
    shippingAddress1: yup.string().min(2, 'Too short').required('Required'),
    shippingAddress2: yup.string(),
    shippingCity: yup.string().min(2, 'Too short').required('Required'),
    shippingRegion: yup.string().required('Required'),
    shippingCountry: yup.string().required('Required'),
    shippingPostcode: yup.string().required('Required'),
  })

  return (
    <div id='shipping' className='settings-section'>
      <h2>
        Shipping Address <SavingIndicator savingStatus={savingStatus} />
      </h2>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
        {({ status, values, setValues, setFieldValue }) => (
          <Form>
            {status && <Alert message={status} type='error' showIcon />}
            <Row gutter={12}>
              <Col md={12} xs={24}>
                <FormItem name='shippingAddress1' label='Street'>
                  <Input name='shippingAddress1' />
                </FormItem>
              </Col>
              <Col md={12} xs={24}>
                <FormItem name='shippingAddress2' label='Apt / Suite / Unit'>
                  <Input name='shippingAddress2' placeholder='Optional' />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col md={12} xs={24}>
                <FormItem label='Country' name='shippingCountry'>
                  <Select
                    getPopupContainer={trigger => trigger.parentNode}
                    virtual={false}
                    name='shippingCountry'
                    showSearch
                    autoComplete='none'
                    style={{ width: '100%' }}
                    listHeight={256}
                    optionFilterProp='label'
                    onChange={e => {
                      setValues({ ...values, shippingCountry: e })
                      setFieldValue('shippingRegion', '', true)
                    }}>
                    {countries.map(country => (
                      <Option key={country.id} value={country.country_code} label={country.name}>
                        {country.name}
                      </Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
              <Col md={12} xs={24}>
                <FormItem label='State / Province' name='shippingRegion'>
                  <Select
                    getPopupContainer={trigger => trigger.parentNode}
                    virtual={false}
                    name='shippingRegion'
                    showSearch
                    required={true}
                    autoComplete='none'
                    style={{ width: '100%' }}
                    listHeight={256}
                    optionFilterProp='label'
                    onChange={e => {
                      setValues({ ...values, shippingRegion: e })
                    }}>
                    {states
                      .filter(state => state.country_code === values.shippingCountry)
                      .map(state => (
                        <Option key={state.id} value={state.name} label={state.name}>
                          {state.name}
                        </Option>
                      ))}
                  </Select>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col md={12} xs={24}>
                <FormItem name='shippingCity' label='City'>
                  <Input name='shippingCity' />
                </FormItem>
              </Col>
              <Col md={12} xs={24}>
                <FormItem name='shippingPostcode' label='Postcode'>
                  <Input name='shippingPostcode' />
                </FormItem>
              </Col>
            </Row>
            <AutoSave />
          </Form>
        )}
      </Formik>
    </div>
  )
}

const Password = () => {
  const { updateCurrentUser } = useContext(UserContext)
  const [modalOpen, setModalOpen] = useState(false)

  const initialValues = {
    currentPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
  }

  const handleSubmit = async (data, { setStatus }) => {
    setStatus('')

    const result = await updateCurrentUser({
      currentPassword: data.currentPassword,
      password: data.newPassword,
    })
    if (result.error) {
      message.error('Current password entered is incorrect')
    } else {
      message.success('Password updated')
      setModalOpen(false)
    }
  }

  const schema = yup.object().shape({
    currentPassword: yup.string().required('Current password is required'),
    newPassword: yup
      .string()
      .required('New password is required')
      .min(8, 'Please enter at least 8 characters'),
    newPasswordConfirm: yup.string().oneOf([yup.ref('newPassword'), null], "Passwords don't match"),
  })

  return (
    <>
      <div className='password-inner'>
        <h3>Password</h3>
        <Button type='link' icon={<EditOutlined />} onClick={() => setModalOpen(prev => !prev)}>
          Change...
        </Button>
      </div>
      <Modal
        title='Change Password'
        open={modalOpen}
        destroyOnClose
        onCancel={() => {
          setModalOpen(false)
        }}
        footer={false}>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
          {({ isSubmitting, status }) => (
            <ModalWrapper>
              <Form>
                {!status ||
                  status.map((err, key) => (
                    <Alert key={key} message={err.msg} type='error' showIcon />
                  ))}
                <FormItem name='currentPassword' label='Current password'>
                  <Input.Password name='currentPassword' />
                </FormItem>

                <FormItem name='newPassword' label='New password'>
                  <Input.Password name='newPassword' />
                </FormItem>

                <FormItem name='newPasswordConfirm' label='Confirm new password'>
                  <Input.Password name='newPasswordConfirm' />
                </FormItem>

                <div className='buttons'>
                  <Button
                    type='secondary'
                    onClick={() => {
                      setModalOpen(false)
                    }}>
                    Cancel
                  </Button>
                  <Button
                    htmlType='submit'
                    loading={isSubmitting}
                    type='primary'
                    icon={<SaveOutlined />}>
                    Save
                  </Button>
                </div>
              </Form>
            </ModalWrapper>
          )}
        </Formik>
      </Modal>
    </>
  )
}

const CloseAccount = ({ userData }) => {
  const { closeAccount } = useContext(UserContext)
  const [modalOpen, setModalOpen] = useState(false)

  const initialValues = {
    reason: '',
    agreement: false,
  }

  const schema = yup.object().shape({
    reason: yup.string().required('Required'),
    agreement: yup
      .boolean()
      .oneOf([true], 'Please indicate you understand the consequences of deleting your account.'),
  })

  const handleSubmit = async (data, { setStatus }) => {
    setStatus('')
    // displaying a brief message saying "Deleting your account..." with loading spinner
    message.loading('Deleting your account...', 2)
    // using setTimeout to simulate a delay (otherwise it redirects to login abruptly)
    setTimeout(async () => {
      const result = await closeAccount(userData.id, data)
      if (result.error) setStatus(result.error)
      else {
        setModalOpen(false)
        message.success('Account deleted. We hope to see you again soon!')
      }
    }, 2000)
  }

  return (
    <div className='danger-zone'>
      <h2>Danger Zone</h2>
      <Button type='danger' onClick={() => setModalOpen(true)}>
        Delete Account...
      </Button>
      <Modal
        title='Delete Account'
        open={modalOpen}
        destroyOnClose
        footer={false}
        onCancel={() => {
          setModalOpen(false)
        }}>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
          {({ values, setValues, isSubmitting, status }) => (
            <ModalWrapper>
              <Form>
                <p>Before you go, please let us know what we could do better.</p>
                {status && <Alert message={status} type='error' showIcon />}
                <FormItem name='reason'>
                  <Input.TextArea name='reason' placeholder='Why are you deleting your account?' />
                </FormItem>
                <Checkbox
                  name='agreement'
                  onChange={e => {
                    setValues({
                      ...values,
                      agreement: e.target.checked,
                    })
                  }}>
                  I understand that my account will be permanently deleted along with my campaign
                  history.
                </Checkbox>
                <div className='buttons'>
                  <Button
                    type='secondary'
                    onClick={() => {
                      setModalOpen(false)
                    }}
                    danger>
                    Cancel
                  </Button>
                  <Button
                    htmlType='submit'
                    type='danger'
                    loading={isSubmitting}
                    disabled={!values.agreement}>
                    Delete Account
                  </Button>
                </div>
              </Form>
            </ModalWrapper>
          )}
        </Formik>
      </Modal>
    </div>
  )
}

const AutoSave = () => {
  const { dirty, values, errors, submitForm } = useFormikContext()

  useEffect(() => {
    // auto saves only if form has been interacted with and there are no errors
    // dirty is false by default, becomes true once form has been interacted with
    if (dirty && !Object.keys(errors).length) {
      submitForm()
    }
  }, [dirty, values, errors, submitForm])

  return null
}

const Wrapper = styled.div`
  .content {
    max-width: 800px;
    scroll-behavior: smooth;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    section {
      background: #fff;
      padding: 20px;
      h2 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        border-bottom: 1px solid #e6e6e6;
        font-size: 1.1rem;
      }
      .settings-section {
        scroll-margin-top: 100px;
      }
      .ant-form {
        max-width: 800px;
        margin: auto;
      }
      .password-inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin: 20px auto 10px auto;
        max-width: 800px;
        border: 1px solid #d9d9d9;
        border-radius: 3px;
        padding: 5px 10px;
        h3 {
          margin: 0;
        }
        .title {
          font-size: 18px;
        }
      }
      .password-inner > button {
        font-size: 15px;
        margin-bottom: 0;
      }
      .password-inner span {
        margin-left: 5px;
      }
      .danger-zone {
        max-width: 800px;
      }
    }
  }

  #payouts {
    min-height: 600px;
  }

  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    .content {
      grid-gap: 20px;
      section {
        padding: 40px;
      }
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpointDesktopWide}) {
    .content {
      margin: 40px auto;
      padding-right: 10px;
      section {
        border-radius: 5px;
      }
    }
  }
`

const ModalWrapper = styled.div`
  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
    margin-top: 40px;
  }
`

export default ProfileSettings
