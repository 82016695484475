import baseball from '../../../../assets/images/backgrounds/baseball.jpg'
import basketball from '../../../../assets/images/backgrounds/basketball.jpg'
import cottonCandy from '../../../../assets/images/backgrounds/cotton-candy.svg'
import gradientWave from '../../../../assets/images/backgrounds/gradient-wave.svg'
import tech from '../../../../assets/images/backgrounds/tech-background.svg'
import creatorcoLogoWhite from '../../../../assets/images/primary-logo-white.png'
import creatorcoLogoBlack from '../../../../assets/images/primary-logo.svg'
import userDark from '../../../../assets/images/stock-photos/user-dark.svg'
import userLight from '../../../../assets/images/stock-photos/user-light.svg'

const slickDotInactiveLight = 'rgba(0, 0, 0, 0.2)'
const slickDotActiveLight = '#080830'
const slickDotInactiveDark = 'rgba(255, 255, 255, 0.2)'
const slickDotActiveDark = '#f7f7f7'

export const themes = {
  // LIGHT THEMES
  lightTheme: {
    profilePlaceholder: userLight,
    primaryColor100: '#027df0', // profile image gradient, buttons and colored text
    primaryColor200: '#027df026',
    primaryColor300: '#EEF6FE',
    primaryColor400: '#fff',
    accentColor: '#ff668c', // profile image gradient
    backgroundPattern: `url(${cottonCandy}) no-repeat center / cover`,
    primaryTextColor: '#23262F',
    secondaryTextColor: '#777e90',
    lightTransparency: 'rgba(255, 255, 255, 0.7)',
    darkTransparency: 'rgba(0, 0, 0, 0.1)',
    glassEffect: 'inset 0px 0px 50px rgba(255, 255, 255, 0.7)',
    slickDotInactive: slickDotInactiveLight,
    slickDotActive: slickDotActiveLight,
    creatorcoLogo: creatorcoLogoBlack,
  },

  brightTheme: {
    profilePlaceholder: userLight,
    primaryColor100: '#027df0',
    primaryColor200: '#027df026',
    primaryColor300: '#EEF6FE',
    primaryColor400: '#fff',
    accentColor: '#FF7A00',
    backgroundPattern: `radial-gradient(100% 244.46% at 0% 0%, #CCFF00 0%, #FF027C 100%), radial-gradient(50% 122.23% at 50% 50%, #9AA4FF 0%, #306C00 100%), radial-gradient(100.45% 245.58% at 0% 0%, #000AFE 0%, #70FF00 100%), linear-gradient(127.43deg, #7B0007 0%, #8F73FF 100%);
        background-blend-mode: lighten, color-dodge, difference, normal;`,
    primaryTextColor: '#23262F',
    secondaryTextColor: '#777e90',
    lightTransparency: 'rgba(255, 255, 255, 0.7)',
    darkTransparency: 'rgba(0, 0, 0, 0.1)',
    glassEffect: 'inset 0px 0px 50px rgba(255, 255, 255, 0.7)',
    slickDotInactive: slickDotInactiveLight,
    slickDotActive: slickDotActiveLight,
    creatorcoLogo: creatorcoLogoBlack,
  },

  dreamyTheme: {
    profilePlaceholder: userLight,
    primaryColor100: '#027df0',
    primaryColor200: '#027df026',
    primaryColor300: '#EEF6FE',
    primaryColor400: '#fff',
    accentColor: '#ff668c',
    backgroundPattern: `radial-gradient(80.99% 100% at 50% 0%, #00FF0A 0%, #36008E 100%), radial-gradient(50% 123.47% at 50% 50%, #EFE7C8 0%, #36008E 100%), linear-gradient(301.28deg, #FF006B 0%, #48DD9E 100%), linear-gradient(294.84deg, #5A60E4 0%, #D30000 100%), linear-gradient(52.29deg, #000000 0%, #00FF85 100%), radial-gradient(100% 138.69% at 100% 0%, #0007A5 0%, #FF7A00 100%), radial-gradient(70.41% 100% at 50% 0%, #D5B300 0%, #2200AA 100%);
        background-blend-mode: screen, screen, lighten, overlay, lighten, difference, normal;`,
    primaryTextColor: '#23262F',
    secondaryTextColor: '#777e90',
    lightTransparency: 'rgba(255, 255, 255, 0.7)',
    darkTransparency: 'rgba(0, 0, 0, 0.1)',
    glassEffect: 'inset 0px 0px 50px rgba(255, 255, 255, 0.7)',
    slickDotInactive: slickDotInactiveLight,
    slickDotActive: slickDotActiveLight,
    creatorcoLogo: creatorcoLogoBlack,
  },

  gradientWave: {
    profilePlaceholder: userLight,
    primaryColor100: '#FF87CF',
    primaryColor200: '#FF87CF26',
    primaryColor300: '#E9F2FB',
    primaryColor400: '#fff',
    accentColor: '#C3DBF3',
    backgroundPattern: `url(${gradientWave}) no-repeat center / cover`,
    primaryTextColor: '#23262F',
    secondaryTextColor: '#777e90',
    lightTransparency: 'rgba(255, 255, 255, 0.7)',
    darkTransparency: 'rgba(0, 0, 0, 0.1)',
    glassEffect: 'inset 0px 0px 50px rgba(255, 255, 255, 0.7)',
    slickDotInactive: slickDotInactiveLight,
    slickDotActive: slickDotActiveLight,
    creatorcoLogo: creatorcoLogoBlack,
  },

  // DARK THEMES
  darkTheme: {
    profilePlaceholder: userDark,
    primaryColor100: '#027df0',
    primaryColor200: '#027df026',
    primaryColor300: '#23262F',
    primaryColor400: '#111',
    accentColor: '#0f0f57',
    backgroundPattern: `radial-gradient(100% 225% at 100% 0%, #FF0000 0%, #000000 100%), linear-gradient(236deg, #00C2FF 0%, #000000 100%), linear-gradient(135deg, #CDFFEB 0%, #CDFFEB 36%, #009F9D 36%, #009F9D 60%, #07456F 60%, #07456F 67%, #0F0A3C 67%, #0F0A3C 100%);
        background-blend-mode: overlay, hard-light, normal;`,
    primaryTextColor: '#f7f7f7',
    secondaryTextColor: '#777e90',
    lightTransparency: 'rgba(255, 255, 255, 0.1)',
    darkTransparency: 'rgba(0, 0, 0, 0.7)',
    glassEffect: 'inset 0px 0px 50px rgba(255, 255, 255, 0.2)',
    slickDotInactive: slickDotInactiveDark,
    slickDotActive: slickDotActiveDark,
    creatorcoLogo: creatorcoLogoWhite,
  },

  techTheme: {
    profilePlaceholder: userDark,
    primaryColor100: '#027df0',
    primaryColor200: '#027df026',
    primaryColor300: '#23262F',
    primaryColor400: '#111',
    accentColor: '#0f0f57',
    backgroundPattern: `url(${tech}) no-repeat center / cover`,
    primaryTextColor: '#f7f7f7',
    secondaryTextColor: '#777e90',
    lightTransparency: 'rgba(255, 255, 255, 0.1)',
    darkTransparency: 'rgba(0, 0, 0, 0.7)',
    glassEffect: 'inset 0px 0px 50px rgba(255, 255, 255, 0.2)',
    slickDotInactive: slickDotInactiveDark,
    slickDotActive: slickDotActiveDark,
    creatorcoLogo: creatorcoLogoWhite,
  },

  sunsetTheme: {
    profilePlaceholder: userDark,
    primaryColor100: '#027df0',
    primaryColor200: '#027df026',
    primaryColor300: '#23262F',
    primaryColor400: '#111',
    accentColor: '#0f0f57',
    backgroundPattern: `linear-gradient(45deg, #000850 0%, #000320 100%), radial-gradient(100% 225% at 100% 0%, #FF6928 0%, #000000 100%), linear-gradient(225deg, #FF7A00 0%, #000000 100%), linear-gradient(135deg, #CDFFEB 10%, #CDFFEB 35%, #009F9D 35%, #009F9D 60%, #07456F 60%, #07456F 67%, #0F0A3C 67%, #0F0A3C 100%);
        background-blend-mode: screen, overlay, hard-light, normal;`,
    primaryTextColor: '#f7f7f7',
    secondaryTextColor: '#777e90',
    lightTransparency: 'rgba(255, 255, 255, 0.1)',
    darkTransparency: 'rgba(0, 0, 0, 0.7)',
    glassEffect: 'inset 0px 0px 50px rgba(255, 255, 255, 0.2)',
    slickDotInactive: slickDotInactiveDark,
    slickDotActive: slickDotActiveDark,
    creatorcoLogo: creatorcoLogoWhite,
  },

  // SPORT THEMES
  baseballTheme: {
    profilePlaceholder: userLight,
    primaryColor100: '#146D38',
    primaryColor200: '#146D3826',
    primaryColor300: '#f0f4f2',
    primaryColor400: '#fff',
    accentColor: '#ffd700',
    backgroundPattern: `url(${baseball}) no-repeat center / cover`,
    primaryTextColor: '#23262F',
    secondaryTextColor: '#777e90',
    lightTransparency: 'rgba(255, 255, 255, 0.7)',
    darkTransparency: 'rgba(0, 0, 0, 0.1)',
    glassEffect: 'inset 0px 0px 50px rgba(255, 255, 255, 0.7)',
    slickDotInactive: slickDotInactiveLight,
    slickDotActive: slickDotActiveLight,
    creatorcoLogo: creatorcoLogoBlack,
  },

  basketballTheme: {
    profilePlaceholder: userLight,
    primaryColor100: '#005BBE',
    primaryColor200: '#005BBE26',
    primaryColor300: '#EEF6FE',
    primaryColor400: '#fff',
    accentColor: '#d4522a',
    backgroundPattern: `url(${basketball}) no-repeat center / cover`,
    primaryTextColor: '#23262F',
    secondaryTextColor: '#777e90',
    lightTransparency: 'rgba(255, 255, 255, 0.7)',
    darkTransparency: 'rgba(0, 0, 0, 0.1)',
    glassEffect: 'inset 0px 0px 50px rgba(255, 255, 255, 0.7)',
    slickDotInactive: slickDotInactiveLight,
    slickDotActive: slickDotActiveLight,
    creatorcoLogo: creatorcoLogoBlack,
  },
}
