import { SearchOutlined, LoadingOutlined } from '@ant-design/icons'
import { Empty, Button, Drawer } from 'antd'
import axios from 'axios'
import { Formik } from 'formik'
import { Form, Input } from 'formik-antd'
import React, { useEffect, useRef, useState, useContext } from 'react'
import { useInfiniteQuery, useQueryClient } from 'react-query'
import styled, { keyframes } from 'styled-components'

import CampaignsFilters from './CampaignsFilters'
import CampaignsInner from './CampaignsInner'
import { GhostCampaignCard } from './GhostCampaignCard'
import filterIcon from '../../../assets/images/icons/filter.svg'
import { API_URL } from '../../../constants'
import { CampaignContext } from '../../../contexts/CampaignContext'
import { useMetaData } from '../../../custom-hooks/useMetaData'

const Campaigns = () => {
  useMetaData(
    'Collaboration Hub',
    'Explore hundreds of social media collaborations, opt in, and track your progress!',
    'https://creatorco.nyc3.cdn.digitaloceanspaces.com/assets/preview-images/meta-preview.png'
  )

  // get search params from url for brand id to set filters to that brand's campaigns
  const urlParams = new URLSearchParams(window.location.search)
  const brandId = urlParams.get('brand')

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  const queryClient = useQueryClient()
  const { formData, setFormData } = useContext(CampaignContext)

  useEffect(() => {
    // automatically filter for brand's campaigns if brandId is found in url
    if (brandId) {
      setFormData(prev => ({ ...prev, brands: [brandId] }))
    }
  }, [brandId, setFormData])

  const fetchCampaigns = async pageParam => {
    const { data } = await axios.post(
      `${API_URL}/public/collab-hub/campaigns`,
      {
        // filters
        brands: formData.brands,
        contentFormats: formData.contentFormats,
        categories: formData.categories,
        socialChannel: formData.socialChannel,
        age: formData.age,
        instagramReach: formData.instagramReach,
        youtubeReach: formData.youtubeReach,
        tiktokReach: formData.tiktokReach,
        instagramEngagement: formData.instagramEngagement,
        youtubeEngagement: formData.youtubeEngagement,
        tiktokEngagement: formData.tiktokEngagement,
        gender: formData.gender,
        country: formData.country,
        search: formData.search,
        strategy: formData.strategy,
        maxPaidAmount: formData.maxPaidAmount,
      },
      {
        params: {
          sort: formData.sort,
          page: pageParam,
        },
      }
    )

    return data
  }

  const { data, fetchNextPage, isFetchingNextPage, isFetchingMore, status, hasNextPage } =
    useInfiniteQuery(
      ['campaigns', formData],
      async ({ pageParam = 0 }) => fetchCampaigns(pageParam),
      {
        getNextPageParam: lastPage => lastPage.nextCursor,
      }
    )

  const formRef = useRef()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const scrollRef = useRef()

  const handleScroll = () => {
    if (scrollRef.current?.offsetHeight - window.scrollY < 1000) {
      if (hasNextPage && !isFetchingMore && scrollRef.current) {
        fetchNextPage()
      }
    }
  }

  const submitRef = useRef(0)

  const handleSubmit = async data => {
    submitRef.current++
    const thisSubmit = submitRef.current
    setTimeout(() => {
      if (thisSubmit === submitRef.current) {
        const { paid, reach, ...campaignParams } = data
        if (paid) {
          campaignParams['maxPaidAmount'] = true
        }
        campaignParams['reach'] = reach < 20000 ? reach : undefined
        queryClient.removeQueries('campaigns')
        setFormData(campaignParams)
      }
    }, 300)
  }

  return (
    <Wrapper>
      <Formik initialValues={formData} onSubmit={handleSubmit} innerRef={formRef}>
        {({ values, setValues, submitForm, resetForm }) => (
          <Form className='campaigns-wrapper'>
            <aside className='campaigns-sidebar'>
              <div className='sidebar-inner'>
                <h2>Refine Search</h2>
                <CampaignsFilters submitForm={submitForm} values={values} setValues={setValues} />
                <div className='results'>
                  {status === 'success' ? (
                    <p
                      className='num-results'
                      style={{
                        fontWeight: 'bold',
                        color: data?.pages[0]?.totalResults > 0 ? '#027df0' : '#999',
                        whiteSpace: 'pre',
                        margin: '0',
                        fontSize: '0.8rem',
                      }}>
                      {data?.pages[0]?.totalResults || 0} result
                      {data?.pages[0]?.totalResults !== 1 && 's'}
                    </p>
                  ) : (
                    <LoadingOutlined spin />
                  )}
                  <Button
                    type='secondary'
                    onClick={() => {
                      resetForm()
                      setFormData({ sort: 'date-desc', available: true })
                      setValues({ sort: 'date-desc', available: true })
                    }}>
                    Reset
                  </Button>
                </div>
              </div>
            </aside>

            <main>
              <div className='campaigns-header'>
                <h1>Collaboration Hub</h1>
                <p className='tagline'>
                  Explore endless partnership opportunities - from paid campaigns to giveaways and
                  affiliate programs. Opt in to campaigns you&apos;re interested in and track your
                  progress!
                </p>
                <div className='filters'>
                  <div className='search'>
                    <Input
                      data-cy='search-campaigns'
                      type='text'
                      name='search'
                      placeholder='Search campaigns'
                      prefix={<SearchOutlined />}
                      allowClear
                      onChange={submitForm}
                      onPressEnter={e => e.preventDefault()}
                    />
                  </div>
                  {/* MOBILE FILTER MENU DRAWER */}
                  {/* FIXME: campaigns reload (form submits) when opening drawer */}
                  <button
                    className='view-filters-btn'
                    aria-label='Open Filters'
                    onClick={e => {
                      e.stopPropagation()
                      setDrawerOpen(true)
                    }}>
                    <img className='icon' src={filterIcon} alt='' />
                  </button>
                  <Drawer
                    title='Filters'
                    open={drawerOpen}
                    onClose={() => setDrawerOpen(false)}
                    extra={
                      <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                        {status === 'success' ? (
                          <p
                            className='num-results'
                            style={{
                              fontWeight: 'bold',
                              color: data?.pages[0].totalResults > 0 ? '#027df0' : '#999',
                              whiteSpace: 'pre',
                              margin: '0',
                              fontSize: '0.8rem',
                            }}>
                            {data?.pages[0]?.totalResults || 0} result
                            {data?.pages[0]?.totalResults !== 1 && 's'}
                          </p>
                        ) : (
                          <LoadingOutlined spin />
                        )}
                        <Button
                          type='primary'
                          onClick={() => {
                            resetForm()
                            setFormData({ sort: 'date-desc', available: true })
                            setValues({ sort: 'date-desc', available: true })
                          }}>
                          Reset
                        </Button>
                      </div>
                    }>
                    <CampaignsFilters
                      submitForm={submitForm}
                      values={values}
                      setValues={setValues}
                    />
                  </Drawer>
                </div>
              </div>
              <div className='results' ref={scrollRef}>
                {status === 'success' ? (
                  data?.pages[0]?.totalResults ? (
                    <CampaignsInner pages={data.pages} fetchingMore={isFetchingNextPage} />
                  ) : (
                    <div className='no-campaigns'>
                      <Empty description='No campaigns found.' />
                      <Button
                        type='secondary'
                        onClick={() => {
                          setFormData({ sort: 'date-desc', available: true })
                          resetForm()
                        }}>
                        Reset Filters
                      </Button>
                    </div>
                  )
                ) : (
                  <div className='ghost-campaigns'>
                    {Array.from(Array(24).keys()).map(item => (
                      <GhostCampaignCard key={item} />
                    ))}
                  </div>
                )}
              </div>
            </main>
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

const gradientAnimation = keyframes`
    from {
        background-position: 0% 50%;
    }
    to {
        background-position: 108% 50%;
    }
`

const Wrapper = styled.div`
  background-color: #f4f4f8;
  flex: 1;
  display: flex;

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .campaigns-sidebar {
    display: none;
    background-color: #fff;
    border-right: 1px solid #e6e6e6;
    .sidebar-inner {
      padding: 20px;
      .results {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
      }
    }
  }
  main {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .campaigns-header {
    background-color: ${props => props.theme.crcoCottonCandyBlue};
    background: linear-gradient(
        -60deg,
        ${props => props.theme.crcoCottonCandyBlue},
        ${props => props.theme.crcoCottonCandyPink},
        ${props => props.theme.crcoCottonCandyBlue},
        ${props => props.theme.crcoCottonCandyPink},
        ${props => props.theme.crcoCottonCandyBlue},
        ${props => props.theme.crcoCottonCandyPink},
        ${props => props.theme.crcoCottonCandyBlue}
      )
      repeat;
    background-position: 105%;
    background-size: 1500% 1500%;
    @media (prefers-reduced-motion: no-preference) {
      animation: ${gradientAnimation} 20s ease-in-out infinite;
    }
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px 20px;
    h1 {
      text-align: center;
      margin: 0;
      font-family: 'Poppins-Bold', sans-serif;
      line-height: 1;
    }
    .tagline {
      font-family: 'Poppins-Medium', sans-serif;
      font-size: 14px;
      opacity: 0.6;
      text-align: center;
      max-width: 600px;
      margin: auto;
    }
    .filters {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 8px;
    }
    .search {
      flex: 1;
      max-width: 600px;
      .ant-input-affix-wrapper {
        background: rgba(255, 255, 255, 0.8);
        border: none;
        border-radius: 20px;
        padding: 10px 20px;
      }
      .ant-input {
        background: transparent;
      }
      .ant-input-prefix {
        margin-right: 8px;
      }
    }
    .view-filters-btn {
      background: rgba(255, 255, 255, 0.8);
      border: none;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      padding: 0;
      .icon {
        height: 20px;
        width: 20px;
      }
    }
  }
  .results {
    flex: 1;
    .fetching-more {
      display: grid;
      place-content: center;
      margin-bottom: 20px;
    }
    .no-campaigns {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 12px;
      height: 100%;
    }
  }
  .ghost-campaigns {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px 10px;
    gap: 20px;
    margin: auto;
    @media only screen and (min-width: 700px) {
      padding: 30px;
      gap: 30px;
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    h1 {
      font-size: 2rem;
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
    h1 {
      font-size: 3rem;
    }
    .campaigns-header {
      .search {
        .ant-input-affix-wrapper {
          padding: 15px 30px;
          border-radius: 30px;
        }
      }
    }
    .campaigns-sidebar {
      .sidebar-inner {
        padding: 20px 40px;
        height: calc(100vh - 50px);
        overflow: auto;
        ${props => props.theme.scrollbar};
      }
    }
  }
  @media only screen and (min-width: 1350px) {
    .campaigns-wrapper {
      display: grid;
      justify-content: center;
      grid-template-columns: 300px 1fr;
      min-height: calc(100vh - 200px);
      .campaigns-sidebar {
        display: block;
        .sidebar-inner {
          position: sticky;
          top: 50px;
        }
      }
      .campaigns-header {
        .view-filters-btn {
          display: none;
        }
      }
    }
  }
`

export default Campaigns
