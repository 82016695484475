import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import axios from 'axios'
import { Checkbox, Select, InputNumber } from 'formik-antd'
import { debounce } from 'lodash'
import React, { useEffect, useState, useContext } from 'react'
import { useInfiniteQuery, useQuery } from 'react-query'
import styled from 'styled-components/macro'

import affiliate from '../../../../src/assets/images/icons/campaigns/affiliate.svg'
import bespoke from '../../../../src/assets/images/icons/campaigns/bespoke.svg'
import giveaway from '../../../../src/assets/images/icons/campaigns/giveaway.svg'
import shoutout from '../../../../src/assets/images/icons/campaigns/shoutout.svg'
import ugc from '../../../../src/assets/images/icons/campaigns/ugc.svg'
import instagram from '../../../../src/assets/images/icons/social/instagram-color-square.svg'
import tiktok from '../../../../src/assets/images/icons/social/tiktok-color-square.svg'
import youtube from '../../../../src/assets/images/icons/social/youtube-color-square.svg'
import { API_URL } from '../../../constants'
import { GlobalContext } from '../../../contexts/GlobalContext'
import { UserContext } from '../../../contexts/UserContext'
import { findProfile } from '../../../utils'

const socialIcons = { instagram, youtube, tiktok }
const { Option } = Select

const CampaignsFilters = ({ submitForm, values, setValues }) => {
  const { fetchCurrentUser } = useContext(UserContext)
  const { getCategories } = useContext(GlobalContext)
  const { data: userData } = useQuery('user', fetchCurrentUser)

  const [brandsSearch, setBrandsSearch] = useState('')
  const {
    data: brandsData,
    status: brandsStatus,
    fetchNextPage: fetchMoreBrands,
    isFetchingNextPage: isFetchingMoreBrands,
    hasNextPage: hasMoreBrands,
  } = useInfiniteQuery(
    ['brands', brandsSearch],
    async ({ pageParam = 1 }) => {
      const { data } = await axios.get(`${API_URL}/public/brands/${pageParam}`, {
        params: {
          search: brandsSearch,
        },
      })
      return data
    },
    {
      getNextPageParam: lastPage => lastPage.nextPage,
    }
  )

  const { data: categories } = useQuery('categories', getCategories)

  const [countries, setCountries] = useState([])
  const platforms = ['instagram', 'youtube', 'tiktok']

  // calculate user age by given date of birth
  const calculateAge = dob => {
    if (!dob) {
      return null
    } else {
      const today = new Date()
      const birthDate = new Date(dob)
      let age = today.getFullYear() - birthDate.getFullYear()
      const m = today.getMonth() - birthDate.getMonth()
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age = age - 1
      }
      return age
    }
  }

  // user stats
  const userAge = calculateAge(userData?.creatorProfile?.birthDate)
  const userCountry = userData?.creatorProfile?.shippingCountry
  const userNiches = userData?.creatorProfile?.niches?.map(item => item.category.slug)

  // #region Functions
  useEffect(() => {
    axios
      .get('../json/countries.json')
      .then(res => {
        setCountries(res.data.countries)
      })
      .catch(() => {}) // TODO: handle error
  }, [])

  const filterByUserStats = () => {
    const instagramProfile = findProfile(userData?.socialProfiles, 'instagram')
    const tiktokProfile = findProfile(userData?.socialProfiles, 'tiktok')
    const youtubeProfile = findProfile(userData?.socialProfiles, 'youtube')
    const countryName = countries.find(country => country.country_code === userCountry)?.name

    setValues({
      ...values,
      instagramReach: instagramProfile?.followerCount,
      youtubeReach: youtubeProfile?.followerCount,
      tiktokReach: tiktokProfile?.followerCount,
      instagramEngagement: instagramProfile?.engagement,
      youtubeEngagement: youtubeProfile?.engagement,
      tiktokEngagement: tiktokProfile?.engagement,
      age: userAge,
      country: countryName,
      categories: userNiches,
    })
    submitForm()
  }

  // checks if platform removed from selection and clears reach & engagement filter for that platform
  const handlePlatformSelections = e => {
    setValues(prev => {
      const newValues = values
      if (prev.socialChannel === 'Instagram' && e !== 'Instagram') {
        newValues.instagramReach = undefined
        newValues.instagramEngagement = undefined
      }
      if (prev.socialChannel === 'YouTube' && e !== 'YouTube') {
        newValues.youtubeReach = undefined
        newValues.youtubeEngagement = undefined
      }
      if (prev.socialChannel === 'TikTok' && e !== 'TikTok') {
        newValues.tiktokReach = undefined
        newValues.tiktokEngagement = undefined
      }
      return { ...newValues, socialChannel: e }
    })
    submitForm(values)
  }
  // #endregion

  return (
    <Wrapper>
      <div className='item'>
        <Select
          getPopupContainer={trigger => trigger.parentNode}
          virtual={false}
          style={{ width: '100%' }}
          onChange={submitForm}
          name='sort'
          dropdownMatchSelectWidth={true}>
          <Option value='maxPaidAmount-desc'>
            <ArrowUpOutlined /> Highest paid campaigns
          </Option>
          <Option value='date-desc'>
            <ArrowDownOutlined /> Newest campaigns
          </Option>
          <Option value='date-asc'>
            <ArrowUpOutlined /> Oldest campaigns
          </Option>
          <Option value='followers-asc'>
            <ArrowDownOutlined /> Lowest followers
          </Option>
          <Option value='followers-desc'>
            <ArrowUpOutlined /> Highest followers
          </Option>
          <Option value='engagement-asc'>
            <ArrowDownOutlined /> Lowest engagement
          </Option>
          <Option value='engagement-desc'>
            <ArrowUpOutlined /> Highest engagement
          </Option>
        </Select>
      </div>
      <div className='item'>
        <Select
          data-cy='brands-filter'
          getPopupContainer={trigger => trigger.parentNode}
          virtual={false}
          name='brands'
          loading={brandsStatus === 'loading' || isFetchingMoreBrands}
          placeholder='Brands'
          style={{ width: '100%' }}
          showSearch
          showArrow
          allowClear
          filterOption={false}
          notFoundContent={brandsStatus === 'loading' ? 'Loading...' : 'No brands found'}
          onSearch={debounce(val => setBrandsSearch(val), 500)}
          onPopupScroll={e => {
            if (!hasMoreBrands) return
            e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight &&
              fetchMoreBrands()
          }}
          onChange={brandId => {
            // set url query to first selected brand
            const url = new URL(window.location.href)
            brandId ? url.searchParams.set('brand', brandId) : url.searchParams.delete('brand')
            window.history.pushState({}, '', url)
            submitForm()
          }}
          options={brandsData?.pages
            .flatMap(page => page.brands)
            .map(brand => ({
              value: brand.id,
              label: brand.name,
            }))}
        />
      </div>
      <div className='item checkbox'>
        <Checkbox name='paid' onChange={submitForm}>
          Only paid campaigns
        </Checkbox>
      </div>
      <h3>Deliverables</h3>
      <div className='item'>
        <Select
          getPopupContainer={trigger => trigger.parentNode}
          virtual={false}
          name='socialChannel'
          style={{ width: '100%' }}
          onChange={e => handlePlatformSelections(e)}
          placeholder='Platform'
          showArrow
          allowClear>
          {platforms.map(platform => (
            <Option key={platform} value={platform}>
              <img
                src={socialIcons[platform]}
                alt=''
                style={{ height: '20px', marginRight: '8px' }}
              />
              {platform === 'youtube'
                ? 'YouTube'
                : platform === 'tiktok'
                  ? 'TikTok'
                  : platform.charAt(0).toUpperCase() + platform.slice(1)}
            </Option>
          ))}
        </Select>
      </div>
      <div className='item'>
        <Select
          // getPopupContainer keeps the dropdown positioned relative to the parent so it doesn't move out of place when scrolling
          getPopupContainer={trigger => trigger.parentNode}
          // prevents page scrolling on touch screen devices
          virtual={false}
          name='strategy'
          style={{ width: '100%' }}
          onChange={submitForm}
          placeholder='Strategy'
          showArrow
          allowClear>
          <Option value='ugc'>
            <img src={ugc} alt='' style={{ height: '20px', marginRight: '8px' }} />
            UGC
          </Option>
          <Option value='shoutout'>
            <img src={shoutout} alt='' style={{ height: '20px', marginRight: '8px' }} />
            ShoutOut
          </Option>
          <Option value='giveaway'>
            <img src={giveaway} alt='' style={{ height: '20px', marginRight: '8px' }} />
            Giveaway
          </Option>
          <Option value='bespoke'>
            <img src={bespoke} alt='' style={{ height: '20px', marginRight: '8px' }} />
            Bespoke
          </Option>
          <Option value='affiliate'>
            <img src={affiliate} alt='' style={{ height: '20px', marginRight: '8px' }} />
            Affiliate
          </Option>
        </Select>
      </div>

      <div className='item'>
        <Select
          getPopupContainer={trigger => trigger.parentNode}
          virtual={false}
          name='contentFormats'
          style={{ width: '100%' }}
          mode='multiple'
          onChange={submitForm}
          placeholder='Content type'
          showArrow
          allowClear>
          <Option value='Video Post'>Video</Option>
          <Option value='Shorts'>Shorts</Option>
          <Option value='Post'>Post</Option>
          <Option value='Story'>Story</Option>
          <Option value='Reel'>Reel</Option>
          <Option value='IGTV'>IGTV</Option>
        </Select>
      </div>
      <h3>Qualifications</h3>
      {values.socialChannel ? (
        <div className='platform-reputation-filters'>
          <p>
            <img
              src={socialIcons[values.socialChannel]}
              alt=''
              style={{ height: '20px', marginRight: '5px' }}
            />
            {values.socialChannel === 'youtube'
              ? 'YouTube'
              : values.socialChannel === 'tiktok'
                ? 'TikTok'
                : values.socialChannel.charAt(0).toUpperCase() + values.socialChannel.slice(1)}
          </p>
          <div className='items'>
            <div className='item'>
              <span className='title' style={{ fontSize: '0.8rem' }}>
                {values.socialChannel === 'YouTube' ? 'Subscribers' : 'Followers'}
              </span>
              <InputNumber
                name={`${values.socialChannel}Reach`}
                placeholder='Max'
                onChange={submitForm}
                style={{ width: '100%' }}
              />
            </div>
            <div className='item'>
              <span className='title' style={{ fontSize: '0.8rem' }}>
                Engagement
              </span>
              <InputNumber
                name={`${values.socialChannel}Engagement`}
                formatter={value => value && `${value}%`}
                parser={value => value.replace('%', '')}
                placeholder='Max'
                onChange={submitForm}
                style={{ width: '100%' }}
              />
            </div>
          </div>
        </div>
      ) : (
        <p className='info-text'>
          Select social platform above to set follower & engagement criteria.
        </p>
      )}
      <div className='item'>
        <InputNumber
          name='age'
          min={13}
          placeholder='Age (13+)'
          formatter={value => value && `Age: ${value}`}
          parser={value => value.replace('Age: ', '')}
          onChange={e => {
            if (e < 13 || e > 100) return
            submitForm()
          }}
          style={{ width: '100%' }}
        />
      </div>
      <div className='item'>
        <Select
          name='gender'
          placeholder='Gender'
          allowClear
          onChange={submitForm}
          style={{ width: '100%' }}>
          <Option value='female'>Female</Option>
          <Option value='male'>Male</Option>
          <Option value='non-binary'>Non-binary</Option>
        </Select>
      </div>
      <div className='item'>
        <Select
          getPopupContainer={trigger => trigger.parentNode}
          virtual={false}
          name='country'
          style={{ width: '100%' }}
          onChange={submitForm}
          placeholder='Country'
          showSearch
          allowClear
          autoComplete='none'
          listHeight={256}>
          {countries.map(country => (
            <Option key={country.id} value={country.name}>
              {country.name}
            </Option>
          ))}
        </Select>
      </div>
      <div className='item'>
        <Select
          getPopupContainer={trigger => trigger.parentNode}
          virtual={false}
          name='categories'
          placeholder='Niche'
          showSearch
          allowClear
          optionFilterProp='label'
          filterOption
          mode='multiple'
          showArrow
          maxTagTextLength={20}
          loading={!categories?.length}
          style={{ width: '100%' }}
          onChange={submitForm}>
          {categories?.map(category => (
            <Option label={category.title} key={category.id} value={category.slug}>
              {category.title}
            </Option>
          ))}
        </Select>
      </div>
      <div className='buttons'>
        {userData && (
          <Button className='set-automatic-filters' type='primary' onClick={filterByUserStats}>
            Automatic Filters
          </Button>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  h3 {
    margin: 20px 0 10px 0;
    &:first-child {
      margin-top: 0;
    }
  }
  .item {
    margin: 5px 0;
    .title {
      font-size: 16px;
      margin-bottom: 5px;
      display: flex;
      .sublabel {
        color: #999;
        font-size: 0.8rem;
      }
    }
    &.checkbox {
      .ant-checkbox-group {
        display: flex;
        flex-direction: column;
      }
      .label {
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }
  .ant-checkbox-wrapper {
    margin: 5px 0;
  }
  .platform-reputation-filters {
    background: ${props => props.theme.crcoOffWhite};
    margin-bottom: 10px;
    padding: 5px;
    border: 1px solid #e6e6e6;
    border-radius: 3px;
    p {
      margin: 0;
    }
    .items {
      display: flex;
      grid-gap: 5px;
      .item {
        margin: 0;
        .title {
          margin: 0;
          color: #666;
        }
        .ant-input-number {
          font-size: 0.8rem;
        }
      }
    }
  }
  .buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    margin-top: 20px;
    button {
      width: 100%;
      flex: 1;
      margin: 0;
    }
  }
`

export default CampaignsFilters
