import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons'
import { Button, Input, message, Tooltip } from 'antd'
import { CountryPhoneInput, ConfigProvider } from 'antd-country-phone-input'
import axios from 'axios'
import React, { useContext, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import styled from 'styled-components/macro'
import en from 'world_countries_lists/data/countries/en/world.json'

import { API_URL } from '../../../constants'
import { UserContext } from '../../../contexts/UserContext'

const PhoneInput = ({ onSuccess = () => {} }) => {
  const [codeSent, setCodeSent] = useState(false)
  const [code, setCode] = useState('')
  const [isVerifying, setIsVerifying] = useState(false)
  const [showVerification, setShowVerification] = useState(false)

  const { fetchCurrentUser } = useContext(UserContext)
  const { data: userData } = useQuery('user', fetchCurrentUser)
  const queryClient = new useQueryClient()

  const [formData, setFormData] = useState({
    phone: userData?.phone ? userData?.phone : '',
    code: userData?.phoneCode ? userData?.phoneCode : '1',
    short: userData?.phoneShort ? userData?.phoneShort : 'US',
    otpCode: '',
  })

  const handleSubmit = async values => {
    setCodeSent(true)
    try {
      const response = await axios.post(`${API_URL}/creator/settings/phone/otp/send`, {
        phone: values.phone,
        phoneCode: values.code,
        phoneShort: values.short,
      })

      if (response.data.messageSent) {
        setShowVerification(true)
        message.success('Verification code sent.')
        setCodeSent(false)
      } else if (response.data.unsupportedCountry) {
        // TODO: this is temporary to allow certain international users to bypass verification. This should be removed when a solution is in place to send local numbers to international users
        queryClient.invalidateQueries('user')
        setShowVerification(false)
        message.warning('Country SMS is not supported. Creator verification is bypassed.')
        setIsVerifying(false)
        setCodeSent(false)
        onSuccess()
      }
    } catch (error) {
      const errorMessage = error.response.data?.err
      message.error(errorMessage)
      setShowVerification(false)
      setCodeSent(false)
    }
  }

  const handleVerification = async values => {
    setIsVerifying(true)
    await axios
      .post(`${API_URL}/creator/settings/phone/otp/verify`, {
        phone: values.phone,
        phoneCode: values.code.toString(),
        phoneShort: values.short,
        otp: code,
      })
      .then(() => {
        queryClient.invalidateQueries('user')
        setShowVerification(false)
        onSuccess()
      })
      .catch(error => {
        message.error(
          `${error.response.data.err} Code is inaccurate` || 'Something went wrong please try again'
        )
        setShowVerification(true)
      })
      .finally(() => {
        setIsVerifying(false)
      })
  }

  const phoneStatus =
    userData?.extraData?.twilioVerified && userData?.extraData?.disableTwilio ? (
      <Tooltip title='The SMS chat feature is not available in your country.'>
        <WarningOutlined style={{ color: 'orange' }} />
      </Tooltip>
    ) : !userData?.extraData?.twilioVerified ||
      formData.phone.replace(/[^0-9]/g, '') !== userData.phone ||
      formData.code !== userData.phoneCode ? (
      ''
    ) : (
      <CheckCircleOutlined style={{ color: '#027DF0' }} />
    )

  return (
    <Wrapper>
      <div>
        <ConfigProvider locale={en}>
          <div style={{ display: 'flex', gap: '10px' }}>
            <CountryPhoneInput
              // temporarily disabled
              suffix={phoneStatus}
              value={{ ...formData }}
              inline
              onChange={e => {
                setFormData(prev => ({
                  ...prev,
                  ...e,
                }))
              }}
            />
            {(!userData?.extraData?.twilioVerified ||
              formData.phone.replace(/[^0-9]/g, '') !== userData.phone ||
              formData.code !== userData.phoneCode) && (
              <Button
                type='secondary'
                loading={codeSent}
                onClick={() => {
                  handleSubmit(formData)
                }}>
                Verify
              </Button>
            )}
          </div>
        </ConfigProvider>
      </div>
      {showVerification && (
        <>
          <p className='info-text'>A verification code has been sent to your number.</p>
          <div style={{ display: 'flex', gap: '10px' }}>
            <Input
              controls={false}
              width={'25px'}
              maxLength={6}
              placeholder='Enter code'
              onChange={e => {
                setCode(e.target.value)
              }}
            />
            <Button
              type='link'
              loading={isVerifying}
              onClick={() => {
                handleVerification(formData)
              }}>
              Confirm
            </Button>
          </div>
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  max-width: 394px;
  .info-text {
    margin: 10px 0;
  }
`

export default PhoneInput
