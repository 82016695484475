import { LoadingOutlined, PlusOutlined, MinusOutlined, EditOutlined } from '@ant-design/icons'
import { Modal, message, Upload, Button, Input as AntInput } from 'antd'
import axios from 'axios'
import { FieldArray } from 'formik'
import { Input } from 'formik-antd'
import { useState } from 'react'
import Resizer from 'react-image-file-resizer'
import styled from 'styled-components'

import imagePlaceholder from '../../../../assets/images/image-placeholder.svg'
import { API_URL } from '../../../../constants'
import { FormItem } from '../../../general/forms/FormItem'
import { ImageLoader } from '../../../general/ImageLoader'

export const RecommendedProducts = ({ values, errors, setValues, submitForm }) => {
  const [submittingProductLink, setSubmittingProductLink] = useState(false)

  const [editProduct, setEditProduct] = useState(undefined)
  const [newImage, setNewImage] = useState(undefined)
  const [newTitle, setNewTitle] = useState(undefined)
  const [newSiteName, setNewSiteName] = useState(undefined)

  const [uploading, setUploading] = useState(false)
  const fileList = []
  const uploadButton = <div>{uploading ? <LoadingOutlined /> : <PlusOutlined />}</div>

  // resize file before upload
  const resizeFile = file =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file, // file to be resized
        800, // maxWidth of the resized image
        800, // maxHeight of the resized image
        'WEBP', // compressFormat of the resized image
        100, // quality of the resized image
        0, // degree of clockwise rotation to apply to the image
        uri => {
          // callback function of the resized image URI
          resolve(uri)
        },
        'file' // outputType of the resized image
      )
    })

  // upload product image to our server
  const handleUpload = async fileData => {
    setUploading(true)

    // resize file to prevent uploading large images to our server
    const image = await resizeFile(fileData.file)
    const formData = new FormData()
    formData.append('upload', image)

    await axios
      .post(`${API_URL}/recommended-products`, formData)
      .then(res => {
        setNewImage(res.data.url)
      })
      .catch(() => {
        message.error('Oops, something went wrong.')
      })
      .finally(() => setUploading(false))
  }

  const handleEditProduct = async () => {
    const updatedProduct = {
      ...editProduct,
      previewUrl: newImage || editProduct.previewUrl,
      title: newTitle || editProduct.title,
      siteName: newSiteName || editProduct.siteName,
    }

    // update the recommendedProducts array and submit form
    const updatedProducts = values.recommendedProducts.map(product =>
      product.url === editProduct.url ? updatedProduct : product
    )
    setValues({
      ...values,
      recommendedProducts: updatedProducts,
    })
    submitForm()

    // close modal
    setEditProduct(undefined)
  }

  return (
    <section id='recommended-products'>
      <h2>Recommended Products</h2>
      <p className='info-text'>
        Upload links for any products you recommend (e.g. affiliate links). Please note, not all
        links will have meta data available and the images may not be displayed.
      </p>
      <FieldArray
        name='recommendedProducts'
        render={arrayHelpers => (
          <>
            <FormItem name='productLink'>
              <div className='add-product'>
                <Input
                  name='productLink'
                  placeholder='e.g. https://www.shop.com/product...'
                  defaultValue={values.productLink}
                  disabled={submittingProductLink}
                  onPressEnter={e => {
                    // prevents submitting form on enter
                    e.preventDefault()
                  }}
                />
                <button
                  className='add-button'
                  type='submit'
                  disabled={!values.productLink || submittingProductLink}
                  onClick={() => {
                    // only runs if productLink validation has passed
                    if (!errors.productLink) {
                      setSubmittingProductLink(true)
                      axios
                        .post(`${API_URL}/link-meta-data`, [values.productLink])
                        .then(res => {
                          if (!res.data.error) {
                            setValues({
                              ...values,
                              recommendedProducts: [
                                ...values.recommendedProducts,
                                res.data, // link preview data
                              ],
                              productLink: '',
                            })
                            submitForm()
                            setSubmittingProductLink(false)
                          } else {
                            message.error('Oops, something went wrong. This link may be invalid.')
                            setSubmittingProductLink(false)
                          }
                        })
                        .catch(() => {
                          message.error('Oops, something went wrong. This link may be invalid.')
                          setSubmittingProductLink(false)
                        })
                    }
                  }}>
                  {submittingProductLink ? <LoadingOutlined spin /> : <PlusOutlined />}
                </button>
              </div>
            </FormItem>
            <div className='products'>
              {values.recommendedProducts?.map((product, key) => (
                <div className='product' key={key}>
                  <a
                    className='product-card'
                    href={product.url}
                    target='_blank'
                    rel='noopener noreferrer'>
                    {product.previewUrl ? (
                      <div className='thumbnail'>
                        <ImageLoader src={product.previewUrl} alt='' />
                      </div>
                    ) : (
                      <div className='placeholder-thumbnail'>
                        <ImageLoader src={imagePlaceholder} alt='' />
                      </div>
                    )}
                    <div className='text'>
                      <p className={`title ${!product.title && 'no-info'}`}>
                        {product.title || 'Info unavailable.'}
                      </p>
                      <p className='sitename'>{product.siteName}</p>
                    </div>
                  </a>
                  <div className='button-wrapper'>
                    <button
                      type='submit'
                      className='remove-button'
                      onClick={() => {
                        arrayHelpers.remove(key)
                        submitForm()
                      }}>
                      <MinusOutlined />
                    </button>
                    <button
                      type='button'
                      className='remove-button'
                      onClick={() => {
                        setEditProduct(product)
                      }}>
                      <EditOutlined />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      />

      <Modal
        title='Edit Product'
        open={editProduct}
        onCancel={() => setEditProduct(null)}
        destroyOnClose
        footer={null}>
        {editProduct && (
          <EditProductModal>
            <FormItem label='Preview image'>
              <Upload
                multiple={false}
                maxCount={1}
                customRequest={handleUpload}
                fileList={fileList}
                listType='picture-card'
                accept='.png, .jpg, .jpeg'
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: false,
                }}>
                {!uploading && (newImage || editProduct.previewUrl) ? (
                  <img src={newImage || editProduct.previewUrl} alt='' />
                ) : (
                  uploadButton
                )}
              </Upload>
            </FormItem>

            <FormItem label='Product name'>
              <AntInput
                placeholder='e.g. Nike Air Max 90'
                defaultValue={editProduct.title}
                onChange={e => setNewTitle(e.target.value)}
              />
            </FormItem>

            <FormItem label='Brand or site name'>
              <AntInput
                placeholder='e.g. Nike or nike.com'
                defaultValue={editProduct.siteName}
                onChange={e => setNewSiteName(e.target.value)}
              />
            </FormItem>

            <div className='buttons'>
              <Button type='secondary' onClick={() => setEditProduct(null)}>
                Cancel
              </Button>
              <Button type='primary' onClick={handleEditProduct}>
                Save
              </Button>
            </div>
          </EditProductModal>
        )}
      </Modal>
    </section>
  )
}

const EditProductModal = styled.div`
  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
    gap: 12px;
  }
`
