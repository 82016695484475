import { Button } from 'antd'
import React, { useContext } from 'react'
import styled from 'styled-components/macro'

import { OptInContext } from '../../../../../contexts/OptInContext'

export const BRAND_SUBMIT_SHIPMENT_TRACKING_LINK = ({ campaignStep }) => {
  const { optIn } = useContext(OptInContext)
  const stepReached = campaignStep.placement <= optIn.currentStep?.placement

  return (
    <Wrapper className='programmed-step'>
      {optIn.extraData?.shipmentTrackingLink ? (
        <a href={optIn.extraData.shipmentTrackingLink} target='_blank' rel='noopener noreferrer'>
          <Button type='secondary'>Track Shipment</Button>
        </a>
      ) : (
        stepReached && <p>The brand needs to submit the shipment tracking link.</p>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div``
