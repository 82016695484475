import { DollarCircleOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import { Alert, Button, DatePicker, Drawer, Empty, Select } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { API_URL } from '../../../../constants'
import { UserContext } from '../../../../contexts/UserContext'
import LoadingHeart from '../../../general/LoadingHeart'
import PayoutSettings from '../PayoutSettings'

const AffiliateAwin = () => {
  const { fetchCurrentUser } = useContext(UserContext)
  const { data: userData } = useQuery('user', fetchCurrentUser)
  // infinite query to fetch general link performance data
  const queryClient = useQueryClient()
  const [dateRange, setDateRange] = useState([
    moment(new Date().getTime() - 1000 * 60 * 60 * 24 * 30),
    moment(new Date()),
  ])
  const [region, setRegion] = useState('US')
  let currency = useRef('USD')
  const [showPayouts, setShowPayouts] = useState(false)
  const { data: affiliateData, status: affiliateStatus } = useQuery(
    [
      'awin-affiliate',
      { dateRange: [dateRange[0].toISOString(), dateRange[1].toISOString()], region },
    ],
    async ({ queryKey: [params] }) => {
      const { data } = await axios.get(`${API_URL}/me/affiliate-analytics/awin`, {
        params,
      })
      return data
    }
  )

  useEffect(() => {
    switch (region) {
      case 'CA':
        currency.current = 'CAD'
        break
      case 'GB':
        currency.current = 'GBP'
        break
      case 'DE':
        currency.current = 'EUR'
        break
      default:
        currency.current = 'USD'
    }
  }, [region])

  return (
    <Wrapper>
      <div className='inner'>
        <div className='inner-content'>
          <div className='filters-box'>
            <div className='filters'>
              <Select
                style={{ minWidth: '250px' }}
                defaultValue={region}
                onChange={e => {
                  setRegion(e)
                }}>
                <Select.Option value='US'>United States</Select.Option>
                <Select.Option value='CA'>Canada</Select.Option>
                <Select.Option value='GB'>United Kingdom</Select.Option>
                <Select.Option value='DE'>Germany</Select.Option>
              </Select>
              <DatePicker.RangePicker
                allowClear={false}
                defaultValue={dateRange}
                disabledDate={current =>
                  current.diff(dateRange[0], 'days') > 30 || current.diff(dateRange[1], 'days') > 30
                }
                onChange={e => {
                  setDateRange(e.map(date => date._d))
                }}
              />
            </div>
            <Button
              style={{ fontSize: '15px' }}
              type='primary'
              onClick={() => setShowPayouts(true)}
              icon={<DollarCircleOutlined />}>
              Payout history
            </Button>
            <Drawer
              onClose={() => {
                setShowPayouts(false)
                queryClient.invalidateQueries('user')
              }}
              open={showPayouts}
              footer={null}
              width={window.innerWidth > 600 ? 600 : '100vw'}>
              <PayoutSettings />
            </Drawer>
          </div>
          {userData?.trolleyStatus !== 'active' && (
            <Alert
              type='warning'
              message={
                <span>
                  You need to verify your payment details to receive commission payouts. You can do
                  so in <Link to='/my-settings'>your account settings</Link>
                </span>
              }
              icon={<ExclamationCircleFilled />}
              showIcon
            />
          )}
          {affiliateStatus === 'success' && (
            <div className='affiliates'>
              {affiliateData?.length ? (
                <>
                  <div className='affiliate-header'>
                    <div className='affiliate-name'>Program</div>
                    <div className='clicks'>Clicks</div>
                    <div className='sales'>Sales</div>
                    <div className='commissions'>Commissions</div>
                  </div>
                  <div className='affiliate-list'>
                    {affiliateData.map((item, i) => (
                      <div key={i} className='affiliate'>
                        <div className='affiliate-name'>{item.advertiserName}</div>
                        <div className='affiliate-analytics'>
                          <div className='clicks'>
                            <span className='value'>{item.totals.quantity.click}</span>
                            <span className='affiliate-analytics-text'>Clicks</span>
                          </div>
                          <span className='analytics-separator'>•</span>
                          <div className='sales'>
                            {parseInt(item.totals.saleAmount.total)
                              ? `$${item.totals.saleAmount.total} ${currency.current}`
                              : '-'}
                            <span className='affiliate-analytics-text'>Sales</span>
                          </div>
                          <span className='analytics-separator'>•</span>
                          <div className='commissions'>
                            {parseInt(item.totals.commissionAmount.total)
                              ? `$${Math.round(item.totals.commissionAmount.total * 95) / 100} ${
                                  currency.current
                                }`
                              : '-'}
                            <span className='affiliate-analytics-text'>Commission</span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <div className='no-results'>
                  <Empty description='No affiliate activity found.' />
                </div>
              )}
            </div>
          )}
          {affiliateStatus === 'loading' && (
            <div className='loading'>
              <LoadingHeart />
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  )
}

// Wrapper being shared between AWIN && Shareasale
export const Wrapper = styled.div`
  height: 100%;
  color: black;
  width: 90vw;
  max-width: 480px;
  margin: 0 auto;

  @media only screen and (min-width: ${props => props.theme.breakpointMobile}) {
    width: 100%;
    max-width: 1200px;
  }

  .inner {
    height: 100%;
  }

  .inner-content {
    background-color: #fff;
    padding: 20px 0px;
    border-radius: 10px;
    margin: 0 auto;

    .filters-box {
      background-color: #fff;
      padding: 0 20px 15px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 20px;
      margin-bottom: 24px;
      box-shadow: 0px 5px 10px -10px #000000ab;
      z-index: 1;

      .filters {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
      }
    }

    .affiliates {
      border: 1px solid #e6e6e6;
      border-radius: 10px;
      padding: 10px;
      background-color: #f2f2f2;
      margin: 0 15px;

      .affiliate-header {
        display: none;
        font-size: 1.1rem;
        margin-bottom: 12px;
        margin-right: 12px;
        padding-left: 12px;
        padding-right: 12px;

        .affiliate-name {
          flex: 2;
        }

        .clicks,
        .sales,
        .commissions {
          font-size: 1.1rem;
          text-align: center;
          flex: 1;
        }
      }

      @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
        .affiliate-header {
          display: flex;
        }
      }
    }

    .affiliate-list {
      max-height: 48vh;
      overflow-y: scroll;
      ${props => props.theme.scrollbar}

      .affiliate {
        display: flex;
        padding: 15px 12px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-items: flex-start;
        align-items: flex-start;

        .affiliate-name {
          flex: 2;
        }

        .affiliate-analytics {
          border-top: 1px solid lightgray;
          padding-top: 5px;
          flex: 3;
          display: flex;
          align-items: baseline;
          flex-wrap: wrap;
          gap: 10px;
          width: 100%;
          margin-top: 5px;

          .clicks,
          .sales,
          .commissions {
            font-size: 12px;
            text-align: left;
            display: flex;
            gap: 3px;
          }
        }

        &:not(:last-child) {
          border-bottom: 1px solid lightgray;
        }
      }
    }

    @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
      .affiliates {
        padding: 10px 10px;
        margin: 0 auto;
      }
    }

    @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
      .affiliates {
        .affiliate-list {
          padding-left: 12px;
          padding-right: 12px;

          .affiliate {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .affiliate-analytics {
              border-top: 0px;
              gap: 0;

              .analytics-separator {
                display: none;
              }

              .clicks,
              .sales,
              .commissions {
                text-align: center;
                font-size: 1.1rem;
                flex: 1;
                display: block;

                .affiliate-analytics-text {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    .inner-content {
      padding: 20px;
    }
  }
`

export default AffiliateAwin
