import { Alert, Empty, Button, Modal, message } from 'antd'
import axios from 'axios'
import { Formik } from 'formik'
import { Form, Input, Radio, Select } from 'formik-antd'
import React, { useContext, useState } from 'react'
import styled from 'styled-components/macro'

import { ProfileCard } from './ProfileCard'
import { SocialConnect } from './SocialConnect'
import { API_URL } from '../../../../constants'
import { UserContext } from '../../../../contexts/UserContext'
import { FormItem } from '../../../general/forms/FormItem'

const InstagramForm = ({ setSupportModalVisible, submitForm, values }) => {
  return (
    <>
      <FormItem name='instagramQuestions'>
        <div className='instagram-questions'>
          <p>Is your IG account a professional account (Creator/Business)?</p>
          <Radio.Group name='creatorBusiness'>
            <Radio value={'Yes'}>Yes</Radio>
            <Radio value={'No'}>No</Radio>
            {values.creatorBusiness === 'No' && (
              <Alert
                className='instagram-questions-alert'
                type='error'
                message={
                  <p>
                    Please follow this{' '}
                    <a
                      href='https://help.instagram.com/502981923235522'
                      target='_blank'
                      rel='noreferrer'>
                      guide
                    </a>{' '}
                    to switch to a professional account. If you still encounter problems, click
                    &apos;yes&apos; to continue and receive support.
                  </p>
                }
              />
            )}
          </Radio.Group>
        </div>
        {values.creatorBusiness === 'Yes' && (
          <div className='instagram-questions' style={{ marginTop: '15px' }}>
            <p>Is your IG account connected to your Facebook page (not personal profile)?</p>
            <Radio.Group name='connectedFacebook'>
              <Radio value={'Yes'}>Yes</Radio>
              <Radio value={'No'}>No</Radio>
            </Radio.Group>
            {values.connectedFacebook === 'No' && (
              <Alert
                className='instagram-questions-alert'
                type='error'
                message={
                  <p>
                    Please follow this{' '}
                    <a
                      href='https://help.instagram.com/176235449218188'
                      target='_blank'
                      rel='noreferrer'>
                      guide
                    </a>{' '}
                    to connect your Instagram account to your Facebook page. If you still encounter
                    problems, click &apos;yes&apos; to continue and receive support.
                  </p>
                }
              />
            )}
          </div>
        )}
        {values.connectedFacebook === 'Yes' && (
          <div className='instagram-questions' style={{ marginTop: '15px' }}>
            <p>Did you select the correct Facebook page & IG account in the permission flow?</p>
            <Radio.Group name='permissionFlow'>
              <Radio value={'Yes'}>Yes</Radio>
              <Radio value={'No'}>No</Radio>
              {values.permissionFlow === 'No' && (
                <Alert
                  className='instagram-questions-alert'
                  type='error'
                  message={
                    <p>
                      Please ensure you select the Facebook page linked to your Instagram account.
                      If you still encounter problems, click &apos;yes&apos; to continue and receive
                      support.
                    </p>
                  }
                />
              )}
            </Radio.Group>
          </div>
        )}
        {values.permissionFlow === 'Yes' && (
          <div className='instagram-questions' style={{ marginTop: '15px' }}>
            <p>Did you accept all the requested permissions?</p>
            <Radio.Group name='requestedPermissions'>
              <Radio value={'Yes'}>Yes</Radio>
              <Radio value={'No'}>No</Radio>
            </Radio.Group>
            {values.requestedPermissions === 'No' && (
              <Alert
                className='instagram-questions-alert'
                type='error'
                message={
                  <p>
                    All requested permissions must be accepted to successfully connect your
                    Instagram account. If you still encounter problems, click &apos;yes&apos; to
                    continue and receive support.
                  </p>
                }
              />
            )}
          </div>
        )}
      </FormItem>
      {values.requestedPermissions === 'Yes' && (
        <>
          <FormItem name='instagramSupportSubject' label='Subject'>
            <Input name='instagramSupportSubject' placeholder='' maxLength={50} showCount />
          </FormItem>
          <FormItem name='instagramSupportMessage' label='Message'>
            <Input.TextArea
              name='instagramSupportMessage'
              placeholder='Describe your problem'
              rows={4}
              maxLength={2500}
              showCount
            />
          </FormItem>
        </>
      )}
      <div className='submit-buttons'>
        <Button
          onClick={submitForm}
          type='primary'
          className='cta'
          disabled={!values.instagramSupportMessage || !values.requestedPermissions}>
          Submit
        </Button>
        <Button
          type='secondary'
          onClick={() => {
            setSupportModalVisible(false)
          }}
          danger>
          Cancel
        </Button>
      </div>
    </>
  )
}

const AllPlatformsForm = ({ setSupportModalVisible, values }) => {
  return (
    <>
      <FormItem name='platformSupportSubject' label='Subject'>
        <Input name='platformSupportSubject' placeholder='' maxLength={50} showCount />
      </FormItem>
      <FormItem name='platformSupportMessage' label='Message'>
        <Input.TextArea
          name='platformSupportMessage'
          placeholder='Describe your problem'
          rows={4}
          maxLength={2500}
          showCount
        />
      </FormItem>
      <div className='submit-buttons'>
        <Button
          htmlType='submit'
          type='primary'
          className='cta'
          disabled={!values.platformSupportSubject || !values.platformSupportMessage}>
          Submit
        </Button>
        <Button
          type='secondary'
          onClick={() => {
            setSupportModalVisible(false)
          }}
          danger>
          Cancel
        </Button>
      </div>
    </>
  )
}

export const SocialAccounts = ({ userData }) => {
  const [supportModalVisible, setSupportModalVisible] = useState(false)
  const { phylloError } = useContext(UserContext)

  const platforms = ['instagram', 'tiktok', 'youtube']

  const connected = platforms
    .filter(platform => userData.socialProfiles.find(profile => profile.platform === platform))
    .map(platform => <ProfileCard platform={platform} key={platform} />)

  const unconnected = platforms
    .filter(platform => !userData.socialProfiles.find(profile => profile.platform === platform))
    .map(platform => <SocialConnect platform={platform} key={platform} />)

  const handleSubmit = async values => {
    axios
      .post(`${API_URL}/creator/settings/contact/contact-support`, {
        subject: values.instagramSupportSubject || values.platformSupportSubject,
        message: values.instagramSupportMessage || values.platformSupportMessage,
        user: {
          id: userData.id,
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
        },
        platform: values.platform,
        instagramQuestions: {
          creatorBusiness: values.creatorBusiness ? values.creatorBusiness : 'N/A',
          connectedFacebook: values.connectedFacebook ? values.connectedFacebook : 'N/A',
          permissionFlow: values.permissionFlow ? values.permissionFlow : 'N/A',
          requestedPermissions: values.requestedPermissions ? values.requestedPermissions : 'N/A',
        },
      })
      .then(() => {
        setSupportModalVisible(false)
        message.success('Support request sent. Thank you!')
      })
      .catch(() => {
        message.error('Oops, something went wrong.')
      })
  }

  return (
    <Wrapper id='social' className='settings-section'>
      <h2>Social Accounts</h2>
      <p className='info-text'>
        Note: We only support one profile connection per social platform. As sessions expire after 3
        months for security reasons, please be sure to reconnect as needed to avoid interruption to
        your campaigns. Follower count updates daily, engagement rate updates monthly.
      </p>

      {connected.length > 0 ? (
        <div className='profile-cards'>{connected}</div>
      ) : (
        <Empty description='No social accounts connected.' />
      )}

      {unconnected.length > 0 && (
        <>
          <h3>Connect</h3>
          <p className='info-text'>
            In order to access platform-specific collaborations, please connect your social
            accounts. Your data is secure. We only use your information to verify your eligibility
            for campaigns and generating insights for registered brands. For more information,
            please see our{' '}
            <a href='https://creator.co/creator-privacy/' target='_blank' rel='noopener noreferrer'>
              Privacy Policy
            </a>
            .
          </p>

          {phylloError && <Alert type='error' message='Oops, something went wrong.' showIcon />}

          <div className='social-connects'>{unconnected}</div>

          <Button
            type='link'
            onClick={() => setSupportModalVisible(true)}
            style={{ width: 'fit-content', margin: 'auto' }}>
            Need Help?
          </Button>
          <Modal
            title='Contact Support'
            open={supportModalVisible}
            destroyOnClose
            onCancel={() => setSupportModalVisible(false)}
            footer={false}>
            <Formik initialValues={{}} onSubmit={handleSubmit}>
              {({ status, submitForm, setFieldValue, values }) => (
                <FormWrapper>
                  <Form>
                    <p className='issues-text'>
                      We&apos;re sorry you&apos;re having issues. Please let us know what&apos;s
                      going wrong and a member of our team will reach out to help you.
                    </p>
                    <FormItem label='Which platform are you having trouble with?'>
                      <Select
                        placeholder='Select'
                        className='select-dropdown'
                        name='platform'
                        style={{ width: '100%', maxWidth: '200px' }}
                        options={[
                          { value: 'Instagram', label: 'Instagram' },
                          { value: 'Tiktok', label: 'Tiktok' },
                          { value: 'Youtube', label: 'Youtube' },
                        ]}
                      />
                      {!status ||
                        status.map((err, key) => (
                          <Alert key={key} message={err.msg} type='error' showIcon />
                        ))}
                    </FormItem>

                    {values.platform === 'Instagram' && (
                      <InstagramForm
                        values={values}
                        setSupportModalVisible={setSupportModalVisible}
                        submitForm={submitForm}
                        setFieldValue={setFieldValue}
                      />
                    )}

                    {values.platform !== 'Instagram' && values.platform !== undefined ? (
                      <AllPlatformsForm
                        setSupportModalVisible={setSupportModalVisible}
                        values={values}
                      />
                    ) : (
                      ''
                    )}
                  </Form>
                </FormWrapper>
              )}
            </Formik>
          </Modal>
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  max-width: 800px;
  margin: auto;
  display: flex;
  flex-direction: column;

  .profile-cards,
  .social-connects {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .animated-div {
      width: 100%;
      position: relative;
      display: flex;
      place-content: center;
    }
  }

  .contact-support {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    button {
      padding: 0;
      margin: 0;
    }
  }

  h3 {
    margin: 30px 0 10px 0;
    padding-top: 30px;
    border-top: 1px dotted #e6e6e6;
  }

  @media only screen and (min-width: 1000px) {
    .profile-cards,
    .social-connects {
      .animated-div {
        width: fit-content;
      }
    }
  }
`

const FormWrapper = styled.div`
  .submit-buttons {
    margin-top: 20px;
    display: flex;
    gap: 12px;
  }
  .issues-text {
    margin-bottom: 20px;
  }
  .instagram-questions {
    margin-top: 15px;
    .instagram-questions-alert {
      margin-top: 10px;
      p {
        margin-bottom: 0px;
      }
    }
  }
`
