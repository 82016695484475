import { Alert, Button, Col, Row } from 'antd'
import { Form, Formik } from 'formik'
import { Input } from 'formik-antd'
import React, { useContext } from 'react'
import styled from 'styled-components/macro'
import * as yup from 'yup'

import { OptInContext } from '../../../../../contexts/OptInContext'
import { FormItem } from '../../../../general/forms/FormItem'

export const GiveawayWinnerDetailsStep = ({ campaignStep }) => {
  const { optIn, stepAction } = useContext(OptInContext)
  const stepReached = campaignStep.placement <= optIn.currentStep?.placement
  const { optInStep } = campaignStep

  const giveawayWinner = optInStep?.data?.giveawayWinners?.[0] || {}

  const schema = yup.object().shape({
    firstName: yup.string().min(2, 'Too short').required('First name is required'),
    lastName: yup.string().min(2, 'Too short').required('Last name is required'),
    address1: yup.string().min(2, 'Too short').required('Address is required'),
    address2: yup.string(),
    city: yup.string().min(2, 'Too short').required('City is required'),
    region: yup.string().required('Region is required'),
    country: yup.string().required('Country is required'),
    postcode: yup.string().required('Postcode is required'),
  })

  const handleSubmit = async (values, { setStatus }) => {
    setStatus('')
    const result = await stepAction(optIn.id, campaignStep.id, {
      giveawayWinners: [values],
    })
    if (result.error) {
      setStatus(result.error)
    }
  }

  return (
    <Wrapper className='programmed-step'>
      {stepReached && (
        <>
          <p>Please submit the giveaway winner details.</p>
          <Formik
            initialValues={{
              ...giveawayWinner,
            }}
            validationSchema={schema}
            onSubmit={handleSubmit}>
            {({ status, isSubmitting }) => (
              <Form>
                {status && <Alert message={status} type='error' showIcon />}
                <Row gutter={12}>
                  <Col md={12} xs={24}>
                    <FormItem required name='firstName' label='First name'>
                      <Input name='firstName' />
                    </FormItem>
                  </Col>
                  <Col md={12} xs={24}>
                    <FormItem required name='lastName' label='Last name'>
                      <Input name='lastName' />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col md={12} xs={24}>
                    <FormItem required name='address1' label='Street'>
                      <Input name='address1' />
                    </FormItem>
                  </Col>
                  <Col md={12} xs={24}>
                    <FormItem name='address2' label='Apt / Suite / Unit'>
                      <Input name='address2' placeholder='Optional' />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col md={12} xs={24}>
                    <FormItem required name='city' label='City'>
                      <Input name='city' />
                    </FormItem>
                  </Col>
                  <Col md={12} xs={24}>
                    <FormItem required name='region' label='State / Province'>
                      <Input name='region' />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col md={12} xs={24}>
                    <FormItem required name='country' label='Country'>
                      <Input name='country' />
                    </FormItem>
                  </Col>
                  <Col md={12} xs={24}>
                    <FormItem required name='postcode' label='Postcode'>
                      <Input name='postcode' />
                    </FormItem>
                  </Col>
                </Row>
                <Button htmlType='submit' type='primary' loading={isSubmitting}>
                  {optInStep?.completed ? 'Update' : 'Submit'}
                </Button>
              </Form>
            )}
          </Formik>
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .details {
    background: #fff;
    padding: 5px;
    border-radius: 5px;
  }
`
