import { Alert, Button } from 'antd'
import { Form, Formik } from 'formik'
import { Input } from 'formik-antd'
import React, { useContext } from 'react'
import styled from 'styled-components/macro'
import * as yup from 'yup'

import { OptInContext } from '../../../../../contexts/OptInContext'
import { FormItem } from '../../../../general/forms/FormItem'

export const CreatorSubmitSocialPostStep = ({ campaignStep }) => {
  const { optIn, stepAction } = useContext(OptInContext)
  const stepReached = campaignStep.placement <= optIn.currentStep?.placement
  const schema = yup.object().shape({
    socialPostUrl: yup
      .string()
      .url('URL is invalid')
      .required('URL is required')
      .test(
        'is-url-same-domain',
        'URL you entered is for the incorrect platform. Please try again.',
        value => {
          return optIn?.campaign?.socialChannels?.some(socialChannel =>
            value?.includes(socialChannel)
          )
        }
      ),
  })

  const handleSubmit = async (values, { setStatus }) => {
    setStatus('')
    const result = await stepAction(optIn.id, campaignStep.id, { url: values.socialPostUrl })
    if (result.error) {
      setStatus(result.error)
    }
  }

  return (
    <Wrapper className='programmed-step'>
      {campaignStep.optInStep?.completed ? (
        <a
          href={campaignStep.optInStep?.data?.shoutoutData?.url || ''}
          target='_blank'
          rel='noopener noreferrer'>
          <Button type='secondary'>View ShoutOut</Button>
        </a>
      ) : (
        stepReached && (
          <>
            <p>Please submit the link to your shoutout.</p>
            <Formik initialValues={{}} validationSchema={schema} onSubmit={handleSubmit}>
              {({ status, isSubmitting }) => (
                <Form>
                  {status && <Alert message={status} type='error' showIcon />}
                  <FormItem name='socialPostUrl'>
                    <Input name='socialPostUrl' placeholder='Enter URL' style={{ width: '100%' }} />
                  </FormItem>
                  <Button htmlType='submit' type='primary' loading={isSubmitting}>
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          </>
        )
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div``
