import { Button, Tag } from 'antd'
import { Form, Formik } from 'formik'
import { Input, Select } from 'formik-antd'
import React, { useContext } from 'react'
import styled from 'styled-components/macro'

import { OptInContext } from '../../../../../contexts/OptInContext'
import { FormItem } from '../../../../general/forms/FormItem'

const CompletedDisplayCard = ({ campaignStep }) => {
  const campaignCustomForm = campaignStep?.optInStep?.data?.customForm?.map(item => item)
  const optInResponseForm = campaignStep?.customForm?.map(formItem => formItem)
  const responseForm = campaignCustomForm?.map(formItem => {
    const campaignStepForm = optInResponseForm.find(item => item.uuid === formItem.uuid)
    return {
      uuid: campaignStepForm?.uuid,
      label: campaignStepForm?.label,
      value: formItem?.value,
    }
  })

  return (
    <div className='completed-display'>
      <div className='basic-info'>
        <div className='step-status'>
          <strong>Status: </strong>
          {campaignStep.stepSettings.influencerCompletedStatus}
        </div>
        <div className='instructions'>
          <strong>Instructions: </strong>
          {campaignStep.instructions}
        </div>
      </div>
      <div className='completed-responses'>
        {responseForm?.map(form => (
          <div key={form.uuid} className='responses'>
            <div className='label'>{form.label}</div>
            <div className='value'>
              {typeof form.value === 'string'
                ? form.value?.split('\n').map((line, i) => <p key={i}>{line}</p>)
                : form.value.map((item, i) => <Tag key={i}>{item}</Tag>)}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

const DynamicFormInput = ({ uuid, label, inputType, selectInputType, selectOptions }) => {
  let body
  if (inputType === 'text') {
    body = (
      <FormItem label={label} size='small'>
        <Input name={uuid} style={{ width: '250px', maxWidth: '250px' }} allowClear required />
      </FormItem>
    )
  } else if (inputType === 'textArea') {
    body = (
      <FormItem label={label} size='small'>
        <Input.TextArea
          name={uuid}
          style={{ width: '250px', maxWidth: '250px' }}
          allowClear
          required
        />
      </FormItem>
    )
  } else if (inputType === 'select') {
    body = (
      <FormItem label={label} size='small'>
        <Select
          name={uuid}
          style={{ width: '250px', maxWidth: '250px', textAlign: 'start' }}
          mode={selectInputType === 'multiSelect' && 'tags'}
          allowClear
          required
          options={
            selectOptions &&
            selectOptions.map(option => ({
              value: option,
              label: option,
            }))
          }
        />
      </FormItem>
    )
  }

  return <div className='custom-form'>{body}</div>
}

export const CustomStep = ({ campaignStep }) => {
  const { optIn, stepAction } = useContext(OptInContext)

  return (
    <Wrapper className='custom-step'>
      {campaignStep.actionee === 'creator' ? (
        !campaignStep.optInStep?.completed ? (
          <>
            <div className='step-status'>{campaignStep.stepSettings.influencerStatus}</div>
            <div className='step-instructions'>
              <strong>Instructions: </strong>
              {campaignStep.instructions}
            </div>
            <Formik
              initialValues={{}}
              onSubmit={async values => {
                // complete custom step
                await stepAction(optIn.id, campaignStep.id, { customForm: values })
              }}>
              {({ isSubmitting }) => (
                <Form>
                  {campaignStep?.customForm?.map((form, i) => (
                    <DynamicFormInput
                      key={i}
                      uuid={form?.uuid}
                      label={form?.label}
                      inputType={form?.inputType}
                      selectInputType={form?.selectInputType}
                      selectOptions={form?.selectOptions}
                    />
                  ))}
                  <Button loading={isSubmitting} htmlType='submit' type='primary'>
                    Done
                  </Button>
                </Form>
              )}
            </Formik>
          </>
        ) : (
          <CompletedDisplayCard campaignStep={campaignStep} />
        )
      ) : campaignStep.optInStep?.completed ? (
        <CompletedDisplayCard campaignStep={campaignStep} />
      ) : (
        <span className='step-status'>{campaignStep.stepSettings.brandStatus}</span>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .completed-display {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 300px;
    max-width: 300px;

    .basic-info {
      display: flex;
      flex-direction: column;
    }

    .completed-responses {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      .responses {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 100%;
      }

      .label,
      .value {
        text-align: left;
      }

      .label {
        font-weight: bold;
        opacity: 1;
      }
    }
  }
`
